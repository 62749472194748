import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
// @mui
import { Card, Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useDispatch, useSelector } from '../../../redux/store';
import { useParams } from 'react-router-dom';
import { useLocales } from '../../../locales';
import AttachmentEditForm from 'src/sections/@dashboard/attachment/AttachmentEditForm';
import { getAttachment } from 'src/redux/slices/attachment';

export default function AttachmentEditPage() {
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { attachmentId, itemId, itemLogId, serviceRequestId, reminderId } =
    useParams();
  const { attachment } = useSelector((state) => state.attachment);

  useEffect(() => {
    dispatch(getAttachment(Number(attachmentId)));
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.attachment.editAttachment')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.attachment.editAttachment')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            { name: `${translate('pages.attachment.editAttachment')}` },
          ]}
        />
        {/* if attachment create from is tight coupled with itemLogId, it is not possible to make it universal */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              <AttachmentEditForm
                attachment={attachment}
                itemId={itemId}
                itemLogId={itemLogId}
                serviceRequestId={serviceRequestId}
                reminderId={reminderId}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
