import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable
} from '../../../components/table';
import { dispatch, useSelector } from 'src/redux/store';
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useLocales } from '../../../locales';
import {
  getItemLogAttachments,
  getReminderAttachments,
  removeAttachment,
  removeItemLogAttachment
} from 'src/redux/slices/attachment';
import AttachmentTableRow from 'src/sections/@dashboard/attachment/list/AttachmentTableRow';
import { IAttachment } from 'src/@types/attachment';
import { savePageToStorage } from 'src/utils/pageStorageHandler';
import { saveRowsPerPageToStorage } from 'src/utils/rowsPerPageStorageHandler';
import { ITEM } from 'src/constants';

type AttachmentListPageProps = {
  itemLogId?: string;
  reminderId?: string;
  fromUrl?: string;
  itemId?: string;
};

export default function AttachmentListPage({
  itemLogId,
  reminderId,
  fromUrl,
  itemId,
}: AttachmentListPageProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [tableData, setTableData] = useState<IAttachment[]>([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const { id } = useParams();
  const [openConfirm, setOpenConfirm] = useState(false);

  const [filterStatus, setFilterStatus] = useState('all');
  const { attachments, isLoading } = useSelector(
    (state: any) => state.attachment
  );

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });
  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const tableHead = [
    { id: 'name', label: `${translate('name')}`, align: 'left' },
    { id: 'description', label: `${translate('description')}`, align: 'left' },
    { id: 'authorName', label: `${translate('authorName')}`, align: 'left' },
    { id: 'download', label: `${translate('download')}`, align: 'left' },
    { id: 'createdAt', label: `${translate('createdAt')}`, align: 'left' },
    { id: 'actions', label: `${translate('actions')}`, align: 'right' },
  ];

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDeleteRow = async (attachmentId: string) => {
    try {
      await dispatch(removeAttachment(Number(attachmentId)));
      if (itemLogId) await dispatch(getItemLogAttachments(Number(itemLogId)));
      else await dispatch(getReminderAttachments(Number(reminderId)));

      setSelected([]);
      setTableData((prev: any) =>
        prev.filter((row: any) => Number(row.id) !== Number(attachmentId))
      );

      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      enqueueSnackbar(`${translate('couldNotDelete')}`, { variant: 'error' });
    }
  };

  const handleDeleteRows = async (
    selectedRows: string[],
    itemLogId: string
  ) => {
    try {
      let hasError = false;
      let messageError = '';
      const deletePromises = selectedRows.map(async (id: string) => {
        try {
          await dispatch(
            removeItemLogAttachment(Number(itemLogId), Number(id))
          );
        } catch (error) {
          messageError = error.message;
          hasError = true;
        }
      });

      await Promise.allSettled(deletePromises);

      if (hasError) {
        const errorMessage =
          messageError !== ''
            ? messageError
            : `${translate('infoMessages.couldNotDelete')}`;
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
        return;
      }

      setSelected([]);
      setTableData((prev: any) => {
        return prev.filter(
          (row: any) => !selectedRows.includes(row.id.toString())
        );
      });

      if (page > 0) {
        if (selectedRows.length === dataInPage.length) {
          setPage(page - 1);
        } else if (selectedRows.length === dataFiltered.length) {
          setPage(0);
        } else if (selectedRows.length > dataInPage.length) {
          const newPage =
            Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
            1;
          setPage(newPage);
        }
      }

      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleEditRow = (attachmentId: string) => {
    savePageToStorage('itemLogAttachment', page);
    saveRowsPerPageToStorage('itemLogAttachment', rowsPerPage);
    if (id && itemLogId) {
      navigate(
        PATH_DASHBOARD.attachment.editItemLogAttachment(
          id ? id : '1',
          itemLogId ? itemLogId : '1',
          attachmentId
        )
      );
    } else {
      navigate(
        PATH_DASHBOARD.attachment.editReminderAttachment(
          reminderId ? reminderId : '1',
          attachmentId
        )
      );
    }
  };

  const handlerCreationNavigate = () => {
    if (itemLogId) {
      navigate(
        PATH_DASHBOARD.attachment.createItemLogAttachment(
          id ? id : '1',
          itemLogId ? itemLogId : '1'
        )
      );
    } else {
      navigate(
        PATH_DASHBOARD.attachment.createReminderAttachment(
          reminderId ? reminderId : '1'
        )
      );
    }
  };

  const handlerBackNavigate = () => {
    if (fromUrl === ITEM) {
      navigate(PATH_DASHBOARD.item.reminders(itemId ? itemId : '1'));
    } else if (itemLogId) {
      navigate(PATH_DASHBOARD.item.itemLogs(id ? id : '1'));
    } else if (!itemLogId && !reminderId) {
      navigate(PATH_DASHBOARD.general.items);
    } else if (reminderId && !itemLogId) {
      navigate(PATH_DASHBOARD.general.reminder);
    }
  };

  useEffect(() => {
    async function fetchAttachments() {
      if (itemLogId) {
        await dispatch(getItemLogAttachments(Number(itemLogId)));
      } else {
        await dispatch(getReminderAttachments(Number(reminderId)));
      }
    }
    fetchAttachments();
  }, [dispatch]);

  useEffect(() => {
    setTableData(attachments);
  }, [attachments]);

  return (
    <>
      <Helmet>
        <title>{`${translate('attachments')}`} | Properio.io</title>
      </Helmet>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth={false}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5 }}>
            <Button
              onClick={handlerCreationNavigate}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {`${translate('pages.attachment.newAttachment')}`}
            </Button>
            <Button
              onClick={handlerBackNavigate}
              sx={{ marginLeft: 2 }}
              type="button"
              variant="contained"
            >
              {`${translate('back')}`}
            </Button>
          </Box>

          <Card>
            <Divider />
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id.toString())
                  )
                }
                action={
                  <Tooltip title={`${translate('delete')}`}>
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHead}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id.toString())
                      )
                    }
                  />

                  <TableBody>
                    {isLoading || dataFiltered.length === 0 ? (
                      <TableNoData isNotFound={true} />
                    ) : (
                      <>
                        {dataFiltered
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <AttachmentTableRow
                              reminderId={reminderId}
                              user={user}
                              key={row.id}
                              row={row}
                              selected={selected.includes(row.id.toString())}
                              onSelectRow={() => onSelectRow(row.id.toString())}
                              onDeleteRow={() =>
                                handleDeleteRow(row.id.toString())
                              }
                              onEditRow={() => handleEditRow(row.id.toString())}
                            />
                          ))}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(
                            page,
                            rowsPerPage,
                            tableData.length
                          )}
                        />

                        <TableNoData isNotFound={isNotFound} />
                      </>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {selected.length > 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong>{' '}
                {selected.length > 4
                  ? translate('item_five_more')
                  : translate('item_two_four')}
                ?
              </>
            )}
            {selected.length === 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong> {translate('item_one')}?
              </>
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected, itemLogId ? itemLogId.toString() : '');
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  inputData: IAttachment[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (attachment) =>
        attachment.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  return inputData;
}
