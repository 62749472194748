import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import { dispatch } from 'src/redux/store';
import { updateItemTag } from 'src/redux/slices/itemTag';
import { IItemTag } from 'src/@types/itemTag';
import { useState } from 'react';
import { useLocales } from '../../../locales';

interface FormValuesProps {
  name: string;
}

type Props = {
  itemTag?: IItemTag;
};

export default function ItemTagNewEditForm({ itemTag }: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const [editing, setEditing] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.firstName')}`),
  });

  const defaultValues = {
    name: itemTag?.name || '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(updateItemTag(Number(itemTag?.id), data));
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      reset();
      navigate(PATH_DASHBOARD.configuration.itemTagList);
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 5 }}>
          <Button
            onClick={() => navigate(PATH_DASHBOARD.configuration.itemTagList)}
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('name')}`}
              value={values.name}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
          </Box>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            {editing && (
              <>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {`${translate('saveChanges')}`}
                </LoadingButton>
                <Button
                  onClick={() => setEditing(false)}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  {`${translate('close')}`}
                </Button>
              </>
            )}
            {!editing && (
              <Button
                onClick={() => setEditing(true)}
                sx={{ ml: 1 }}
                variant="contained"
              >
                {`${translate('edit')}`}
              </Button>
            )}
          </Box>
        </Card>
      </FormProvider>
    </Container>
  );
}
