// TODO: make it object with keys to get the values easier?
export const ROLES = [
  { value: 'companyManager', label: 'Company manager' },
  { value: 'propertyManager', label: 'Property manager' },
  { value: 'customer', label: 'Customer' },
  { value: 'internalOperator', label: 'Internal operator' },
  { value: 'externalOperator', label: 'External operator' },
  { value: 'admin', label: 'Admin' },
];

export const ROLE_WITHOUT_CUSTOMER = [
  { value: 'companyManager', label: 'Company manager' },
  { value: 'propertyManager', label: 'Property manager' },
  { value: 'internalOperator', label: 'Internal operator' },
  { value: 'externalOperator', label: 'External operator' },
  { value: 'admin', label: 'Admin' },
];

export const CUSTOMER_ROLE = [{ value: 'customer', label: 'Customer' }];

export const COULD_NOT_DELETE = 'Could not delete!';
export const SUCCESS_DELETE = 'Successfully deleted!';
export const COULT_NOT_EDIT = 'Could not update!';
export const SUCCESS_EDIT = 'Successfully updated!';
export const COULD_NOT_CREATE = 'Could not create!';
export const SUCCESS_CREATE = 'Successfully created!';

export const LOW = 'low';
export const LOW_LABEL = 'Low';
export const MEDIUM = 'medium';
export const MEDIUM_LABEL = 'Medium';
export const HIGH = 'high';
export const HIGH_LABEL = 'High';

export const CREATED = 'created';
export const CREATED_LABEL = 'Created';
export const IN_PROGRESS = 'in_progress';
export const IN_PROGRESS_LABEL = 'In progress';
export const DONE = 'done';
export const DONE_LABEL = 'Done';
export const SENT = 'sent';
export const SENT_LABEL = 'Sent';
export const SEEN = 'seen';
export const SEEN_LABEL = 'Seen';

export const SERVICE_REQUEST_PRIORITY = [
  { value: LOW, label: LOW_LABEL },
  { value: MEDIUM, label: MEDIUM_LABEL },
  { value: HIGH, label: HIGH_LABEL },
];

export const SERVICE_REQUEST_STATUS = [
  { value: CREATED, label: 'Created' },
  { value: IN_PROGRESS, label: 'In progress' },
  { value: DONE, label: 'Done' },
  { value: SENT, label: 'Sent' },
  { value: SEEN, label: 'Seen' },
];

export const STATUS_SELECT = [
  { value: IN_PROGRESS, label: 'In progress' },
  { value: DONE, label: 'Done' },
];

export const ROLES_PERMISSIONS = {
  companyManager: 'companyManager',
  propertyManager: 'propertyManager',
  customer: 'customer',
  internalOperator: 'internalOperator',
  externalOperator: 'externalOperator',
  admin: 'admin',
};

export const ROLES_PERMISSIONS_LABEL = {
  admin: 'Admin',
  companyManager: 'Company manager',
  propertyManager: 'Property manager',
  externalOperator: 'External operator',
  internalOperator: 'Internal operator',
  customer: 'Customer',
};

export const CUSTOMER_PERMISSIONS = [
  'service-request',
  'tasks',
  'properties',
  'rooms',
  'items',
];
export const FILTERED_TAG = 'filteredTag';
export const FILTERED_CATEGORY = 'filteredCategory';
export const FILTERED_PROPERTY_IDS = 'filteredPropertyIds';
export const FILTERED_CUSTOMERS_IDS = 'filteredCustomerIds';
export const FILTERED_CITY = 'filteredCity';
export const FILTERED_MANAGER_IDS = 'filteredManagerIds';
export const FILTERED_STATUS = 'filteredStatus';
export const FILTERED_PRIORITY = 'filteredPriorities';

export const PROPERTY = 'property';
export const ROOM = 'room';
export const SERVICE_REQUEST = 'service-request';
export const CUSTOMER = 'customer';
export const REMINDER = 'reminder';
export const ITEM = 'item';
export const ATTACHMENT = 'attachment';

export const ALL_ROLES = [
  ROLES_PERMISSIONS.admin,
  ROLES_PERMISSIONS.companyManager,
  ROLES_PERMISSIONS.propertyManager,
  ROLES_PERMISSIONS.internalOperator,
  ROLES_PERMISSIONS.externalOperator,
  ROLES_PERMISSIONS.customer,
];
export const NO_OPERATORS_ROLES = [
  ROLES_PERMISSIONS.admin,
  ROLES_PERMISSIONS.companyManager,
  ROLES_PERMISSIONS.propertyManager,
  ROLES_PERMISSIONS.customer,
];

export const NO_OPERATOR_NO_CUSTOMER_ROLES = [
  ROLES_PERMISSIONS.admin,
  ROLES_PERMISSIONS.companyManager,
  ROLES_PERMISSIONS.propertyManager,
];

export const ONLY_ADMIN_CUSTOMER_ROLES = [
  ROLES_PERMISSIONS.admin,
  ROLES_PERMISSIONS.customer,
];

export const ADMIN_MANAGERS_ROLES = [
  ROLES_PERMISSIONS.admin,
  ROLES_PERMISSIONS.companyManager,
  ROLES_PERMISSIONS.propertyManager,
];

export const ONLY_ADMIN_ROLES = [ROLES_PERMISSIONS.admin];

export const ONLY_ADMIN_COMPANY_MANAGER_ROLES = [
  ROLES_PERMISSIONS.admin,
  ROLES_PERMISSIONS.companyManager,
];

export const ONLY_ADMIN_COMPANY_CUSTOMER_ROLES = [
  ROLES_PERMISSIONS.admin,
  ROLES_PERMISSIONS.customer,
  ROLES_PERMISSIONS.companyManager,
];

export const NO_CUSTOMER_ROLES = [
  ROLES_PERMISSIONS.admin,
  ROLES_PERMISSIONS.companyManager,
  ROLES_PERMISSIONS.propertyManager,
  ROLES_PERMISSIONS.internalOperator,
  ROLES_PERMISSIONS.externalOperator,
];

export const ONLY_OPERATORS_ROLES = [
  ROLES_PERMISSIONS.internalOperator,
  ROLES_PERMISSIONS.externalOperator,
];

export const STATUS_CODE_FORBIDDEN = 403;
export const STATUS_CODE_INTERNAL_SERVER_ERROR = 500;
