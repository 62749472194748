import * as Yup from 'yup';
import { useCallback, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFTextField } from '../../../../components/hook-form';
import { useDispatch } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { useLocales } from '../../../../locales';
import {
  createItemAttachment,
  createItemLogAttachment,
  createReminderAttachment,
  createServiceRequestAttachment,
  removeUploadedFile,
  uploadAttachment
} from 'src/redux/slices/attachment';
import { Upload } from '../../../../components/upload';

// ----------------------------------------------------------------------

type AttachmentCreateFormProps = {
  itemId?: number;
  itemLogId?: number;
  serviceRequestId?: number;
  reminderId?: number;
};

type FormValuesProps = {
  name: string;
  description: string;
};

export default function AttachmentCreateForm({
  itemId,
  itemLogId,
  serviceRequestId,
  reminderId,
}: AttachmentCreateFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    description: Yup.string(),
  });

  const defaultValues = {
    name: '',
    description: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    if (!fileName)
      return enqueueSnackbar(`${translate('infoMessages.fileNotAttached')}`, {
        variant: 'error',
      });
    try {
      if (itemLogId) {
        await dispatch(createItemLogAttachment(itemLogId, data, fileName));
        navigate(-1);
      } else if (itemId) {
        await dispatch(createItemAttachment(itemId, data, fileName));
        navigate(-1);
      } else if (!itemId && !itemLogId && serviceRequestId) {
        await dispatch(
          createServiceRequestAttachment(serviceRequestId ?? 1, data, fileName)
        );
        navigate(
          PATH_DASHBOARD.serviceRequest.attachments(
            serviceRequestId?.toString() ?? '1'
          )
        );
      } else if (reminderId) {
        await dispatch(createReminderAttachment(reminderId, data, fileName));
        navigate(
          PATH_DASHBOARD.reminder.attachments(reminderId.toString() ?? '1')
        );
      }
      reset();
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  const handleRemove = async () => {
    // TODO: here needs to be check if we are not removing a file already assigned to some Attachment entry
    if (fileName) {
      await dispatch(removeUploadedFile(fileName));

      setFile(null);
      setFileName(null);
    }
  };

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const result = await dispatch(uploadAttachment(file));

      setFileName(result.data.fileName);

      setFile(file);
    },
    [setValue]
  );

  const handleNavigateBack = () => {
    if (itemLogId || itemId) {
      navigate(-1);
    } else if (serviceRequestId) {
      navigate(
        PATH_DASHBOARD.serviceRequest.attachments(
          serviceRequestId?.toString() ?? '1'
        )
      );
    } else if (reminderId) {
      navigate(
        PATH_DASHBOARD.reminder.attachments(reminderId.toString() ?? '1')
      );
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
        <Button
          onClick={handleNavigateBack}
          sx={{ float: 'right' }}
          type="button"
          variant="contained"
        >
          {`${translate('back')}`}
        </Button>
      </Stack>
      <Card sx={{ p: 3 }}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)',
          }}
        >
          <RHFTextField
            name="name"
            label={`${translate('name')}`}
            value={values.name}
            required
          />

          <RHFTextField
            name="description"
            label={`${translate('description')}`}
            multiline
            rows={3}
            value={values.description}
          />
        </Box>

        <br />

        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <Upload
            file={file}
            onDrop={handleDrop}
            maxFiles={1}
            disabled={!!file}
          />
          {file && (
            <div>
              <p>Uploaded file: {file.name}</p>
              <button type="button" onClick={handleRemove}>
                Remove file
              </button>
            </div>
          )}
        </Box>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          {/*<RHFTextField name="about" multiline rows={4} label="About" />*/}

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('pages.attachment.createNewAttachment')}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
