import React, { useState } from 'react';
// @mui
import { Button, Checkbox, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
// @types
// components
import ConfirmDialog from '../../../../components/confirm-dialog';
import { useLocales } from 'src/locales';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IItemLog } from 'src/@types/itemLog';

// ----------------------------------------------------------------------

type Props = {
  row: IItemLog;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function ItemLogTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { title, content, createdAt } = row;
  const date = new Date(createdAt);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { translate } = useLocales();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/*<Avatar alt={name} src={avatarUrl} />*/}

            <Typography
              variant="subtitle2"
              onClick={() => onEditRow()}
              style={{ cursor: 'pointer' }}
              noWrap
            >
              {title}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">{row.authorName}</TableCell>
        <TableCell align="left">{content}</TableCell>
        <TableCell align="left">{`${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`}</TableCell>

        <TableCell align="right">
          <Tooltip title={`${translate('edit')}`} sx={{ cursor: 'pointer' }}>
            <EditIcon
              onClick={() => {
                onEditRow();
                handleClosePopover();
              }}
            />
          </Tooltip>
          <Tooltip title={`${translate('delete')}`} sx={{ cursor: 'pointer' }}>
            <DeleteIcon
              onClick={() => {
                handleOpenConfirm();
                handleClosePopover();
              }}
            />
          </Tooltip>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('infoMessages.deletePromptV2')}`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
