import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// auth
// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import { useDispatch } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../locales';
import { updateRequestComment } from 'src/redux/slices/requestComment';
import { IRequestComment } from 'src/@types/requestComment';

// ----------------------------------------------------------------------
type FormValuesProps = {
  content: string;
  serviceRequestId: string;
};

type Props = {
  requestComment: IRequestComment | null;
  disabledEdit?: boolean;
};

export default function RequestCommentEditForm({
  requestComment,
  disabledEdit,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [editing, setEditing] = useState<boolean>(false);
  const requestCommentId = requestComment?.id;
  const serviceRequestId = requestComment?.serviceRequest.id;

  const [defaultValues, setDefaultValues] = useState<FormValuesProps>({
    content: '',
    serviceRequestId: '',
  });

  useEffect(() => {
    if (requestComment) {
      setDefaultValues((prevDefaultValues) => ({
        ...prevDefaultValues,
        content: requestComment?.content || '',
        serviceRequestId: requestComment?.serviceRequest.id || '',
      }));
      reset(defaultValues);
    }
  }, [requestComment]);

  const UpdateRequestCommentSchema = Yup.object().shape({
    content: Yup.string().required(`${translate('isRequired.content')}`),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateRequestCommentSchema),
    values: defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;
  const values = watch();

  // TODO: ADD onIvalid
  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(
        updateRequestComment(
          requestCommentId ? Number(requestCommentId) : 1,
          data
        )
      );
      reset();
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      navigate(
        PATH_DASHBOARD.serviceRequest.comments(
          serviceRequestId ? serviceRequestId : '1'
        )
      );
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
          <Button
            onClick={() =>
              navigate(
                PATH_DASHBOARD.serviceRequest.comments(
                  serviceRequestId ? serviceRequestId : '1'
                )
              )
            }
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          <Box rowGap={3} columnGap={2} display="grid">
            <RHFTextField
              name="content"
              label={`${translate('content')}`}
              value={values.content}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              multiline
              minRows={3}
              required
              fullWidth
            />
          </Box>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            {editing && (
              <>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {`${translate('saveChanges')}`}
                </LoadingButton>
                <Button
                  onClick={() => setEditing(false)}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  {`${translate('close')}`}
                </Button>
              </>
            )}
            {!editing && !disabledEdit && (
              <Button
                onClick={() => setEditing(true)}
                sx={{ ml: 1 }}
                variant="contained"
              >
                {`${translate('edit')}`}
              </Button>
            )}
          </Box>
        </Card>
      </FormProvider>
    </Container>
  );
}
