import { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListSubheader,
  Tooltip,
  Typography,
} from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import {
  ENotificationStatus,
  ENotificationType,
  INotification,
} from 'src/@types/notification';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import {
  getNotifications,
  readNotification,
} from 'src/redux/slices/notification';
import { useDispatch, useSelector } from 'src/redux/store';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useAuthContext } from 'src/auth/useAuthContext';
import { ROLES_PERMISSIONS } from 'src/constants';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { translate } = useLocales();
  const { notifications, totalUnRead } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());
    const myInterval = setInterval(() => {
      dispatch(getNotifications());
    }, 10000);

    return () => clearInterval(myInterval);
  }, [dispatch]);

  const handleMarkAsRead = (id: number) => {
    dispatch(readNotification(id));
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = () => {
    notifications.forEach((notification) => {
      dispatch(readNotification(notification.id));
    });
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 360, p: 0, height: 600 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{`${translate(
              'notification.notification'
            )}`}</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {`${translate('notification.unreadMessages')}: ${totalUnRead} `}
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title={`${translate('notification.markAllAsRead')}`}>
              <IconButton color="primary" onClick={() => handleMarkAllAsRead()}>
                <MarkChatReadIcon color="primary" sx={{ float: 'right' }} />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: '500px !important' }}>
          <List
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                {`${translate('notification.new')}`}
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleMarkAsRead={handleMarkAsRead}
                closePopover={handleClosePopover}
              />
            ))}
          </List>

          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: 'overline' }}
              >
                {`${translate('notification.beforeThat')}`}
              </ListSubheader>
            }
          >
            {notifications.slice(2, 10).map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                handleMarkAsRead={handleMarkAsRead}
                closePopover={handleClosePopover}
              />
            ))}
          </List>
        </Scrollbar>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

type NotificationItemProps = {
  notification: INotification;
  handleMarkAsRead: (id: number) => void;
  closePopover: () => void;
};

function NotificationItem({
  notification,
  handleMarkAsRead,
  closePopover,
}: NotificationItemProps) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const isUserCustomer =
    user?.roles.includes(ROLES_PERMISSIONS.customer) && user.roles.length === 1;

  const handleClickRedirect = (entityId: number, entityType: string) => {
    handleMarkAsRead(notification.id);
    if (isUserCustomer && notification.entityType === ENotificationType.TASK)
      return;

    closePopover();

    switch (entityType) {
      case ENotificationType.SERVICE_REQUEST:
        navigate(PATH_DASHBOARD.serviceRequest.view(String(entityId)));
        break;
      case ENotificationType.SERVICE_REQUEST_ACCEPT:
        navigate(PATH_DASHBOARD.serviceRequest.view(String(entityId)));
        break;
      case ENotificationType.SERVICE_REQUEST_COMMENT:
        navigate(PATH_DASHBOARD.comment.view(String(entityId)));
        break;
      case ENotificationType.ATTACHMENT:
        navigate(PATH_DASHBOARD.attachment.viewAttachment(String(entityId)));
        break;
      case ENotificationType.TASK:
        navigate(PATH_DASHBOARD.task.view(String(entityId)), { replace: true });
        break;
      case ENotificationType.REMINDER:
        navigate(PATH_DASHBOARD.reminder.view(String(entityId)), {
          replace: true,
        });
        break;
      default:
        return;
    }
  };

  const getMessageByType = (type: string) => {
    switch (type) {
      case ENotificationType.SERVICE_REQUEST:
        return `${translate('notification.serviceRequestCreate')}`;
      case ENotificationType.SERVICE_REQUEST_COMMENT:
        return `${translate('notification.commentCreate')}`;
      case ENotificationType.ATTACHMENT:
        return `${translate('notification.attachmentCreate')}`;
      case ENotificationType.TASK:
        return `${translate('notification.taskCreate')}`;
      case ENotificationType.SERVICE_REQUEST_ACCEPT:
        return `${translate('notification.serviceRequestAccept')}`;
      default:
        return '';
    }
  };

  return (
    <ListItemButton
      sx={{
        ...(notification.status === ENotificationStatus.CREATED && {
          bgcolor: 'action.selected',
        }),
      }}
      onClick={() =>
        handleClickRedirect(notification.entityId, notification.entityType)
      }
    >
      <div style={{ display: 'flex' }}>
        <div>
          <Typography variant="subtitle2">
            {notification.title
              ? notification.title
              : getMessageByType(notification.entityType)}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {notification.message}
          </Typography>
        </div>
        <div>
          <Typography variant="caption" sx={{ float: 'right' }}>
            {fToNow(notification.created)}
          </Typography>
          {notification.status === ENotificationStatus.CREATED && (
            <Tooltip title={`${translate('notification.markAsRead')}`}>
              <IconButton
                color="primary"
                onClick={() => handleMarkAsRead(notification.id)}
                sx={{ float: 'right' }}
              >
                <MarkChatReadIcon color="primary" sx={{ float: 'right' }} />
              </IconButton>
            </Tooltip>
          )}
          {notification.status === ENotificationStatus.READ && (
            <Tooltip title={`${translate('notification.markedAsRead')}`}>
              <IconButton
                color="primary"
                onClick={() => handleMarkAsRead(notification.id)}
                sx={{ float: 'right' }}
              >
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </ListItemButton>
  );
}
