import React, { useState } from 'react';
// @mui
import { Button, Checkbox, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
// @types
// components
import ConfirmDialog from '../../../../components/confirm-dialog';
import { useLocales } from 'src/locales';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IAttachment } from 'src/@types/attachment';
import { CloudDownload } from '@mui/icons-material';
import { ROLES_PERMISSIONS } from 'src/constants';
import { AuthUserType } from 'src/auth/types';

// ----------------------------------------------------------------------

type Props = {
  row: IAttachment;
  selected: boolean;
  reminderId?: string;
  user: AuthUserType | null;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function AttachmentTableRow({
  row,
  selected,
  user,
  reminderId,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, description, authorName, url, createdAt, authorId } = row;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { translate } = useLocales();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleRowIcon = () => {
    if (!reminderId) return true;
    else if (
      user?.id === authorId ||
      user?.roles.some((role: string) => role === ROLES_PERMISSIONS.admin)
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/*<Avatar alt={name} src={avatarUrl} />*/}

            <Typography
              variant="subtitle2"
              onClick={() => onEditRow()}
              style={{ cursor: 'pointer' }}
              noWrap
            >
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">{description}</TableCell>
        <TableCell align="left">
          {authorName !== ''
            ? authorName
            : `${translate('pages.attachment.noAuthor')}`}
        </TableCell>
        <TableCell align="left">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <CloudDownload />
          </a>
        </TableCell>
        <TableCell align="left">
          {new Date(createdAt).toLocaleDateString()}
        </TableCell>

        <TableCell align="right">
          {handleRowIcon() ? (
            <>
              <Tooltip
                title={`${translate('edit')}`}
                sx={{ cursor: 'pointer' }}
              >
                <EditIcon
                  onClick={() => {
                    onEditRow();
                    handleClosePopover();
                  }}
                />
              </Tooltip>
              <Tooltip
                title={`${translate('delete')}`}
                sx={{ cursor: 'pointer' }}
              >
                <DeleteIcon
                  onClick={() => {
                    handleOpenConfirm();
                    handleClosePopover();
                  }}
                />
              </Tooltip>{' '}
            </>
          ) : (
            <></>
          )}
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('infoMessages.deletePromptV2')}`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
