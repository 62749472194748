import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useParams } from 'react-router';
import ItemCreateForm from 'src/sections/@dashboard/item/create/ItemCreateForm';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function RoomItemCreatePage() {
  const { translate } = useLocales();

  const { id, roomId } = useParams();

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.item.titleCreate')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.item.createItem')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('item')}`,
              href: PATH_DASHBOARD.general.items,
            },
            { name: `${translate('pages.item.createNewItem')}` },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              <ItemCreateForm propertyId={Number(id)} roomId={Number(roomId)} />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
