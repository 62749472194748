import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosAPI';
import { IItemCategory, IItemCategoryState } from '../../@types/itemCategory';

// ----------------------------------------------------------------------

const initialState: IItemCategoryState = {
  isLoading: true,
  error: null,
  itemCategories: [],
  itemCategory: null,
  itemCategorySuperior: null,
  itemCategoriesWithoutParent: [],
};

const slice = createSlice({
  name: 'itemCategory',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET ITEM_CATEGORIES
    getItemCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.itemCategories = action.payload;
    },

    // GET ITEM_CATEGORIES_WITHOUT_PARENT
    getItemCategoriesWithoutParentSuccess(state, action) {
      state.isLoading = false;
      state.itemCategoriesWithoutParent = action.payload;
    },

    // GET ITEM_CATEGORY
    getItemCategorySuccess(state, action) {
      state.isLoading = false;
      state.itemCategory = action.payload;
    },

    // GET ITEM_CATEGORY_SUPERIOR
    getItemCategorySuperiorSuccess(state, action) {
      state.isLoading = false;
      state.itemCategorySuperior = action.payload;
    },

    // UPDATE ITEM_CATEGORY
    updateItemCategorySuccess(state, action) {
      state.isLoading = false;
      state.itemCategory = action.payload;
    },

    // CREATE ITEM_CATEGORY
    createItemCategorySuccess(state, action) {
      state.isLoading = false;
      state.itemCategory = action.payload;
      state.itemCategories = [...state.itemCategories, action.payload];
    },

    // REMOVE ITEM_CATEGORY
    removeItemCategorySuccess(state, action) {
      state.itemCategories = state.itemCategories.filter(
        (itemCategory) => itemCategory.id !== action.payload
      );
      state.isLoading = false;
      // state.itemCategory = action.payload;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.itemCategories = [];
      state.itemCategory = null;
      state.itemCategorySuperior = null;
      state.itemCategoriesWithoutParent = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
const mapItemCategory = (itemCategory: any): IItemCategory => {
  return {
    id: itemCategory?.id ? itemCategory.id : '',
    name: itemCategory?.name ? itemCategory.name : '',
    itemCategoryParent: itemCategory?.itemCategoryParent
      ? itemCategory.itemCategoryParent
      : null,
  };
};

const mapItemCategoryAccount = (itemCategory: any) => {
  return {
    name: itemCategory?.name,
    parentId: itemCategory?.parentId !== '' ? itemCategory?.parentId : null,
  };
};

// ----------------------------------------------------------------------

export function getItemCategories() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/item-categories');

      const result: IItemCategory[] = response?.data?.data?.map(
        (itemCategory: any) => mapItemCategory(itemCategory)
      );

      dispatch(slice.actions.getItemCategoriesSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function getItemCategoriesWithoutParent() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/item-categories/parents');

      const result: IItemCategory[] = response?.data?.data?.map(
        (itemCategory: any) => mapItemCategory(itemCategory)
      );

      dispatch(slice.actions.getItemCategoriesWithoutParentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

// ----------------------------------------------------------------------

export function getItemCategory(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/item-categories/' + id, {
        params: { id },
      });

      const result: IItemCategory = mapItemCategory(response?.data?.data);

      dispatch(slice.actions.getItemCategorySuccess(result));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function getItemCategorySuperior(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/item-categories/' + id + '/superior', {
        params: { id },
      });

      const result: IItemCategory = mapItemCategory(response?.data?.data);

      dispatch(slice.actions.getItemCategorySuperiorSuccess(result));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function updateItemCategory(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        '/item-categories/' + id,
        mapItemCategoryAccount(data)
      );

      const result: IItemCategory = mapItemCategory(response?.data?.data);

      dispatch(slice.actions.updateItemCategorySuccess(result));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function createItemCategory(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/item-categories/',
        mapItemCategoryAccount(data)
      );

      const result: IItemCategory = mapItemCategory(response?.data?.data);

      dispatch(slice.actions.createItemCategorySuccess(result));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function removeItemCategory(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.delete('/item-categories/' + id);

      dispatch(slice.actions.removeItemCategorySuccess(id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function resetItemCategories() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
