import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Box, Card, Container, Grid, Tab, Tabs } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import Iconify from '../../../components/iconify';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { AccountGeneral } from '../../../sections/@dashboard/customer/account';
import { getCustomer } from '../../../redux/slices/customer';
import { useDispatch, useSelector } from '../../../redux/store';
import { useLocation, useParams } from 'react-router-dom';
import PropertyListPage from '../property/PropertyListPage';
import { useLocales } from '../../../locales';
import UserOriginalListPage from '../userOriginal/UserOriginalListPage';
import useFlushNavigate from 'src/hooks/useFlushNavigate';

// ----------------------------------------------------------------------

export default function CustomerAccountPage() {
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { customer } = useSelector((state) => state.customer);
  const [currentTab, setCurrentTab] = useState(`customer/${id}/general`);
  const { flushRedux } = useFlushNavigate();

  const tabHandler = (tab: string) => {
    flushRedux();
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (id) {
      dispatch(getCustomer(Number(id)));
    }
  }, [dispatch, id, currentTab]);

  useEffect(() => {
    const newPathname = `/dashboard/${currentTab}`;
    window.history.pushState(null, '', newPathname);
  }, [currentTab]);

  useEffect(() => {
    const tabFromPathname = location.pathname.replace('/dashboard/', '');
    tabHandler(tabFromPathname);
  }, [location.pathname]);

  const TABS = [
    {
      value: `customer/${id}/general`,
      label: `${translate('general')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral id={id} />,
    },
    {
      value: `customer/${id}/properties`,
      label: `${translate('properties')}`,
      icon: <Iconify icon="ic:round-receipt" />,
      component: <PropertyListPage customerId={id} />,
    },
    {
      value: `customer/${id}/users`,
      label: `${translate('users')}`,
      icon: <Iconify icon="ic:round-receipt" />,
      component: <UserOriginalListPage customerId={Number(id)} />,
    },
  ];

  const content = (
    <>
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => tabHandler(newValue)}
      >
        {TABS.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>

      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box key={tab.value} sx={{ mt: 5 }}>
              {tab.component}
            </Box>
          )
      )}
    </>
  );

  const renderContent = () => {
    if (currentTab === TABS[0].value) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              {content}
            </Card>
          </Grid>
        </Grid>
      );
    }
    return (
      <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
        {content}
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`${translate('customer')}`}: {customer?.name ?? ''} | Properio.io
        </title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.customer.customerDetail')}`}
          links={[
            { name: `${translate('dashboard')}`, href: PATH_DASHBOARD.root },
            {
              name: `${translate('customers')}`,
              href: PATH_DASHBOARD.general.customers,
            },
            { name: customer?.name ?? '' },
          ]}
        />
        {renderContent()}
      </Container>
    </>
  );
}
