import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosAPI';
import {
  IRequestComment,
  IRequestCommentState,
} from 'src/@types/requestComment';

// ----------------------------------------------------------------------

const initialState: IRequestCommentState = {
  isLoading: true,
  error: null,
  requestComments: [],
  requestComment: null,
};

const slice = createSlice({
  name: 'requestComment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET REQUEST COMMENTS
    getRequestCommentsSuccess(state, action) {
      state.requestComments = action.payload;
      state.isLoading = false;
    },

    // GET REQUEST COMMENT
    getRequestCommentSuccess(state, action) {
      state.requestComment = action.payload;
      state.isLoading = false;
    },

    // UPDATE REQUEST COMMENT
    updateRequestCommentSuccess(state, action) {
      state.requestComment = action.payload;
      state.isLoading = false;
    },

    // CREATE REQUEST COMMENT
    createRequestCommentSuccess(state, action) {
      state.requestComment = action.payload;
      state.isLoading = false;
    },

    // REMOVE REQUEST COMMENT
    removeRequestCommentSuccess(state, action) {
      state.isLoading = false;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.requestComments = [];
      state.requestComment = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
function mapToRequestComment<T>(requestComment: any): T {
  return {
    id: requestComment?.id ? requestComment.id : '',
    title: requestComment?.title ? requestComment.title : '',
    content: requestComment?.content ? requestComment.content : '',
    serviceRequest: requestComment?.serviceRequest
      ? requestComment.serviceRequest
      : '',
    author: requestComment?.author ? requestComment.author : '',
    createdAt: requestComment?.created ? requestComment.created : '',
    updatedAt: requestComment?.updated ? requestComment.updated : '',
  } as T;
}

const mapRequestCommentAccount = (requestComment: any) => {
  return {
    content: requestComment?.content,
    serviceRequestId: requestComment?.serviceRequestId,
  };
};

// ----------------------------------------------------------------------

export function getRequestComments(serviceRequestId: number | null = null) {
  return async (dispatch: Dispatch) => {
    try {
      let response;
      if (serviceRequestId) {
        response = await axios.get('/request-comments', {
          params: { serviceRequestId },
        });
      } else {
        response = await axios.get('/request-comments');
      }

      const result: IRequestComment[] = response?.data?.data?.map(
        (requestComment: any) =>
          mapToRequestComment<IRequestComment>(requestComment)
      );

      dispatch(slice.actions.getRequestCommentsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getRequestComment(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/request-comments/' + id);

      const result: IRequestComment = mapToRequestComment<IRequestComment>(
        response?.data?.data
      );

      dispatch(slice.actions.getRequestCommentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateRequestComment(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        '/request-comments/' + id,
        mapRequestCommentAccount(data)
      );

      const result: IRequestComment = mapToRequestComment<IRequestComment>(
        response?.data?.data
      );

      dispatch(slice.actions.updateRequestCommentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function createRequestComment(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/request-comments',
        mapRequestCommentAccount(data)
      );

      const result: IRequestComment = mapToRequestComment<IRequestComment>(
        response?.data?.data
      );

      dispatch(slice.actions.createRequestCommentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function removeRequestComment(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/request-comments/' + id);
      const result = response?.data;
      dispatch(slice.actions.removeRequestCommentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // Throw the error to propagate it to the calling function
      throw new Error('Error occurred during remove: ' + error.message);
    }
  };
}

export function resetRequestComments() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
