import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  SelectChangeEvent,
  StackProps,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable
} from '../../../components/table';
// sections
import CustomerTableToolbar from '../../../sections/@dashboard/customer/list/CustomerTableToolbar';
import CustomerTableRow from '../../../sections/@dashboard/customer/list/CustomerTableRow';
import { useDispatch, useSelector } from '../../../redux/store';
import { getCustomerCities, getCustomers, removeCustomer } from 'src/redux/slices/customer';
import { useSnackbar } from 'notistack';
import { useLocales } from '../../../locales';
import { ICustomer } from 'src/@types/customer';
import CustomSimpleFilter from '../CustomSimpleFilter';
import { FILTERED_CITY } from 'src/constants';
import useDeleteRowsHandler from 'src/hooks/useDeleteHandler';
import { getPageFromStorage, savePageToStorage } from 'src/utils/pageStorageHandler';
import { getRowsPerPageFromStorage, saveRowsPerPageToStorage } from 'src/utils/rowsPerPageStorageHandler';

// ----------------------------------------------------------------------

const CITY_OPTIONS = [
  'all',
  'ux designer',
  'full stack designer',
  'backend developer',
  'project manager',
  'leader',
  'ui designer',
  'ui/ux designer',
  'front end developer',
  'full stack developer',
];

// ----------------------------------------------------------------------

export default function CustomerListPage() {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { deleteHandlers } = useDeleteRowsHandler();
  const { customers, isLoading } = useSelector((state) => state.customer);
  const [tableData, setTableData] = useState<ICustomer[]>([]);
  const [filterName, setFilterName] = useState('');
  const [filterCity, setFilterCity] = useState('all');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterContact, setFilterContact] = useState('all');
  const [cities, setCities] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState<string[]>([]);

  const tableHead = [
    { id: 'name', label: `${translate('name')}`, align: 'left' },
    { id: 'businessId', label: `${translate('businessId')}`, align: 'left' },
    { id: 'taxId', label: `${translate('taxId')}`, align: 'left' },
    { id: 'vatId', label: `${translate('vatId')}`, align: 'left' },
    { id: 'city', label: `${translate('city')}`, align: 'left' },
    { id: 'actions', label: `${translate('actions')}`, align: 'right' },
  ];

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterCity,
    filterContact,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 52 : 72;

  const isFiltered =
    filterName !== '' || filterCity !== 'all' || filterContact !== 'all';

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterCity) ||
    (!dataFiltered.length && !!filterContact);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterCity(event.target.value);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await dispatch(removeCustomer(Number(id)));
      await dispatch(getCustomers());
      const deleteRow = tableData.filter(
        (row) => Number(row.id) !== Number(id)
      );
      setSelected([]);
      setTableData(deleteRow);
      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    try {
      if (await deleteHandlers.deleteRows(selectedRows, removeCustomer)) {
        return;
      }

      const filteredRows = tableData.filter(
        (row: any) => !selectedRows.includes(row.id.toString())
      );
      setSelected([]);
      setTableData(filteredRows);

      if (page > 0) {
        if (selectedRows.length === dataInPage.length) {
          setPage(page - 1);
        } else if (selectedRows.length === dataFiltered.length) {
          setPage(0);
        } else if (selectedRows.length > dataInPage.length) {
          const newPage =
            Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
            1;
          setPage(newPage);
        }
      }

      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleEditRow = (id: string) => {
    savePageToStorage('customer', page);
    saveRowsPerPageToStorage('customer', rowsPerPage);
    navigate(PATH_DASHBOARD.customer.view(id));
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterCity('all');
    setFilterContact('all');
  };

  const handleChange = async (
    event: SelectChangeEvent<typeof selectedCity>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedCity(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    await dispatch(getCustomerCities(selectedCity));
  };

  useEffect(() => {
    dispatch(getCustomers());
  }, [dispatch]);

  useEffect(() => {
    if (customers.length) {
      setTableData(customers);
    }
    if (cities.length === 0) {
      // unique cities
      const cities = customers
        .map((customer) => customer.address.city)
        .filter((city, index, self) => self.indexOf(city) === index);
      setCities(cities);
    }
  }, [customers]);

  useEffect(() => {
    const savedPage = getPageFromStorage('customer');
    setPage(savedPage ?? 0);
    const savedRowsPerPage = getRowsPerPageFromStorage('customer');
    setRowsPerPage(savedRowsPerPage ?? 5);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.customer.title')}`}</title>
      </Helmet>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth={false}>
          <CustomBreadcrumbs
            heading={`${translate('pages.customer.heading')}`}
            links={[
              { name: `${translate('dashboard')}`, href: PATH_DASHBOARD.root },
              {
                name: `${translate('customers')}`,
                href: PATH_DASHBOARD.general.customers,
              },
              { name: `${translate('pages.customer.heading')}` },
            ]}
            action={
              <Button
                component={RouterLink}
                to={PATH_DASHBOARD.customer.create}
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                {`${translate('pages.customer.newCustomer')}`}
              </Button>
            }
          />

          <Card>
            <Divider />

            <div style={{ marginLeft: 20 }}>
              <CustomSimpleFilter
                filteredData={selectedCity}
                dataToFilter={cities}
                handleChange={handleChange}
                updating={FILTERED_CITY}
              />
            </div>

            <CustomerTableToolbar
              isFiltered={isFiltered}
              filterName={filterName}
              filterCity={filterCity}
              optionsCity={CITY_OPTIONS}
              onFilterName={handleFilterName}
              onFilterCity={handleFilterCity}
              onResetFilter={handleResetFilter}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id.toString())
                  )
                }
                action={
                  <Tooltip title={`${translate('delete')}`}>
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHead}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id.toString())
                      )
                    }
                  />

                  <TableBody>
                    {isLoading || dataFiltered.length === 0 ? (
                      <TableNoData isNotFound={true} />
                    ) : (
                      <>
                        {dataFiltered
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <CustomerTableRow
                              key={row.id}
                              row={row}
                              selected={selected.includes(row.id.toString())}
                              onSelectRow={() => onSelectRow(row.id.toString())}
                              onDeleteRow={() =>
                                handleDeleteRow(row.id.toString())
                              }
                              onEditRow={() => handleEditRow(row.id.toString())}
                            />
                          ))}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(
                            page,
                            rowsPerPage,
                            tableData.length
                          )}
                        />

                        <TableNoData isNotFound={isNotFound} />
                      </>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {selected.length > 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong>{' '}
                {selected.length > 4
                  ? translate('item_five_more')
                  : translate('item_two_four')}
                ?
              </>
            )}
            {selected.length === 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong> {translate('item_one')}?
              </>
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterCity,
  filterContact,
}: {
  inputData: ICustomer[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterCity: string;
  filterContact: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (customer) =>
        customer.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  // if (filterContact !== 'all') {
  //   inputData = inputData.filter((user) => user.status === filterContact);
  // }
  //
  // if (filterCity !== 'all') {
  //   inputData = inputData.filter((user) => user.role === filterCity);
  // }

  return inputData;
}
