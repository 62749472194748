import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Box, Card, Container, Grid, Tab, Tabs } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import Iconify from '../../../components/iconify';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useDispatch, useSelector } from '../../../redux/store';
import { useLocation, useParams } from 'react-router-dom';
import { useLocales } from '../../../locales';
import { getItemLogs } from 'src/redux/slices/itemLog';
import ItemLogNewEditForm from 'src/sections/@dashboard/itemLog/ItemLogNewEditForm';
import AttachmentListPage from '../attachment/AttachmentListPage';
import useFlushNavigate from 'src/hooks/useFlushNavigate';

// ----------------------------------------------------------------------

export default function ItemLogEditPage() {
  const { itemLogId, id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { itemLogs, isLoading } = useSelector((state) => state.itemLog);
  const [currentTab, setCurrentTab] = useState(
    `item/${id}/item-logs/${itemLogId}/general`
  );
  const itemLog = itemLogs.filter((log) => log.id.toString() === itemLogId)[0];
  const { flushRedux } = useFlushNavigate();

  const tabHandler = (tab: string) => {
    flushRedux();
    setCurrentTab(tab);
  };

  useEffect(() => {
    const newPathname = `/dashboard/${currentTab}`;
    window.history.pushState(null, '', newPathname);
  }, [currentTab]);

  useEffect(() => {
    const tabFromPathname = location.pathname.replace('/dashboard/', '');
    tabHandler(tabFromPathname);
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getItemLogs(Number(id)));
  }, [dispatch, itemLogId, currentTab]);

  const TABS = [
    {
      value: `item/${id}/item-logs/${itemLogId}/general`,
      label: `${translate('general')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: <ItemLogNewEditForm itemLog={itemLog} />,
    },
    {
      value: `item/${id}/item-logs/${itemLogId}/attachments`,
      label: `${translate('attachments')}`,
      icon: <Iconify icon="ic:round-receipt" />,
      component: <AttachmentListPage itemLogId={itemLogId} />,
    },
  ];

  const content = (
    <>
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => tabHandler(newValue)}
      >
        {TABS.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>

      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box key={tab.value} sx={{ mt: 5 }}>
              {tab.component}
            </Box>
          )
      )}
    </>
  );

  const renderContent = () => {
    if (currentTab === TABS[0].value) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              {content}
            </Card>
          </Grid>
        </Grid>
      );
    }
    return (
      <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
        {content}
      </Card>
    );
  };
  return (
    <>
      <Helmet>
        {/* <title>{`${translate('itemLog')}`}: {itemLog?.title} | Properio.io</title> */}
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.itemLog.itemLogDetail')}`}
          links={[
            { name: `${translate('dashboard')}`, href: PATH_DASHBOARD.root },
            {
              name: `${translate('itemLogs')}`,
              href: PATH_DASHBOARD.general.items,
            },
            { name: itemLog?.title ?? '' },
          ]}
        />
        {renderContent()}
      </Container>
    </>
  );
}
