import React, { useState } from 'react';
// @mui
import { Button, Checkbox, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
// @types
// components
import ConfirmDialog from '../../../../components/confirm-dialog';
import { IUserOriginal } from 'src/@types/user';
import { ROLES, ROLES_PERMISSIONS } from 'src/constants';
import { useLocales } from 'src/locales';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAuthContext } from 'src/auth/useAuthContext';

// ----------------------------------------------------------------------

type Props = {
  row: IUserOriginal;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function UserOriginalTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { firstName, lastName, roles, email } = row;
  const { user } = useAuthContext();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { translate } = useLocales();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleClick = () => {
    onEditRow();
  };

  return (
    <>
      <TableRow hover selected={selected}>
        {!user?.roles.includes(ROLES_PERMISSIONS.customer) && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell>
        )}

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/*<Avatar alt={name} src={avatarUrl} />*/}

            <Typography
              variant="subtitle2"
              onClick={handleClick}
              style={{
                cursor: 'pointer',
              }}
              noWrap
            >
              {lastName}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">{firstName}</TableCell>

        <TableCell align="left">{email}</TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {roles.map((role: string, index) => (
            <React.Fragment key={role}>
              {ROLES.find((item) => item.value === role)?.label}
              {index !== roles.length - 1 && ','}{' '}
              {/* Add a comma if it's not the last item */}
            </React.Fragment>
          ))}
        </TableCell>
        <TableCell align="right">
          <Tooltip title={`${translate('edit')}`} sx={{ cursor: 'pointer' }}>
            <EditIcon
              onClick={() => {
                onEditRow();
                handleClosePopover();
              }}
            />
          </Tooltip>
          {!user?.roles.includes(ROLES_PERMISSIONS.customer) && (
            <Tooltip
              title={`${translate('delete')}`}
              sx={{ cursor: 'pointer' }}
            >
              <DeleteIcon
                onClick={() => {
                  handleOpenConfirm();
                  handleClosePopover();
                }}
              />
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('infoMessages.deletePromptV2')}`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
