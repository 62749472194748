import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable
} from '../../../components/table';
// sections
import { FilterData, IItem } from 'src/@types/item';
import ItemTableRow from 'src/sections/@dashboard/item/list/ItemTableRow';
import { useDispatch, useSelector } from '../../../redux/store';
import { getFilteredItems, getItems, getPropertyItems, getRoomItems, removeItem } from 'src/redux/slices/item';
import { getCustomers } from '../../../redux/slices/customer';
import { useSnackbar } from 'notistack';
import { useLocales } from '../../../locales';
import ItemTableToolbar from 'src/sections/@dashboard/item/list/ItemTableToolbar';
import { IProperty } from 'src/@types/property';
import { ICustomer } from 'src/@types/customer';
import { IItemTag } from 'src/@types/itemTag';
import { IItemCategory } from 'src/@types/itemCategory';
import { getItemTags } from 'src/redux/slices/itemTag';
import { getItemCategories } from 'src/redux/slices/itemCategory';
import { getProperties, setPropertyToNull } from 'src/redux/slices/property';
import {
  ADMIN_MANAGERS_ROLES,
  CUSTOMER,
  FILTERED_CATEGORY,
  FILTERED_CUSTOMERS_IDS,
  FILTERED_PROPERTY_IDS,
  FILTERED_TAG,
  PROPERTY,
  ROLES_PERMISSIONS,
  ROOM
} from 'src/constants';
import CustomFilter from '../CustomFilter';
import { useAuthContext } from 'src/auth/useAuthContext';
import useDeleteRowsHandler from 'src/hooks/useDeleteHandler';
import { setLastUrlItems } from 'src/redux/slices/setting';
import { allowTo } from 'src/utils/permissionHandler';
import { getPageFromStorage, savePageToStorage } from 'src/utils/pageStorageHandler';
import { getRowsPerPageFromStorage, saveRowsPerPageToStorage } from 'src/utils/rowsPerPageStorageHandler';

type ItemListPageProps = {
  propertyId?: string;
  roomId?: string;
  customerId?: string;
};

type Filter = {
  properties: IProperty[];
  customers: ICustomer[];
  tags: IItemTag[];
  categories: IItemCategory[];
};

export default function ItemListPage({
  propertyId,
  roomId,
  customerId,
}: ItemListPageProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { deleteHandlers } = useDeleteRowsHandler();
  const { items, isLoading: itemLoading } = useSelector((state) => state.item);
  const { properties } = useSelector((state) => state.property);
  const { customers, isLoading: customerLoading } = useSelector(
    (state) => state.customer
  );
  const { itemTags, isLoading: itemTagLoading } = useSelector(
    (state) => state.itemTag
  );
  const { itemCategories, isLoading: itemCategoryLoading } = useSelector(
    (state) => state.itemCategory
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { lastUrlItems, lastUrlRooms, lastUrlProperties } = useSelector(
    (state) => state.setting
  );
  const [tableData, setTableData] = useState<IItem[]>([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const [dataToFilter, setDataToFilter] = useState<Filter>({
    properties: [],
    customers: [],
    tags: [],
    categories: [],
  });
  const [filteredData, setFilteredData] = useState<FilterData>({
    [FILTERED_TAG]: [],
    [FILTERED_CATEGORY]: [],
    [FILTERED_PROPERTY_IDS]: [],
    [FILTERED_CUSTOMERS_IDS]: [],
  });

  const tableHead = [
    { id: 'name', label: `${translate('name')}`, align: 'left' },
    { id: 'property', label: `${translate('property')}`, align: 'left' },
    { id: 'room', label: `${translate('room')}`, align: 'left' },
    {
      id: 'itemCategory',
      label: `${translate('itemCategory')}`,
      align: 'left',
    },
    { id: 'itemTag', label: `${translate('itemTag')}`, align: 'left' },
    { id: 'actions', label: `${translate('actions')}`, align: 'right' },
  ];

  const uniqueHandler = (arr: any[]) => {
    return arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  };

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 52 : 72;

  const isFiltered =
    filterName !== '' || filterRole !== 'all' || filterStatus !== 'all';

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await dispatch(removeItem(Number(id)));
      if (!propertyId) {
        await dispatch(getItems());
      } else {
        await dispatch(getPropertyItems(Number(propertyId)));
      }

      const deleteRow = tableData.filter(
        (row) => Number(row.id) !== Number(id)
      );
      setSelected([]);
      setTableData(deleteRow);

      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    try {
      if (await deleteHandlers.deleteRows(selectedRows, removeItem)) {
        return;
      }

      const filteredRows = tableData.filter(
        (row: any) => !selectedRows.includes(row.id.toString())
      );
      setSelected([]);
      setTableData(filteredRows);

      if (page > 0) {
        if (selectedRows.length === dataInPage.length) {
          setPage(page - 1);
        } else if (selectedRows.length === dataFiltered.length) {
          setPage(0);
        } else if (selectedRows.length > dataInPage.length) {
          const newPage =
            Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
            1;
          setPage(newPage);
        }
      }

      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleEditRow = (id: string) => {
    savePageToStorage('item', page);
    saveRowsPerPageToStorage('item', rowsPerPage);
    if (roomId) {
      dispatch(setLastUrlItems(ROOM));
      navigate(PATH_DASHBOARD.item.view(id));
    } else if (propertyId) {
      dispatch(setLastUrlItems(PROPERTY));
      navigate(PATH_DASHBOARD.item.view(id));
    } else {
      dispatch(setPropertyToNull());
      navigate(PATH_DASHBOARD.item.view(id));
    }
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterRole('all');
    setFilterStatus('all');
  };

  const handleChange = async (
    event: SelectChangeEvent<typeof filteredData>,
    updating: string
  ) => {
    const {
      target: { value },
    } = event;
    setFilteredData({ ...filteredData, [updating]: value });
    await dispatch(getFilteredItems({ ...filteredData, [updating]: value }));
  };

  const handlerNavigateBack = () => {
    if (propertyId && lastUrlItems === PROPERTY && !customerId) {
      navigate(PATH_DASHBOARD.property.items(propertyId));
    } else if (customerId && lastUrlProperties === CUSTOMER) {
      navigate(PATH_DASHBOARD.customer.properties(customerId));
    } else if (propertyId && lastUrlRooms === PROPERTY) {
      navigate(PATH_DASHBOARD.property.rooms(propertyId));
    } else {
      navigate(PATH_DASHBOARD.general.properties);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(getCustomers()),
          dispatch(getItemTags()),
          dispatch(getItemCategories()),
          dispatch(getProperties()),
        ]);

        if (roomId) {
          await dispatch(getRoomItems(Number(roomId)));
          return;
        } else if (!propertyId) {
          await dispatch(getItems());
          dispatch(setLastUrlItems(''));

          if (roomId) {
            await dispatch(getRoomItems(Number(roomId)));
          }
        } else {
          await dispatch(getPropertyItems(Number(propertyId)));
        }
      } catch (error) {
        enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
          variant: 'error',
        });
      }
    };

    fetchData();
  }, [propertyId, roomId]);

  useEffect(() => {
    setTableData(items);
    if (
      dataToFilter.categories.length === 0 ||
      dataToFilter.customers.length === 0 ||
      dataToFilter.properties.length === 0 ||
      dataToFilter.tags.length === 0
    ) {
      setDataToFilter({
        properties: uniqueHandler(properties),
        customers: uniqueHandler(customers),
        tags: uniqueHandler(itemTags),
        categories: uniqueHandler(itemCategories),
      });
    }
  }, [items, properties, customers, itemTags, itemCategories]);

  useEffect(() => {
    const savedPage = getPageFromStorage('item');
    setPage(savedPage ?? 0);
    const savedRowsPerPage = getRowsPerPageFromStorage('item');
    setRowsPerPage(savedRowsPerPage ?? 5);
  }, []);

  useEffect(() => {
    const _isLoading =
      itemLoading || customerLoading || itemTagLoading || itemCategoryLoading;
    setIsLoading(_isLoading);
  }, [itemLoading, customerLoading, itemTagLoading, itemCategoryLoading]);

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Helmet>
            <title>{`${translate('pages.item.title')}`}</title>
          </Helmet>
          <Container maxWidth={false}>
            {propertyId && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5 }}>
                {allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
                  <Button
                    component={RouterLink}
                    to={
                      roomId
                        ? PATH_DASHBOARD.item.createItemFromRoom(
                            propertyId,
                            roomId
                          )
                        : PATH_DASHBOARD.item.createItemFromProperty(propertyId)
                    }
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    {`${translate('pages.item.newItem')}`}
                  </Button>
                )}
                <Button
                  onClick={handlerNavigateBack}
                  sx={{ float: 'right', marginLeft: '13px' }}
                  type="button"
                  variant="contained"
                >
                  {`${translate('back')}`}
                </Button>
              </Box>
            )}
            {!propertyId &&
              allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
                <CustomBreadcrumbs
                  heading={`${translate('pages.item.heading')}`}
                  links={[
                    {
                      name: `${translate('dashboard')}`,
                      href: PATH_DASHBOARD.root,
                    },
                    {
                      name: `${translate('items')}`,
                      href: PATH_DASHBOARD.general.items,
                    },
                    { name: `${translate('pages.item.heading')}` },
                  ]}
                  action={
                    <Button
                      component={RouterLink}
                      to={PATH_DASHBOARD.item.create}
                      variant="contained"
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      {`${translate('pages.item.newItem')}`}
                    </Button>
                  }
                />
              )}
            <Card>
              <Divider />
              <div style={{ marginLeft: 20 }}>
                {user?.roles.includes(ROLES_PERMISSIONS.admin) && (
                  <CustomFilter
                    dataToFilter={dataToFilter.customers}
                    filteredData={filteredData.filteredCustomerIds}
                    handleChange={handleChange}
                    updating={FILTERED_CUSTOMERS_IDS}
                  />
                )}
                <CustomFilter
                  dataToFilter={dataToFilter.properties}
                  filteredData={filteredData.filteredPropertyIds}
                  handleChange={handleChange}
                  updating={FILTERED_PROPERTY_IDS}
                />
                <CustomFilter
                  dataToFilter={dataToFilter.tags}
                  filteredData={filteredData.filteredTag}
                  handleChange={handleChange}
                  updating={FILTERED_TAG}
                />
                <CustomFilter
                  dataToFilter={dataToFilter.categories}
                  filteredData={filteredData.filteredCategory}
                  handleChange={handleChange}
                  updating={FILTERED_CATEGORY}
                />
              </div>
              <ItemTableToolbar
                isFiltered={isFiltered}
                filterName={filterName}
                onFilterName={handleFilterName}
                onResetFilter={handleResetFilter}
              />

              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id.toString())
                    )
                  }
                  action={
                    <Tooltip title={`${translate('delete')}`}>
                      <IconButton color="primary" onClick={handleOpenConfirm}>
                        <Iconify icon="eva:trash-2-outline" />
                      </IconButton>
                    </Tooltip>
                  }
                />

                <Scrollbar>
                  <Table
                    size={dense ? 'small' : 'medium'}
                    sx={{ minWidth: 800 }}
                  >
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={tableHead}
                      rowCount={tableData.length}
                      numSelected={selected.length}
                      onSort={onSort}
                      onSelectAllRows={
                        !allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES)
                          ? undefined
                          : (checked) =>
                              onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id.toString())
                              )
                      }
                    />

                    <TableBody>
                      <>
                        {dataFiltered
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <ItemTableRow
                              key={row.id}
                              row={row}
                              selected={selected.includes(row.id.toString())}
                              onSelectRow={() => onSelectRow(row.id.toString())}
                              onDeleteRow={() =>
                                handleDeleteRow(row.id.toString())
                              }
                              onEditRow={() => handleEditRow(row.id.toString())}
                            />
                          ))}
                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(
                            page,
                            rowsPerPage,
                            tableData.length
                          )}
                        />
                        <TableNoData isNotFound={isNotFound} />
                      </>
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>

              <TablePaginationCustom
                count={dataFiltered.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                //
                dense={dense}
                onChangeDense={onChangeDense}
              />
            </Card>
          </Container>
        </>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {selected.length > 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong>{' '}
                {selected.length > 4
                  ? translate('item_five_more')
                  : translate('item_two_four')}
                ?
              </>
            )}
            {selected.length === 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong> {translate('item_one')}?
              </>
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  inputData: IItem[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (item) => item.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  // if (filterStatus !== 'all') {
  //   inputData = inputData.filter((user) => user.status === filterStatus);
  // }
  //
  // if (filterRole !== 'all') {
  //   inputData = inputData.filter((user) => user.role === filterRole);
  // }

  return inputData;
}
