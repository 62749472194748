import {
  STATUS_CODE_FORBIDDEN,
  STATUS_CODE_INTERNAL_SERVER_ERROR,
} from 'src/constants';
import { PATH_PAGE } from 'src/routes/paths';

export const allowTo = (roles: string[], allowed: string[]) => {
  if (roles.some((role: string) => allowed.includes(role))) {
    return true;
  }
  return false;
};

export const permissionUrlHandler = (statusCode: number) => {
  switch (statusCode) {
    case STATUS_CODE_FORBIDDEN:
      window.location.pathname = PATH_PAGE.page403;
      return;
    case STATUS_CODE_INTERNAL_SERVER_ERROR:
      window.location.pathname = PATH_PAGE.page500;
      return;
    default:
      return;
  }
};
