export function savePageToStorage(entity: string, page: number): void {
  localStorage.setItem(entity + 'Page', page.toString());
}

export function getPageFromStorage(entity: string): number | null {
  const pageFromStorage = localStorage.getItem(entity + 'Page');
  if (pageFromStorage !== null) {
    return Number(pageFromStorage);
  }
  return null;
}

export function removePageFromStorage(entity: string): void {
  localStorage.removeItem(entity + 'Page');
}

export function removeAllPagesFromStorage(): void {
  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    if (key.includes('Page')) {
      localStorage.removeItem(key);
    }
  });
}
