import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
// sections
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable
} from '../../../components/table';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { IItemCategory } from 'src/@types/itemCategory';
import { getItemCategories, removeItemCategory } from 'src/redux/slices/itemCategory';
import { ItemCategoryTableRow } from 'src/sections/@dashboard/itemCategory/list';
import { useLocales } from '../../../locales';
import ItemCategoryTableToolbar from 'src/sections/@dashboard/itemCategory/list/itemCategoryToolbar';
import useDeleteRowsHandler from 'src/hooks/useDeleteHandler';
import ItemCategoryCreateForm from 'src/sections/@dashboard/itemCategory/create/ItemCategoryCreateForm';
import { getPageFromStorage, savePageToStorage } from 'src/utils/pageStorageHandler';
import { getRowsPerPageFromStorage, saveRowsPerPageToStorage } from 'src/utils/rowsPerPageStorageHandler';

type FormValuesProps = {
  itemCategory?: IItemCategory;
};

export default function ItemCategoryListPage({
  itemCategory,
}: FormValuesProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { deleteHandlers } = useDeleteRowsHandler();
  const [tableData, setTableData] = useState<IItemCategory[]>([]);
  const { itemCategories, isLoading } = useSelector(
    (state: any) => state.itemCategory
  );
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const { translate } = useLocales();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getItemCategories());
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (itemCategories.length || itemCategories.length === 0) {
      const filteredItemCategories = itemCategory
        ? itemCategories.filter(
            (itemCat: IItemCategory) =>
              itemCat?.itemCategoryParent?.id === itemCategory.id
          )
        : itemCategories;

      setTableData(filteredItemCategories);
    }
  }, [itemCategories, itemCategory]);

  const dataFiltered = applyFilter({
    inputData: itemCategories,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 52 : 72;

  const isFiltered =
    filterName !== '' || filterRole !== 'all' || filterStatus !== 'all';

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const tableHead = [
    { id: 'name', label: `${translate('name')}`, align: 'left' },
    !itemCategory
      ? {
          id: 'parentCategory',
          label: `${translate('parentCategory')}`,
          align: 'left',
        }
      : null,
    { id: 'actions', label: `${translate('actions')}`, align: 'right' },
  ].filter((item) => item !== null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await dispatch(removeItemCategory(Number(id)));
      await dispatch(getItemCategories());
      const deleteRow = tableData.filter(
        (row) => Number(row.id) !== Number(id)
      );
      setSelected([]);
      setTableData(deleteRow);

      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    try {
      if (await deleteHandlers.deleteRows(selectedRows, removeItemCategory)) {
        return;
      }

      const filteredRows = tableData.filter(
        (row: any) => !selectedRows.includes(row.id.toString())
      );
      setSelected([]);
      setTableData(filteredRows);

      if (page > 0) {
        if (selectedRows.length === dataInPage.length) {
          setPage(page - 1);
        } else if (selectedRows.length === dataFiltered.length) {
          setPage(0);
        } else if (selectedRows.length > dataInPage.length) {
          const newPage =
            Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
            1;
          setPage(newPage);
        }
      }

      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleEditRow = (id: string) => {
    savePageToStorage('itemCategory', page);
    saveRowsPerPageToStorage('itemCategory', rowsPerPage);
    navigate(PATH_DASHBOARD.itemCategory.view(paramCase(id)));
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterRole('all');
    setFilterStatus('all');
  };

  useEffect(() => {
    const savedPage = getPageFromStorage('itemCategory');
    setPage(savedPage ?? 0);
    const savedRowsPerPage = getRowsPerPageFromStorage('itemCategory');
    setRowsPerPage(savedRowsPerPage ?? 5);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.configuration.title')}`}</title>
      </Helmet>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {!itemCategory && <ItemCategoryCreateForm />}

          <Stack
            direction="row"
            alignItems="end"
            justifyContent="flex-end"
            mb={5}
          ></Stack>
          <Card>
            <Divider />

            <ItemCategoryTableToolbar
              isFiltered={isFiltered}
              filterName={filterName}
              onFilterName={handleFilterName}
              onResetFilter={handleResetFilter}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id.toString())
                  )
                }
                action={
                  <Tooltip title={`${translate('delete')}`}>
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHead}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id.toString())
                      )
                    }
                  />

                  <TableBody>
                    <>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <ItemCategoryTableRow
                            key={row.id}
                            row={row}
                            selected={selected.includes(row.id.toString())}
                            onSelectRow={() => onSelectRow(row.id.toString())}
                            onDeleteRow={() =>
                              handleDeleteRow(row.id.toString())
                            }
                            onEditRow={() => handleEditRow(row.id.toString())}
                            isItemCategoryEditView={!!itemCategory}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          tableData.length
                        )}
                      />
                      <TableNoData isNotFound={isNotFound} />
                    </>
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {selected.length > 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong>{' '}
                {selected.length > 4
                  ? translate('item_five_more')
                  : translate('item_two_four')}
                ?
              </>
            )}
            {selected.length === 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong> {translate('item_one')}?
              </>
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  inputData: IItemCategory[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (itemCategory) =>
        itemCategory.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  // if (filterStatus !== 'all') {
  //   inputData = inputData.filter(user => user.name === filterStatus)
  // }

  // if (filterRole !== 'all') {
  //   inputData = inputData.filter(user => user.name === filterRole)
  // }

  return inputData;
}
