import { ICustomer } from './customer';
import { IItem } from './item';
import { IProperty } from './property';
import { IRoom } from './room';
import { IUserLight, IUserOriginal } from './user';

type IDefaultReminder = {
  id: string;
  name: string;
  description: string;
  time: string;
  room?: IRoom;
  item: IItem;
  property?: IProperty;
  author: IUserOriginal;
  customer: ICustomer;
  status: string;
  createdAt: string;
  updatedAt: string;
  type: ReminderType;
};

export enum ReminderType {
  QUARTERLY = 'ŠTVRŤROČNE',
  SEMIANNUAL = 'POLROČNE',
  ANNUAL = 'ROČNE',
}

export interface IReminder extends IDefaultReminder {
  assignee: IUserOriginal;
}

export interface IReminderLight extends IDefaultReminder {
  assignee: IUserLight;
}

export type IReminderState = {
  isLoading: boolean;
  error: Error | string | null;
  reminders: IReminder[];
  reminder: IReminder | null;
  redirect: boolean | null;
};
