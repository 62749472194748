import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Box, Card, Container, Grid, Tab, Tabs } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { dispatch } from 'src/redux/store';
import RoomNewEditForm from 'src/sections/@dashboard/room/RoomNewEditForm';
import Iconify from 'src/components/iconify';
import ItemListPage from '../item/ItemListPage';
import { getRedirectForRoom, getRoom } from 'src/redux/slices/room';
import TaskListPage from '../task/TaskListPage';
import { useLocales } from 'src/locales';
import QrCodePage from '../QrCodePage';
import { ALL_ROLES } from 'src/constants';

// ----------------------------------------------------------------------

export default function RoomEditPage() {
  const { translate } = useLocales();
  const { propertyId, roomId } = useParams();

  const { room, redirect } = useSelector((state: any) => state.room);
  const location = useLocation();

  const [currentTab, setCurrentTab] = useState<string>(
    `property/${propertyId}/room/${roomId}/general`
  );

  useEffect(() => {
    if (roomId) {
      const fetchData = async () => {
        Promise.all([
          dispatch(getRoom(roomId ? Number(roomId) : 1)),
          dispatch(getRedirectForRoom(roomId ? Number(roomId) : 1)),
        ]);
      };
      fetchData();
    }
  }, [dispatch, roomId]);

  useEffect(() => {
    const newPathname = `/dashboard/${currentTab}`;
    window.history.pushState(null, '', newPathname);
  }, [currentTab]);

  useEffect(() => {
    const tabFromPathname = location.pathname.replace('/dashboard/', '');
    setCurrentTab(tabFromPathname);
  }, [location.pathname]);

  const TABS = [
    {
      value: `property/${propertyId}/room/${roomId}/general`,
      label: `${translate('general')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <RoomNewEditForm room={room} propertyId={propertyId} roomId={roomId} />
      ),
    },
    {
      value: `property/${propertyId}/room/${roomId}/tasks`,
      label: `${translate('tasks')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: <TaskListPage propertyId={propertyId} roomId={roomId} />,
    },
    {
      value: `property/${propertyId}/room/${roomId}/items`,
      label: `${translate('items')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: <ItemListPage propertyId={propertyId} roomId={roomId} />,
    },
    {
      value: `property/${propertyId}/room/${roomId}/qr-code`,
      label: `${translate('pages.qrCode.heading')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <QrCodePage
          uuid={redirect?.uuid}
          uri={room ? room.name : `unknown-room`}
        />
      ),
      roles: ALL_ROLES,
    },
  ];

  const content = (
    <>
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => setCurrentTab(newValue)}
      >
        {TABS.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>

      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box key={tab.value} sx={{ mt: 5 }}>
              {tab.component}
            </Box>
          )
      )}
    </>
  );

  const renderContent = () => {
    if (currentTab === TABS[0].value) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              {content}
            </Card>
          </Grid>
        </Grid>
      );
    }
    return (
      <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
        {content}
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.property.titleEditRoom')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.room.editRoom')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('room')}`,
              href: PATH_DASHBOARD.property.rooms(roomId?.toString() || '1'),
            },
            { name: room?.name ?? '' },
          ]}
        />
        {renderContent()}
      </Container>
    </>
  );
}
