import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
// @mui
import { Card, Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { getItemTag } from 'src/redux/slices/itemTag';
import ItemTagNewEditForm from 'src/sections/@dashboard/itemTag/ItemTagNewEditForm';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ItemTagEditPage() {
  const { translate } = useLocales();
  const { id } = useParams();

  const { itemTag } = useSelector((state: any) => state.itemTag);

  useEffect(() => {
    dispatch(getItemTag(id ? Number(id) : 1));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.configuration.titleEditItemTag')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.configuration.editItemTag')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('configuration')}`,
              href: PATH_DASHBOARD.configuration.itemTagList,
            },
            { name: itemTag?.name ?? '' },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 0 32px 0', marginBottom: '40px' }}>
              <ItemTagNewEditForm itemTag={itemTag} />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
