import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { getProperties } from '../../../redux/slices/property';
import { getCustomers } from 'src/redux/slices/customer';
import { getItem, updateItem } from 'src/redux/slices/item';
import { getItemCategories } from '../../../redux/slices/itemCategory';
import { useNavigate } from 'react-router-dom';
import { getPropertyRooms, getRooms } from 'src/redux/slices/room';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../locales';
import { getItemTags } from 'src/redux/slices/itemTag';
import { ADMIN_MANAGERS_ROLES, PROPERTY, ROOM } from 'src/constants';
import { allowTo } from 'src/utils/permissionHandler';
import { IItemTag } from 'src/@types/itemTag';
import { IItemCategory } from 'src/@types/itemCategory';
import { IRoom } from 'src/@types/room';
import { IProperty } from 'src/@types/property';

// ----------------------------------------------------------------------

type FormValuesProps = {
  id: string | null;
  name: string;
  description: string;
  roomId: number | string;
  propertyId: number | string;
  itemTagId: number | string;
  itemCategoryId: number | string;
};

type Props = {
  id: string | number | undefined;
  fromUrl: string;
};

export default function AccountGeneral({ id, fromUrl }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { item } = useSelector((state) => state.item);
  const { properties } = useSelector((state) => state.property);
  const { itemCategories } = useSelector((state) => state.itemCategory);
  const { itemTags } = useSelector((state) => state.itemTag);
  const { rooms } = useSelector((state) => state.room);
  const [editing, setEditing] = useState<boolean>(false);
  const { user } = useAuthContext();

  const defaultValues = {
    id: item?.id || null,
    name: item?.name || '',
    description: item?.description || '',
    roomId: item?.room?.id || '',
    propertyId: item?.property?.id || '',
    itemTagId: item?.itemTag?.id ?? '',
    itemCategoryId: item?.itemCategory?.id || '',
  };

  const updateItemSchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    description: Yup.string().required(`${translate('isRequired.name')}`),
    roomId: Yup.number().required(`${translate('isRequired.room')}`),
    propertyId: Yup.number().required(`${translate('isRequired.property')}`),
    itemTagId: Yup.string(),
    itemCategoryId: Yup.number().required(
      `${translate('isRequired.itemCategory')}`
    ),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(updateItemSchema),
    values: defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
    reset,
    setValue,
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(updateItem(Number(item?.id), data));
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      reset();
      handlerNavigate();
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  const handlerNavigate = () => {
    switch (fromUrl) {
      case PROPERTY:
        navigate(PATH_DASHBOARD.property.items(values.propertyId.toString()));
        break;
      case ROOM:
        navigate(
          PATH_DASHBOARD.room.items(
            values.roomId.toString(),
            values.propertyId.toString()
          )
        );
        break;
      default:
        navigate(PATH_DASHBOARD.general.items);
        break;
    }
  };

  const onChangeProperty = async (propertyId: string) => {
    setValue('propertyId', propertyId);
    if (propertyId !== '') await dispatch(getPropertyRooms(Number(propertyId)));
    else setValue('roomId', '');
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        // TODO: add getUsers
        await Promise.all([
          dispatch(getItem(Number(id))),
          dispatch(getCustomers()),
          dispatch(getProperties()),
          dispatch(getItemTags()),
          dispatch(getItemCategories()),
        ]);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      if (item?.property?.id) {
        await dispatch(getPropertyRooms(Number(item?.property?.id)));
      } else {
        await dispatch(getRooms());
      }
    };
    fetchData();
  }, [item]);

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 5 }}>
          <Button
            onClick={handlerNavigate}
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('name')}`}
              value={values.name}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />

            <RHFTextField
              name="description"
              label={`${translate('description')}`}
              value={values.description}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              multiline
              minRows={3}
              required
            />

            <RHFSelect
              native
              name="propertyId"
              label={`${translate('property')}`}
              placeholder={`${translate('property')}`}
              onChange={(e) => onChangeProperty(e.target.value)}
              value={values.propertyId}
              disabled={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            >
              {properties.map((property: IProperty) => (
                <option key={property.id} value={property.id}>
                  {property.name}
                </option>
              ))}
            </RHFSelect>

            <RHFSelect
              native
              name="roomId"
              label={`${translate('room')}`}
              placeholder={`${translate('room')}`}
              value={values.roomId}
              disabled={!editing || values.propertyId === ''}
              variant={!editing ? 'standard' : 'outlined'}
              required
            >
              <option key="" value="" />
              {rooms?.map((room: IRoom) => (
                <option key={room.id} value={room.id}>
                  {room.name}
                </option>
              ))}
            </RHFSelect>

            <RHFSelect
              native
              name="itemTagId"
              label={`${translate('itemTag')}`}
              placeholder={`${translate('itemTag')}`}
              value={values.itemTagId}
              disabled={!editing}
              variant={!editing ? 'standard' : 'outlined'}
            >
              <option key="" value="" />
              {itemTags.map((itemTag: IItemTag) => (
                <option key={itemTag.id} value={itemTag.id}>
                  {itemTag.name}
                </option>
              ))}
            </RHFSelect>

            <RHFSelect
              native
              name="itemCategoryId"
              label={`${translate('itemCategory')}`}
              placeholder={`${translate('itemCategory')}`}
              value={values.itemCategoryId}
              disabled={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            >
              <option key="" value="" />
              {itemCategories.map((itemCategory: IItemCategory) => (
                <option key={itemCategory.id} value={itemCategory.id}>
                  {itemCategory.name}
                </option>
              ))}
            </RHFSelect>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            {editing && (
              <>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {`${translate('saveChanges')}`}
                </LoadingButton>
                <Button
                  onClick={() => setEditing(false)}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  {`${translate('close')}`}
                </Button>
              </>
            )}
            {!editing &&
              allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
                <Button
                  onClick={() => setEditing(true)}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  {`${translate('edit')}`}
                </Button>
              )}
          </Box>
        </Card>
      </FormProvider>
    </Container>
  );
}
