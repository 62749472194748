import { Button } from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import QRCode from 'react-qr-code';
import { useLocales } from 'src/locales';
import { Canvg } from 'canvg';

type QrCodePageProps = {
  uuid?: string;
  uri: string;
};

export default function QrCodePage({ uuid, uri }: QrCodePageProps) {
  const { translate } = useLocales();

  const feUrl = window.location.origin;

  const [pngDataUrl, setPngDataUrl] = useState('');

  useEffect(() => {
    const svgElement = document.querySelector('#qrWrapper svg') as HTMLElement;
    if (svgElement) {
      const xmlSerializer = new XMLSerializer();
      const svgString = xmlSerializer.serializeToString(svgElement);

      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(svgString, 'image/svg+xml');

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        return;
      }
      const a = new Canvg(ctx, svgDocument);
      a.render();
      const dataUrl = canvas.toDataURL('image/png');
      setPngDataUrl(dataUrl);
    }
  }, [uuid]);

  const handleDownload = () => {
    if (pngDataUrl) {
      const downloadLink = document.createElement('a');
      downloadLink.href = pngDataUrl;
      downloadLink.download = `${uri}-QR.png`;
      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const renderSuccess = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <div id="qrWrapper">
          <QRCode value={`${feUrl}/redirect-to/${uuid}`} />
        </div>
        <Button
          onClick={handleDownload}
          sx={{ mb: 2, mt: 2 }}
          type="button"
          variant="contained"
        >
          {`${translate('pages.qrCode.download')}`}
        </Button>
        <Button
          onClick={handlePrint}
          sx={{ mb: 2 }}
          type="button"
          variant="contained"
        >
          {`${translate('pages.qrCode.print')}`}
        </Button>
        {process.env.NODE_ENV === 'development' && (
          <Button
            type="button"
            variant="contained"
            onClick={() => {
              window.location.href = `${feUrl}/redirect-to/${uuid}`;
            }}
          >
            TEST ME (only dev)
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.qrCode.title')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {uuid && renderSuccess()}
          {!uuid && <>{`${translate('somethingWentWrong')}`}</>}
        </Box>
      </Container>
      <style>
        {`@media print {
          body * {
            visibility: hidden;
          }
          #qrWrapper,
          #qrWrapper * {
            visibility: visible;
            margin:0 auto;
          }
          #qrWrapper {
            position: absolute;
            left: 0;
            top: 0;
          }
          @page {
            size: auto; /* Choose the paper size */
            margin: 0; /* Set margins to zero */
          }

          /* Define a blank header and footer */
          @top-center {
            content: "";
          }
          @bottom-center {
            content: "";
          }
        }`}
      </style>
    </>
  );
}
