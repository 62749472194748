import * as Yup from 'yup';
import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getCustomers } from '../../../../redux/slices/customer';
import { getUsersForReminders } from 'src/redux/slices/user';
import { useNavigate } from 'react-router-dom';
import { createReminder } from 'src/redux/slices/reminder';
import { DateTimePicker } from '@mui/x-date-pickers';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../../locales';
import { useAuthContext } from 'src/auth/useAuthContext';
import { CUSTOMER, PROPERTY } from 'src/constants';
import { useActiveSelectReminder } from 'src/hooks/useActiveSelectReminder';
import { getItems } from 'src/redux/slices/item';
import { getProperties } from 'src/redux/slices/property';
import { ReminderType } from 'src/@types/reminder';

// ----------------------------------------------------------------------

type FormValuesProps = {
  propertyId: number | string;
  customerId: number | string;
  assigneeId: number | string;
  itemId: number | string;
  roomId: number | string;
  name: string;
  description: string;
  time: string;
  type: string;
};

type ReminderCreateFormProps = {
  itemId?: string;
};

export const ReminderCreateForm: FC<ReminderCreateFormProps> = ({ itemId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const defaultValues = {
    name: '',
    description: '',
    customerId: user?.customerId ? user?.customerId : '',
    propertyId: '',
    assigneeId: '',
    itemId: itemId ? itemId : '',
    roomId: '',
    time: new Date().toISOString(),
    type: '',
  };
  const { customers } = useSelector((state) => state.customer);
  const { properties } = useSelector((state) => state.property);
  const { users } = useSelector((state) => state.user);
  const { items } = useSelector((state) => state.item);
  const { onChangeHandler } = useActiveSelectReminder();

  const UpdatePropertySchema = Yup.object().shape({
    description: Yup.string().required(
      `${translate('isRequired.description')}`
    ),
    name: Yup.string().required(`${translate('isRequired.name')}`),
    customerId: Yup.number()
      .typeError(`${translate('isRequired.customerId')}`)
      .required(`${translate('isRequired.customerId')}`),
    propertyId: Yup.number()
      .typeError(`${translate('isRequired.property')}`)
      .required(`${translate('isRequired.property')}`),
    assigneeId: Yup.number()
      .typeError(`${translate('isRequired.assigneeName')}`)
      .required(`${translate('isRequired.assigneeName')}`),
    time: Yup.string().required('Time is required'),
    itemId: itemId
      ? Yup.number()
          .typeError(`${translate('isRequired.item')}`)
          .required(`${translate('isRequired.item')}`)
      : Yup.number().typeError(`${translate('isRequired.item')}`),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(createReminder(data));
      reset();
      navigate(
        itemId
          ? PATH_DASHBOARD.item.reminders(itemId.toString())
          : PATH_DASHBOARD.general.reminder
      );
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getCustomers()),
        dispatch(getUsersForReminders()),
        dispatch(getProperties()),
      ]);
      if (itemId) {
        dispatch(getItems());
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (itemId) {
      const foundItem = items.find(
        (item) => Number(item.id) === Number(itemId)
      );
      setValue('propertyId', foundItem?.property?.id ?? '');
      setValue('customerId', foundItem?.property?.customerId ?? '');
    }
  }, [properties, customers, items, itemId]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
        <Button
          onClick={() =>
            navigate(
              itemId
                ? PATH_DASHBOARD.item.reminders(itemId.toString())
                : PATH_DASHBOARD.general.reminder
            )
          }
          sx={{ float: 'right' }}
          type="button"
          variant="contained"
        >
          {`${translate('back')}`}
        </Button>
      </Stack>
      <Card sx={{ p: 3 }}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField
            name="name"
            label={`${translate('name')}`}
            value={values.name}
            required
          />
          <RHFTextField
            name="description"
            label={`${translate('description')}`}
            value={values.description}
            required
          />
          <RHFSelect
            native
            name="customerId"
            label={`${translate('customer')}`}
            placeholder={`${translate('customer')}`}
            value={values.customerId}
            disabled={itemId || defaultValues.customerId ? true : false}
            onChange={(e: any) =>
              onChangeHandler(CUSTOMER, e.target.value, setValue, setError)
            }
            required
          >
            <option key={''} value=""></option>
            {customers.map((customer) => (
              <option key={customer.id} value={customer.id}>
                {customer.name}
              </option>
            ))}
          </RHFSelect>
          <RHFSelect
            native
            name="propertyId"
            label={`${translate('property')}`}
            placeholder={`${translate('property')}`}
            value={values.propertyId}
            disabled={itemId || values.customerId === '' ? true : false}
            readOnly={values.customerId === '' ? true : false}
            onChange={(e: any) =>
              onChangeHandler(PROPERTY, e.target.value, setValue, setError)
            }
            error={
              values.customerId !== '' && properties.length === 0 ? true : false
            }
            required
          >
            <option key={''} value=""></option>
            {properties.map((property) => (
              <option key={property.id} value={property.id}>
                {property.name}
              </option>
            ))}
          </RHFSelect>
          <RHFSelect
            native
            name="assigneeId"
            label={`${translate('assigneeName')}`}
            placeholder={`${translate('assigneeName')}`}
            value={values.assigneeId}
            required
          >
            <option key={''} value=""></option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.firstName} {user.lastName}
              </option>
            ))}
          </RHFSelect>
          <RHFSelect
            native
            name="itemId"
            label={`${translate('item')}`}
            placeholder={`${translate('item')}`}
            value={values.itemId}
            disabled={itemId || values.propertyId === '' ? true : false}
            readOnly={values.propertyId === '' ? true : false}
            error={
              values.customerId !== '' && items.length === 0 ? true : false
            }
            required
          >
            <option key={''} value=""></option>
            {items.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </RHFSelect>
          <DateTimePicker
            renderInput={(props) => <TextField {...props} fullWidth />}
            label={`${translate('time')}`}
            value={values.time}
            onChange={(time: any) => setValue('time', time)}
          />
          <RHFSelect
            native
            name="type"
            label={`${translate('pages.reminder.repeating')}`}
            placeholder={`${translate('pages.reminder.repeating')}`}
            value={values.type}
          >
            <option key={''} value=""></option>
            {Object.entries(ReminderType).map((reminderType) => (
              <option key={reminderType[0]} value={reminderType[1]}>
                {reminderType[1].charAt(0).toUpperCase() +
                  reminderType[1].slice(1).toLowerCase()}
              </option>
            ))}
          </RHFSelect>
        </Box>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('pages.reminder.createReminder')}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
};
