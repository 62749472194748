import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { IUserOriginal } from '../../../@types/user';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import {
  ADMIN_MANAGERS_ROLES,
  COULT_NOT_EDIT,
  CREATED,
  IN_PROGRESS,
  NO_CUSTOMER_ROLES,
  ROLES_PERMISSIONS,
  ROOM,
  SERVICE_REQUEST,
  SUCCESS_EDIT
} from 'src/constants';
import { dispatch, useSelector } from 'src/redux/store';
import { getProperties } from 'src/redux/slices/property';
import { getUsers } from 'src/redux/slices/user';
import { ITask } from 'src/@types/task';
import { updateTask, updateToCompleteTask, updateToStartTask } from 'src/redux/slices/task';
import { getPropertyServiceRequests } from 'src/redux/slices/serviceRequest';
import { getPropertyRooms } from 'src/redux/slices/room';
import { useLocales } from '../../../locales';
import { useAuthContext } from 'src/auth/useAuthContext';
import { allowTo } from 'src/utils/permissionHandler';

// ----------------------------------------------------------------------

type FormValuesProps = {
  assigneeId: number | string;
  title: string;
  description: string;
  serviceRequestId: number | string;
  propertyId: number | string;
  roomId: number | string;
};
type Props = {
  isEdit?: boolean;
  task?: ITask;
  fromUrl?: string;
};

export default function TaskNewEditForm({
  task,
  fromUrl,
}: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { user } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const { serviceRequests } = useSelector((state) => state.serviceRequest);
  const [editing, setEditing] = useState<boolean>(true);
  const { users } = useSelector((state) => state.user);
  const { properties } = useSelector((state) => state.property);
  const { rooms } = useSelector((state) => state.room);
  const [operators, setOperators] = useState<IUserOriginal[]>([]);
  const [isDisable, setDisable] = useState<boolean>(false);

  const UpdatePropertySchema = Yup.object().shape({
    description: Yup.string().required(
      `${translate('isRequired.description')}`
    ),
    title: Yup.string().required(`${translate('isRequired.name')}`),
    assigneeId: Yup.number()
      .typeError(`${translate('isRequired.assigneeName')}`)
      .required(`${translate('isRequired.assigneeName')}`),
  });

  const defaultValues = {
    title: task?.title || '',
    description: task?.description || '',
    assigneeId: Number(task?.assignee?.id) || '',
    serviceRequestId: Number(task?.serviceRequestId) || '',
    propertyId: Number(task?.property?.id) || '',
    roomId: Number(task?.roomId) || '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(updateTask(Number(task?.id), data));
      reset();
      enqueueSnackbar(SUCCESS_EDIT, { variant: 'success' });
      handlerNavigate();
    } catch (error) {
      enqueueSnackbar(COULT_NOT_EDIT, { variant: 'error' });
    }
  };

  const updateStatusToStart = async () => {
    try {
      await dispatch(updateToStartTask(Number(task?.id)));
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      handlerNavigate();
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  const updateStatusToComplete = async () => {
    try {
      await dispatch(updateToCompleteTask(Number(task?.id)));
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      navigate(PATH_DASHBOARD.general.task);
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  const handlerNavigate = () => {
    switch (fromUrl) {
      case SERVICE_REQUEST:
        navigate(-1);
        break;
      case ROOM:
        navigate(
          PATH_DASHBOARD.room.tasks(
            values.roomId.toString(),
            values.propertyId.toString()
          )
        );
        break;
      default:
        navigate(PATH_DASHBOARD.general.task);
        break;
    }
  };

  const onChangeProperty = async (propertyId: string) => {
    setValue('propertyId', propertyId);
    if (propertyId !== '') {
      await dispatch(getPropertyRooms(Number(propertyId)));
      await dispatch(getPropertyServiceRequests(Number(propertyId)));
    } else {
      setValue('roomId', '');
      setValue('serviceRequestId', '');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([dispatch(getUsers()), dispatch(getProperties())]);
      if (task?.property?.id) {
        await Promise.all([
          dispatch(getPropertyRooms(Number(task?.property?.id))),
          dispatch(getPropertyServiceRequests(Number(task?.property?.id))),
        ]);
      }
    };
    fetchData();
  }, [task]);

  useEffect(() => {
    const _operators = users.filter(
      (user: IUserOriginal) =>
        user.roles.includes(ROLES_PERMISSIONS.externalOperator) ||
        user.roles.includes(ROLES_PERMISSIONS.internalOperator)
    );
    setOperators(_operators);
  }, [users]);

  useEffect(() => {
    const _isServiceRequest = fromUrl === SERVICE_REQUEST;
    setDisable(_isServiceRequest);
  }, [fromUrl]);

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
          <Button
            onClick={handlerNavigate}
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          {allowTo(user ? user.roles : [], NO_CUSTOMER_ROLES) && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                margin: 2,
              }}
            >
              {task?.status === CREATED && (
                <Button onClick={updateStatusToStart} variant="contained">
                  {`${translate('start')}`}
                </Button>
              )}
              {task?.status === IN_PROGRESS && (
                <Button onClick={updateStatusToComplete} variant="contained">
                  {`${translate('complete')}`}
                </Button>
              )}
            </Box>
          )}
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="title"
              label={`${translate('name')}`}
              id="title"
              value={values.title}
              readOnly={editing}
              variant={editing ? 'standard' : 'outlined'}
              required
            />
            <RHFTextField
              name="description"
              label={`${translate('description')}`}
              id="description"
              value={values.description}
              readOnly={editing}
              variant={editing ? 'standard' : 'outlined'}
              required
            />
            <RHFSelect
              native
              name="assigneeId"
              label={`${translate('assigneeName')}`}
              placeholder={`${translate('assigneeName')}`}
              value={values.assigneeId}
              readOnly={editing}
              disabled={editing}
              variant={editing ? 'standard' : 'outlined'}
              required
            >
              {users.length === 0 && <option value="">No user</option>}
              {values.assigneeId === '' && <option value=""></option>}
              {operators.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect
              native
              name="propertyId"
              label={`${translate('property')}`}
              placeholder={`${translate('property')}`}
              onChange={(e) => onChangeProperty(e.target.value)}
              value={values.propertyId}
              readOnly={isDisable ? true : editing}
              disabled={isDisable ? true : editing}
              variant={editing ? 'standard' : 'outlined'}
            >
              {properties.length === 0 && (
                <option value="">{`${translate(
                  'pages.property.noProperty'
                )}`}</option>
              )}
              {values.propertyId === '' && <option value=""></option>}
              {properties.map((property) => (
                <option key={property.id} value={property.id}>
                  {property.name}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect
              native
              name="serviceRequestId"
              label={`${translate('serviceRequest')}`}
              placeholder={`${translate('serviceRequest')}`}
              value={values.serviceRequestId}
              readOnly={isDisable ? true : editing}
              disabled={isDisable ? true : editing}
              variant={editing ? 'standard' : 'outlined'}
            >
              {<option value=""></option>}
              {serviceRequests.map((serviceRequest) => (
                <option key={serviceRequest.id} value={serviceRequest.id}>
                  {serviceRequest.name}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect
              native
              name="roomId"
              label={`${translate('room')}`}
              placeholder={`${translate('room')}`}
              value={values.roomId}
              readOnly={editing}
              disabled={editing || values.propertyId === ''}
              variant={editing ? 'standard' : 'outlined'}
            >
              <option key={''} value=""></option>
              {rooms?.map((room) => (
                <option key={room.id} value={room.id}>
                  {room.name}
                </option>
              ))}
            </RHFSelect>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            {allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) &&
              !editing && (
                <>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    sx={{ mr: 1 }}
                    loading={isSubmitting}
                  >
                    {`${translate('saveChanges')}`}
                  </LoadingButton>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={() => setEditing(true)}
                  >
                    {`${translate('close')}`}
                  </Button>
                </>
              )}
            {allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) &&
              editing && (
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => setEditing(false)}
                >
                  {`${translate('edit')}`}
                </Button>
              )}
          </Box>
        </Card>
      </FormProvider>
    </Container>
  );
}
