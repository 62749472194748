import React, { useState } from 'react';
// @mui
import { Button, Checkbox, TableCell, TableRow, Tooltip } from '@mui/material';
// components
import ConfirmDialog from '../../../../components/confirm-dialog';
import { useLocales } from 'src/locales';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IRequestComment } from 'src/@types/requestComment';
import { convertDateTime } from 'src/utils/convertDateTime';
import { useAuthContext } from 'src/auth/useAuthContext';
import { ROLES_PERMISSIONS } from 'src/constants';

// ----------------------------------------------------------------------

type Props = {
  row: IRequestComment;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function RequestCommentTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { content, author, createdAt } = row;

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { translate } = useLocales();
  const { user } = useAuthContext();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const canEditOrDelete = () => {
    if (user?.roles.includes(ROLES_PERMISSIONS.admin)) return true;
    return author.id === user?.id;
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>
        <TableCell align="left" sx={{ wordWrap: 'break-word', maxWidth: 600 }}>
          {content}
        </TableCell>

        <TableCell align="left">
          {author.firstName} {author.lastName}
        </TableCell>

        <TableCell align="left">{convertDateTime(createdAt)}</TableCell>

        <TableCell align="right">
          {canEditOrDelete() && (
            <>
              <Tooltip
                title={`${translate('edit')}`}
                sx={{ cursor: 'pointer' }}
              >
                <EditIcon
                  onClick={() => {
                    onEditRow();
                    handleClosePopover();
                  }}
                />
              </Tooltip>
              <Tooltip
                title={`${translate('delete')}`}
                sx={{ cursor: 'pointer' }}
              >
                <DeleteIcon
                  onClick={() => {
                    handleOpenConfirm();
                    handleClosePopover();
                  }}
                />
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('infoMessages.deletePromptV2')}`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
