import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify';
import { useLocation, useParams } from 'react-router-dom';
import { useLocales } from 'src/locales';
import { useDispatch, useSelector } from 'src/redux/store';
import { useAuthContext } from 'src/auth/useAuthContext';
import { ADMIN_MANAGERS_ROLES, ATTACHMENT } from 'src/constants';
import { getReminder } from 'src/redux/slices/reminder';
import { Box, Card, Container, Grid, Tab, Tabs } from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { urlHandler } from 'src/utils/navigateHandler';
import { setLastUrlReminder } from 'src/redux/slices/setting';
import AttachmentListPage from '../attachment/AttachmentListPage';
import ReminderNewEditForm from 'src/sections/@dashboard/reminder/ReminderNewEditForm';
import useFlushNavigate from 'src/hooks/useFlushNavigate';

const ReminderDetailPage = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const location = useLocation();
  const { reminder } = useSelector((state) => state.reminder);
  const { lastUrlReminder } = useSelector((state: any) => state.setting);
  const [currentTab, setCurrentTab] = useState(`reminder/${id}/general`);
  const { flushRedux } = useFlushNavigate();

  const tabHandler = (tab: string) => {
    flushRedux();
    setCurrentTab(tab);
  };

  const TABS = [
    {
      value: `reminder/${id}/general`,
      label: `${translate('general')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <ReminderNewEditForm reminder={reminder} fromUrl={lastUrlReminder} />
      ),
      roles: ADMIN_MANAGERS_ROLES,
    },
    {
      value: `reminder/${id}/attachments`,
      label: `${translate('attachments')}`,
      icon: <Iconify icon="ic:round-receipt" />,
      component: (
        <AttachmentListPage
          reminderId={id}
          fromUrl={lastUrlReminder}
          itemId={reminder?.item.id}
        />
      ),
      roles: ADMIN_MANAGERS_ROLES,
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getReminder(Number(id) as number));
    }
  }, [dispatch, id, currentTab]);

  useEffect(() => {
    const newPathname = `/dashboard/${currentTab}`;
    window.history.pushState(null, '', newPathname);
  }, [currentTab]);

  useEffect(() => {
    const tabFromPathname = location.pathname.replace('/dashboard/', '');
    const url = urlHandler(ATTACHMENT, tabFromPathname);
    if (url.length > 0) dispatch(setLastUrlReminder(url));
    tabHandler(tabFromPathname);
  }, [location.pathname]);

  const content = (
    <>
      <Tabs
        value={currentTab}
        onChange={(event: any, newValue: any) => tabHandler(newValue)}
      >
        {TABS.map((tab) => {
          if (tab.roles.length === 0) {
            return (
              <Tab
                key={tab.value}
                label={tab.label}
                icon={tab.icon}
                value={tab.value}
              />
            );
          }
          const hasPermission = tab.roles.some((role) =>
            user?.roles.includes(role)
          );

          if (hasPermission) {
            return (
              <Tab
                key={tab.value}
                label={tab.label}
                icon={tab.icon}
                value={tab.value}
              />
            );
          }
          return null;
        })}
      </Tabs>

      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box key={tab.value} sx={{ mt: 5 }}>
              {tab.component}
            </Box>
          )
      )}
    </>
  );

  const renderContent = () => {
    if (currentTab === TABS[0].value) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              {content}
            </Card>
          </Grid>
        </Grid>
      );
    }
    return (
      <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
        {content}
      </Card>
    );
  };

  return (
    <>
      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.reminder.editReminder')}`}
          links={[
            { name: `${translate('dashboard')}`, href: PATH_DASHBOARD.root },
            {
              name: `${translate('reminders')}`,
              href: PATH_DASHBOARD.general.reminder,
            },
            { name: reminder?.name ?? '' },
          ]}
        />
        {renderContent()}
      </Container>
    </>
  );
};

export default ReminderDetailPage;
