export function saveRowsPerPageToStorage(
  entity: string,
  rowsPerPage: number
): void {
  localStorage.setItem(entity + 'Rows', rowsPerPage.toString());
}

export function getRowsPerPageFromStorage(entity: string): number | null {
  const rowsPerPageFromStorage = localStorage.getItem(entity + 'Rows');
  if (rowsPerPageFromStorage !== null) {
    return Number(rowsPerPageFromStorage);
  }
  return null;
}

export function removeRowsPerPageFromStorage(entity: string): void {
  localStorage.removeItem(entity + 'Rows');
}

export function removeAllRowsPerPageFromStorage(): void {
  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    if (key.includes('Rows')) {
      localStorage.removeItem(key);
    }
  });
}
