import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import productReducer from './slices/product';
import customerReducer from './slices/customer';
import userReducer from './slices/user';
import propertyReducer from './slices/property';
import itemReducer from './slices/item';
import itemLogReducer from './slices/itemLog';
import itemTagReducer from './slices/itemTag';
import itemCategoryReducer from './slices/itemCategory';
import serviceRequestReducer from './slices/serviceRequest';
import requestCommentReducer from './slices/requestComment';
import reminderReducer from './slices/reminder';
import taskReducer from './slices/task';
import roomReducer from './slices/room';
import attachmentReducer from './slices/attachment';
import settingReducer from './slices/setting';
import notificationReducer from './slices/notification';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const customerPersistConfig = {
  key: 'customer',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const propertyPersistConfig = {
  key: 'property',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const itemPersistConfig = {
  key: 'item',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const itemLogPersistConfig = {
  key: 'itemLog',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const itemTagPersistConfig = {
  key: 'itemTag',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const itemCategoryPersistConfig = {
  key: 'itemCategory',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

export const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const serviceRequestPersistConfig = {
  key: 'serviceRequest',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const requestCommentPersistConfig = {
  key: 'requestComment',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const reminderPersistConfig = {
  key: 'reminder',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const taskPersistConfig = {
  key: 'task',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const roomPersistConfig = {
  key: 'room',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const attachmentPersistConfig = {
  key: 'attachment',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const notesPersistConfig = {
  key: 'notes',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const settingPersistConfig = {
  key: 'setting',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const notificationPersistConfig = {
  key: 'notification',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  product: persistReducer(productPersistConfig, productReducer),
  customer: persistReducer(customerPersistConfig, customerReducer),
  property: persistReducer(propertyPersistConfig, propertyReducer),
  item: persistReducer(itemPersistConfig, itemReducer),
  itemLog: persistReducer(itemLogPersistConfig, itemLogReducer),
  itemTag: persistReducer(itemTagPersistConfig, itemTagReducer),
  itemCategory: persistReducer(itemCategoryPersistConfig, itemCategoryReducer),
  user: persistReducer(userPersistConfig, userReducer),
  serviceRequest: persistReducer(
    serviceRequestPersistConfig,
    serviceRequestReducer
  ),
  requestComment: persistReducer(
    requestCommentPersistConfig,
    requestCommentReducer
  ),
  reminder: persistReducer(reminderPersistConfig, reminderReducer),
  task: persistReducer(taskPersistConfig, taskReducer),
  room: persistReducer(roomPersistConfig, roomReducer),
  attachment: persistReducer(attachmentPersistConfig, attachmentReducer),
  setting: persistReducer(settingPersistConfig, settingReducer),
  notification: persistReducer(notificationPersistConfig, notificationReducer),
});

export default rootReducer;
