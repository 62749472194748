import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axiosAPI';
import {
  IUserOriginal,
  IUserOriginalReset,
  IUserOriginalState,
} from '../../@types/user';

// ----------------------------------------------------------------------

const initialState: IUserOriginalState = {
  isLoading: true,
  error: null,
  users: [],
  user: null,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.users = action.payload;
      state.isLoading = false;
    },

    // GET USER
    getUserSuccess(state, action) {
      state.user = action.payload;
      state.isLoading = false;
    },

    // UPDATE USER
    updateUserSuccess(state, action) {
      state.user = action.payload;
      state.isLoading = false;
    },

    // CREATE USER
    createUserSuccess(state, action) {
      state.user = action.payload;
      state.isLoading = false;
    },

    // REMOVE USER
    removeUserSuccess(state, action) {
      state.isLoading = false;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.user = null;
      state.users = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
const mapUser = (user: any): IUserOriginal => {
  return {
    id: user?.id ? user.id : '',
    email: user?.email ? user.email : '',
    firstName: user?.firstName ? user.firstName : '',
    lastName: user?.lastName ? user.lastName : '',
    roles: user?.roles ? user.roles : [],
    customerId: user?.customerId,
  };
};

const mapUserAccount = (user: any) => {
  return {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    roles: user?.roles,
    password: user?.password,
    customerId: user?.customerId === '' ? null : user?.customerId,
  };
};

const mapPasswordReset = (user: IUserOriginalReset) => {
  return {
    newPassword: user?.password,
    confirmationPassword: user?.confirmationPassword,
  };
};

const mapProfileData = (user: IUserOriginalReset) => {
  return {
    firstName: user?.firstName,
    lastName: user?.lastName,
  };
};

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/users');

      const result: IUserOriginal[] = response?.data?.data?.map((user: any) =>
        mapUser(user)
      );
      dispatch(slice.actions.getUsersSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getUsersForReminders() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/users/get-for-reminders');

      const result: IUserOriginal[] = response?.data?.data?.map((user: any) =>
        mapUser(user)
      );
      dispatch(slice.actions.getUsersSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getPropertyManagers() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/properties/filter/managers');

      const result: IUserOriginal[] = response?.data?.data?.map((user: any) =>
        mapUser(user)
      );
      dispatch(slice.actions.getUsersSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getPropertyWithPropertyManagersRole() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/properties/filter/property-managers');

      const result: IUserOriginal[] = response?.data?.data?.map((user: any) =>
        mapUser(user)
      );
      dispatch(slice.actions.getUsersSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getUser(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/users/' + id, {
        params: { id },
      });

      const result: IUserOriginal = mapUser(response?.data?.data);

      dispatch(slice.actions.getUserSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getCustomerUsers(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`/customers/${id}/users`);

      const result: IUserOriginal[] = response?.data?.data?.map((user: any) =>
        mapUser(user)
      );

      dispatch(slice.actions.getUsersSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateUser(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch('/users/' + id, mapUserAccount(data));

      const result: IUserOriginal = mapUser(response?.data?.data);

      dispatch(slice.actions.updateUserSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createUser(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post('/users/', mapUserAccount(data));

      const result: IUserOriginal = mapUser(response?.data?.data);

      dispatch(slice.actions.createUserSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeUser(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/users/' + id);

      const result = response?.data;

      dispatch(slice.actions.removeUserSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('User is not deleted: ' + error.message);
    }
  };
}

export function resetPassword(data: IUserOriginalReset) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
    try {
      const response = await axios.patch(
        '/users/password-reset/me',
        mapPasswordReset(data)
      );

      const result: IUserOriginal = mapUser(response?.data?.data);

      dispatch(slice.actions.updateUserSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function resetPersonalData(data: IUserOriginalReset) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
    try {
      const response = await axios.patch(
        '/users/profile/me',
        mapProfileData(data)
      );

      const result: IUserOriginal = mapUser(response?.data?.data);

      dispatch(slice.actions.updateUserSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function resetUsers() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
