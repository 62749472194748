// @mui
import { Stack, InputAdornment, TextField, Button } from '@mui/material';
// components
import Iconify from '../../../../components/iconify';
import { useLocales } from '../../../../locales';

// ----------------------------------------------------------------------

type Props = {
  filterName: string;
  isFiltered: boolean;
  onResetFilter: VoidFunction;
  onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function ReminderToolbar({
  isFiltered,
  filterName,
  onFilterName,
  onResetFilter,
}: Props) {
  const { translate } = useLocales();

  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      {/*<TextField*/}
      {/*  fullWidth*/}
      {/*  select*/}
      {/*  label="Role"*/}
      {/*  value={filterCity}*/}
      {/*  onChange={onFilterCity}*/}
      {/*  SelectProps={{*/}
      {/*    MenuProps: {*/}
      {/*      PaperProps: {*/}
      {/*        sx: {*/}
      {/*          maxHeight: 260,*/}
      {/*        },*/}
      {/*      },*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  sx={{*/}
      {/*    maxWidth: { sm: 240 },*/}
      {/*    textTransform: 'capitalize',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {optionsCity.map((option) => (*/}
      {/*    <MenuItem*/}
      {/*      key={option}*/}
      {/*      value={option}*/}
      {/*      sx={{*/}
      {/*        mx: 1,*/}
      {/*        borderRadius: 0.75,*/}
      {/*        typography: 'body2',*/}
      {/*        textTransform: 'capitalize',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {option}*/}
      {/*    </MenuItem>*/}
      {/*  ))}*/}
      {/*</TextField>*/}

      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        placeholder={`${translate('search')}...`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          {`${translate('clear')}`}
        </Button>
      )}
    </Stack>
  );
}
