export const navigateHandler = (
  userRoles: string[],
  navigateUrl: string,
  allowedTo: string[]
): string | undefined => {
  if (userRoles.some((role) => allowedTo.includes(role))) {
    return navigateUrl;
  }
  return undefined;
};

export const urlHandler = (
  firstUrl: string,
  url: string,
  secondUrl?: string
): string => {
  if (url.includes(firstUrl)) {
    return firstUrl;
  } else if (secondUrl && url.includes(secondUrl)) {
    return secondUrl;
  }
  return '';
};
