import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosAPI';
import {
  FilterData,
  IServiceRequest,
  IServiceRequestLight,
  IServiceRequestState,
} from '../../@types/serviceRequest';

// ----------------------------------------------------------------------

const initialState: IServiceRequestState = {
  isLoading: true,
  error: null,
  serviceRequests: [],
  serviceRequest: null,
};

const slice = createSlice({
  name: 'serviceRequest',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET serviceRequests
    getServiceRequestsSuccess(state, action) {
      state.isLoading = false;
      state.serviceRequests = action.payload;
    },

    // GET serviceRequest
    getServiceRequestSuccess(state, action) {
      state.isLoading = false;
      state.serviceRequest = action.payload;
    },

    // UPDATE serviceRequest
    updateServiceRequestSuccess(state, action) {
      state.isLoading = false;
      state.serviceRequest = action.payload;
    },

    // CREATE serviceRequest
    createServiceRequestSuccess(state, action) {
      state.isLoading = false;
      state.serviceRequest = action.payload;
    },

    // REMOVE serviceRequest
    removeServiceRequestSuccess(state, action) {
      state.isLoading = false;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.serviceRequests = [];
      state.serviceRequest = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
function mapToServiceRequest<T>(serviceRequest: any): T {
  return {
    id: serviceRequest?.id ? serviceRequest.id : '',
    name: serviceRequest?.name ? serviceRequest.name : '',
    description: serviceRequest?.description ? serviceRequest.description : '',
    status: serviceRequest?.status ? serviceRequest.status : '',
    property: serviceRequest?.property ? serviceRequest.property : '',
    customer: serviceRequest?.customer ? serviceRequest.customer : '',
    rooms: serviceRequest?.rooms ? serviceRequest.rooms : '',
    itemId: serviceRequest?.itemId ? serviceRequest.itemId : '',
    author: serviceRequest?.author ? serviceRequest.author : '',
    assignee: serviceRequest?.assignee ? serviceRequest.assignee : '',
    createdAt: serviceRequest?.createdAt ? serviceRequest.createdAt : '',
    updatedAt: serviceRequest?.updatedAt ? serviceRequest.updatedAt : '',
    priority: serviceRequest?.priority ? serviceRequest.priority : '',
  } as T;
}

const mapServiceRequestAccount = (serviceRequest: any) => {
  return {
    name: serviceRequest?.name,
    description: serviceRequest?.description,
    customerId: serviceRequest?.customerId,
    propertyId: serviceRequest?.propertyId,
    priority: serviceRequest?.priority,
    itemId: serviceRequest?.itemId,
  };
};

// ----------------------------------------------------------------------

export function getServiceRequests() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/service-requests');

      const result: IServiceRequestLight[] = response?.data?.data?.map(
        (serviceRequest: any) =>
          mapToServiceRequest<IServiceRequestLight>(serviceRequest)
      );

      dispatch(slice.actions.getServiceRequestsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getFilteredServiceRequests(
  data: FilterData,
  propertyId?: number
) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/service-requests', {
        params: {
          statuses: data.filteredStatus,
          customers: data.filteredCustomerIds,
          properties: data.filteredPropertyIds,
          priorities: data.filteredPriorities,
          propertyId: propertyId,
        },
      });

      const result: IServiceRequest[] = response?.data?.data?.map(
        (serviceRequest: any) =>
          mapToServiceRequest<IServiceRequest>(serviceRequest)
      );

      dispatch(slice.actions.getServiceRequestsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getServiceRequest(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/service-requests/' + id);

      const result: IServiceRequest = mapToServiceRequest<IServiceRequest>(
        response?.data?.data
      );

      dispatch(slice.actions.getServiceRequestSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getPropertyServiceRequests(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(
        'properties/' + id + '/service-requests'
      );

      const result: IServiceRequest[] = response?.data?.data?.map(
        (request: any) => mapToServiceRequest<IServiceRequest>(request)
      );

      dispatch(slice.actions.getServiceRequestsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateServiceRequest(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        '/service-requests/' + id,
        mapServiceRequestAccount(data)
      );

      const result: IServiceRequest = mapToServiceRequest<IServiceRequest>(
        response?.data?.data
      );

      dispatch(slice.actions.updateServiceRequestSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function createServiceRequest(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/service-requests/',
        mapServiceRequestAccount(data)
      );

      const result: IServiceRequest = mapToServiceRequest<IServiceRequest>(
        response?.data?.data
      );

      dispatch(slice.actions.createServiceRequestSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function removeServiceRequest(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/service-requests/' + id);
      const result = response?.data;
      dispatch(slice.actions.removeServiceRequestSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      // Throw the error to propagate it to the calling function
      throw new Error('Error occurred during remove: ' + error.message);
    }
  };
}

export function updateToProgressServiceRequest(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/service-requests/' + id + '/accept');

      const result = response?.data.data;

      dispatch(slice.actions.updateServiceRequestSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function updateToDoneServiceRequest(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/service-requests/' + id + '/close');

      const result = response?.data.data;

      dispatch(slice.actions.updateServiceRequestSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function resetServiceRequets() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
