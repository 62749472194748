import { Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useLocales } from 'src/locales';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

interface CustomSimpleFilterProps {
  dataToFilter: any;
  filteredData: any;
  handleChange: (e: any, name: string) => void;
  updating: string;
}

const CustomSimpleFilter: FC<CustomSimpleFilterProps> = (props) => {
  const { translate } = useLocales();
  const { dataToFilter, filteredData, handleChange, updating } = props;
  return (
    <FormControl sx={{ mt: 3, width: 220, mr: 2 }}>
      <InputLabel id="demo-multiple-checkbox-label">{`${translate(
        `${updating}`
      )}`}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={filteredData}
        onChange={(e: any) => handleChange(e, updating)}
        input={<OutlinedInput label={`${translate(`${updating}`)}`} />}
        renderValue={(selected) => {
          if (Array.isArray(selected)) {
            return (
              <Typography>
                {selected
                  .map((value) => translate(`${value.toLocaleLowerCase()}`))
                  .join(', ')}
              </Typography>
            );
          } else {
            return (
              <Typography>{`${translate(
                `${selected.toLocaleLowerCase()}`
              )}`}</Typography>
            );
          }
        }}
        MenuProps={MenuProps}
      >
        {dataToFilter.map((filter: string) => (
          <MenuItem key={filter} value={filter}>
            <Checkbox checked={filteredData.indexOf(filter) > -1} />
            <p style={{ fontSize: 14 }}>{`${translate(
              `${filter.toLocaleLowerCase()}`
            )}`}</p>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSimpleFilter;
