import * as Yup from 'yup';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Stack } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { ADMIN_MANAGERS_ROLES, COULT_NOT_EDIT, SUCCESS_EDIT } from 'src/constants';
import { dispatch, useSelector } from 'src/redux/store';
import { getProperty } from 'src/redux/slices/property';
import { IRoom } from 'src/@types/room';
import { getPropertyRooms, getRoom, updateRoom } from 'src/redux/slices/room';
import { useLocales } from 'src/locales';
import { allowTo } from 'src/utils/permissionHandler';
import { useAuthContext } from 'src/auth/useAuthContext';

// ----------------------------------------------------------------------

type FormValuesProps = {
  propertyId: number | string;
  name: string;
  parentRoomId: number | string;
};

type Props = {
  room?: IRoom;
  propertyId?: number | string;
  roomId?: number | string;
};

export default function RoomNewEditForm({ room, propertyId, roomId }: Props) {
  const navigate = useNavigate();
  const [editing, setEditing] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { rooms } = useSelector((state) => state.room);
  const { property } = useSelector((state) => state.property);
  const { user } = useAuthContext();

  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    propertyId: Yup.number().required('Property is required'),
  });

  const defaultValues = useMemo(() => {
    return {
      name: room?.name || '',
      propertyId: propertyId || '',
      parentRoomId: room?.parentRoom?.id || '',
    };
  }, [room, propertyId]);

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    const fetchData = async () => {
      if (propertyId && roomId) {
        Promise.all([
          dispatch(getPropertyRooms(Number(propertyId))),
          dispatch(getProperty(Number(propertyId))),
          dispatch(getRoom(Number(roomId))),
        ]);
      }
    };
    fetchData();
  }, [propertyId, roomId, dispatch]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(updateRoom(Number(room?.id), data));
      reset();
      enqueueSnackbar(SUCCESS_EDIT, { variant: 'success' });
      navigate(PATH_DASHBOARD.property.rooms(propertyId?.toString() || '1'));
    } catch (error) {
      enqueueSnackbar(COULT_NOT_EDIT, { variant: 'error' });
    }
  };
  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 5 }}>
          <Button
            onClick={() =>
              navigate(
                PATH_DASHBOARD.property.rooms(propertyId?.toString() || '1')
              )
            }
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('name')}`}
              value={values.name}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
            <RHFSelect
              native
              name="propertyId"
              label={`${translate('property')}`}
              placeholder={`${translate('property')}`}
              value={values.propertyId}
              disabled={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            >
              <option key={propertyId} value={propertyId}>
                {property?.name}
              </option>
            </RHFSelect>
            <RHFSelect
              native
              name="parentRoomId"
              label={`${translate('parentRoom')}`}
              placeholder={`${translate('parentRoom')}`}
              value={values.parentRoomId}
              disabled={!editing}
              variant={!editing ? 'standard' : 'outlined'}
            >
              {!room?.parentRoom && <option key={''} value=""></option>}
              {rooms?.map((room) => (
                <option key={room.id} value={room.id}>
                  {room.name}
                </option>
              ))}
            </RHFSelect>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            {editing &&
              allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
                <>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    {`${translate('saveChanges')}`}
                  </LoadingButton>
                  <Button
                    onClick={() => setEditing(false)}
                    sx={{ ml: 1 }}
                    variant="contained"
                  >
                    {`${translate('close')}`}
                  </Button>
                </>
              )}
            {!editing &&
              allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
                <Button
                  onClick={() => setEditing(true)}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  {`${translate('edit')}`}
                </Button>
              )}
          </Box>
        </Card>
      </FormProvider>
    </Container>
  );
}
