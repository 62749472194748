import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// auth
// utils
// assets
import { countries } from '../../../../assets/data';
// components
import { CustomFile } from '../../../../components/upload';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { useDispatch } from '../../../../redux/store';
import { createCustomer } from '../../../../redux/slices/customer';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { useLocales } from '../../../../locales';

// ----------------------------------------------------------------------

type FormValuesProps = {
  displayName: string;
  email: string;
  photoURL: CustomFile | string | null;
  phoneNumber: string | null;
  country: string | null;
  address: string | null;
  state: string | null;
  city: string | null;
  zip: string | null;
  about: string | null;
  isPublic: boolean;
  description: string | null;
};

export default function CustomerCreateForm() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { translate } = useLocales();

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    // email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    // photoURL: Yup.mixed().required('Avatar is required'),
    // phoneNumber: Yup.string().required('Phone number is required'),
    country: Yup.string().required(`${translate('isRequired.country')}`),
    street: Yup.string().required(`${translate('isRequired.street')}`),
    // state: Yup.string().required('State is required'),
    city: Yup.string().required(`${translate('isRequired.city')}`),
    zip: Yup.string().required(`${translate('isRequired.zipCode')}`),
    description: Yup.string().required(
      `${translate('isRequired.description')}`
    ),
    // businessId: Yup.string().required('About is required'),
    // vatId: Yup.string().required('About is required'),
    // taxId: Yup.string().required('About is required'),
  });

  const defaultValues = {
    name: '',
    country: 'Slovakia',
    street: '',
    city: '',
    zip: '',
    businessId: '',
    vatId: '',
    taxId: '',
    description: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(createCustomer(data));
      reset();
      navigate(PATH_DASHBOARD.general.customers);
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
            <Button
              onClick={() => navigate(PATH_DASHBOARD.general.customers)}
              sx={{ float: 'right' }}
              type="button"
              variant="contained"
            >
              {`${translate('back')}`}
            </Button>
          </Stack>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                name="name"
                label={`${translate('name')}`}
                required
              />

              <RHFTextField
                name="description"
                label={`${translate('description')}`}
                required
              />

              <RHFTextField
                name="businessId"
                label={`${translate('businessId')}`}
              />

              <RHFTextField name="vatId" label={`${translate('vatId')}`} />

              <RHFTextField name="taxId" label={`${translate('taxId')}`} />

              <RHFTextField
                name="street"
                label={`${translate('street')}`}
                required
              />

              <RHFTextField
                name="city"
                label={`${translate('city')}`}
                required
              />

              <RHFSelect
                native
                name="country"
                label={`${translate('country')}`}
                placeholder={`${translate('country')}`}
                required
              >
                <option value="" />
                {countries.map((country) => (
                  <option key={country.code} value={country.label}>
                    {country.label}
                  </option>
                ))}
              </RHFSelect>
              <RHFTextField
                name="zip"
                label={`${translate('zipCode')}`}
                type="number"
                inputProps={{
                  max: 99999, // Set the maximum value as per your requirement
                }}
                required
              />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              {/*<RHFTextField name="about" multiline rows={4} label="About" />*/}

              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {`${translate('pages.customer.createCustomer')}`}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
