import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import TaskCreateForm from 'src/sections/@dashboard/task/create/TaskCreateForm';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function TaskCreatePage() {
  const { translate } = useLocales();

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.task.titleCreate')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.task.createNewTask')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('tasks')}`,
              href: PATH_DASHBOARD.general.task,
            },
            { name: `${translate('pages.task.createTask')}` },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              <TaskCreateForm />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
