import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Card, Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { useDispatch, useSelector } from '../../../../redux/store';
import { createItemCategory, getItemCategories, getItemCategoriesWithoutParent } from 'src/redux/slices/itemCategory';
import { useLocales } from '../../../../locales';

// ----------------------------------------------------------------------

type FormValuesProps = {
  name: string;
  parentId: string;
};

export default function ItemCategoryCreateForm() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { itemCategoriesWithoutParent } = useSelector(
    (state) => state.itemCategory
  );

  useEffect(() => {
    if (!isDataFetched) {
      Promise.all([dispatch(getItemCategoriesWithoutParent())]).then(() => {
        setIsDataFetched(true);
      });
    }
  }, [dispatch, isDataFetched]);

  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
  });

  const defaultValues = {
    name: '',
    parentId: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(createItemCategory(data));
      await dispatch(getItemCategories());
      await dispatch(getItemCategoriesWithoutParent());
      reset(defaultValues);
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField
                name="name"
                label={`${translate('name')}`}
                value={values.name}
                required
              />
              <RHFSelect
                native
                name="parentId"
                label={`${translate('parentCategory')}`}
                placeholder={`${translate('parentCategory')}`}
                value={values.parentId}
              >
                <option key={''} value=""></option>
                {itemCategoriesWithoutParent.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </RHFSelect>
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              {/*<RHFTextField name="about" multiline rows={4} label="About" />*/}

              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {`${translate('pages.configuration.createItemCategory')}`}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
