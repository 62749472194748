import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { IItemLog, IItemLogState } from 'src/@types/itemLog';
import axios from '../../utils/axiosAPI';

const initialState: IItemLogState = {
  isLoading: true,
  error: null,
  itemLogs: [],
  itemLog: null,
};

const slice = createSlice({
  name: 'itemLog',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET ITEMLOGS
    getItemLogsSuccess(state, action) {
      state.isLoading = false;
      state.itemLogs = action.payload;
    },

    // GET ITEMLOG
    getItemLogSuccess(state, action) {
      state.isLoading = false;
      state.itemLog = action.payload;
    },

    // UPDATE ITEMLOG
    updateItemLogSuccess(state, action) {
      state.isLoading = false;
      state.itemLog = action.payload;
    },

    // CREATE ITEMLOG
    createItemLogSuccess(state, action) {
      state.isLoading = false;
      state.itemLog = action.payload;
    },

    // REMOVE ITEMLOG
    removeItemLogSuccess(state, action) {
      state.isLoading = false;
      // state.item = action.payload;
    },

    reset(state) {
      state.error = null;
      state.isLoading = true;
      state.itemLogs = [];
      state.itemLog = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
const mapItemLog = (itemLog: any): IItemLog => {
  return {
    id: itemLog?.id ? itemLog.id : '',
    title: itemLog?.title ? itemLog.title : '',
    content: itemLog?.content ? itemLog?.content : null,
    type: itemLog?.type ? itemLog?.type : null,
    authorId: itemLog?.authorId ? itemLog?.authorId : null,
    itemId: itemLog?.itemId ? itemLog?.itemId : null,
    createdAt: itemLog?.created ? itemLog.created : '',
    updatedAt: itemLog?.updated ? itemLog.updated : '',
    authorName: itemLog?.authorName ? itemLog.authorName : '',
  };
};

const mapItemLogAccount = (itemLog: any) => {
  return {
    title: itemLog?.name,
    content: itemLog?.content,
    type: itemLog?.type,
    itemId: itemLog?.itemId,
  };
};

// ----------------------------------------------------------------------

export function getItemLogs(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/items/' + id + '/logs');

      const result: IItemLog[] = response?.data?.data?.map((itemLog: any) =>
        mapItemLog(itemLog)
      );

      dispatch(slice.actions.getItemLogsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateItemLog(logId: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        '/items/logs/' + logId,
        mapItemLogAccount(data)
      );

      const result: IItemLog = mapItemLog(response?.data?.data);

      dispatch(slice.actions.updateItemLogSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createItemLog(itemId: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/items/' + itemId + '/logs',
        mapItemLogAccount(data)
      );

      const result: IItemLog = mapItemLog(response?.data?.data);

      dispatch(slice.actions.createItemLogSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeItemLog(itemLogId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/items/' + itemLogId + '/logs', {
        params: { itemLogId },
      });

      const result = response?.data;

      dispatch(slice.actions.removeItemLogSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function resetItemLogs() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
