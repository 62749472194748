import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import { useDispatch } from 'src/redux/store';
import { useEffect, useState } from 'react';
import { useLocales } from '../../../locales';
import { IAttachment } from 'src/@types/attachment';
import { updateAttachment } from 'src/redux/slices/attachment';

interface FormValuesProps {
  name: string;
  description: string;
}

type Props = {
  attachment: IAttachment | null;
  itemId?: string;
  itemLogId?: string;
  serviceRequestId?: string;
  reminderId?: string;
  disabledEdit?: boolean;
};

export default function AttachmentEditForm({
  attachment,
  itemId,
  itemLogId,
  serviceRequestId,
  reminderId,
  disabledEdit,
}: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [navigatePath, setNavigatePath] = useState('');

  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    description: Yup.string().required(
      `${translate('isRequired.description')}`
    ),
  });

  const [defaultValues, setDefaultValues] = useState<FormValuesProps>({
    name: attachment?.name || '',
    description: attachment?.description || '',
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    setDefaultValues((prevDefaultValues) => ({
      ...prevDefaultValues,
      name: attachment?.name || '',
      description: attachment?.description || '',
    }));
    reset(defaultValues);
  }, [attachment]);

  useEffect(() => {
    if (itemId && itemLogId) {
      setNavigatePath(
        PATH_DASHBOARD.item.itemLogsAttachments(
          itemId ? itemId : '1',
          itemLogId ? itemLogId : '1'
        )
      );
    } else if (itemId) {
      setNavigatePath(PATH_DASHBOARD.item.attachments(itemId ? itemId : '1'));
    } else if (serviceRequestId) {
      setNavigatePath(
        PATH_DASHBOARD.serviceRequest.attachments(
          serviceRequestId ? serviceRequestId : '1'
        )
      );
    } else if (reminderId) {
      setNavigatePath(PATH_DASHBOARD.reminder.attachments(reminderId));
    }
  }, []);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(
        updateAttachment(attachment ? Number(attachment.id) : 1, data)
      );
      reset();
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      navigate(navigatePath);
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 5 }}>
          <Button
            onClick={() => navigate(-1)}
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('name')}`}
              value={values.name}
              required
              readOnly={disabledEdit}
              variant={disabledEdit ? 'standard' : 'outlined'}
            />

            <RHFTextField
              name="description"
              label={`${translate('description')}`}
              multiline
              rows={3}
              value={values.description}
              required
              readOnly={disabledEdit}
              variant={disabledEdit ? 'standard' : 'outlined'}
            />
          </Box>

          {!disabledEdit && (
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {`${translate('saveChanges')}`}
              </LoadingButton>
            </Stack>
          )}
        </Card>
      </FormProvider>
    </Container>
  );
}
