import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable
} from '../../../components/table';
import { dispatch, useSelector } from 'src/redux/store';
import { useSnackbar } from 'notistack';
import { RoomTableRow } from 'src/sections/@dashboard/room/list';
import { getPropertyRooms, getRooms, removeRoom } from 'src/redux/slices/room';
import { IRoom } from 'src/@types/room';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useLocales } from '../../../locales';
import useDeleteRowsHandler from 'src/hooks/useDeleteHandler';
import { ADMIN_MANAGERS_ROLES, CUSTOMER, PROPERTY } from 'src/constants';
import { allowTo } from 'src/utils/permissionHandler';
import { getPageFromStorage, savePageToStorage } from 'src/utils/pageStorageHandler';
import { getRowsPerPageFromStorage, saveRowsPerPageToStorage } from 'src/utils/rowsPerPageStorageHandler';
import { setLastUrlItems, setLastUrlRooms } from 'src/redux/slices/setting';

type RoomListPageProps = {
  propertyId?: string;
  customerId?: string;
  fromUrl?: string;
};

export default function RoomListPage({
  propertyId,
  customerId,
  fromUrl,
}: RoomListPageProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [tableData, setTableData] = useState<IRoom[]>([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const { deleteHandlers } = useDeleteRowsHandler();
  const [openConfirm, setOpenConfirm] = useState(false);

  const [filterStatus, setFilterStatus] = useState('all');
  const { rooms, isLoading } = useSelector((state: any) => state.room);

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 52 : 72;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const tableHead = [
    { id: 'name', label: `${translate('name')}`, align: 'left' },
    { id: 'property', label: `${translate('property')}`, align: 'left' },
    { id: 'parentRoom', label: `${translate('parentRoom')}`, align: 'left' },
    { id: 'subRooms', label: `${translate('subRoom')}`, align: 'left' },
    { id: 'actions', label: `${translate('actions')}`, align: 'right' },
  ];

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await dispatch(removeRoom(Number(id)));
      if (!propertyId) {
        await dispatch(getRooms());
      } else {
        await dispatch(getPropertyRooms(Number(propertyId)));
      }
      const deleteRow = tableData.filter(
        (row) => Number(row.id) !== Number(id)
      );
      setSelected([]);
      setTableData(deleteRow);

      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    try {
      if (await deleteHandlers.deleteRows(selectedRows, removeRoom)) {
        return;
      }

      const filteredRows = tableData.filter(
        (row: any) => !selectedRows.includes(row.id.toString())
      );
      setSelected([]);
      setTableData(filteredRows);

      if (page > 0) {
        if (selectedRows.length === dataInPage.length) {
          setPage(page - 1);
        } else if (selectedRows.length === dataFiltered.length) {
          setPage(0);
        } else if (selectedRows.length > dataInPage.length) {
          const newPage =
            Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
            1;
          setPage(newPage);
        }
      }

      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleEditRow = (id: string) => {
    savePageToStorage('room', page);
    saveRowsPerPageToStorage('room', rowsPerPage);
    if (propertyId) {
      dispatch(setLastUrlRooms(PROPERTY));
      dispatch(setLastUrlItems(PROPERTY));
    }
    navigate(PATH_DASHBOARD.room.view(propertyId?.toString() || '1', id));
  };

  const navigateBackHandler = () => {
    switch (fromUrl) {
      case CUSTOMER:
        navigate(
          PATH_DASHBOARD.customer.properties(customerId || '1').toString()
        );
        return;
      default:
        break;
    }
    if (propertyId && fromUrl === PROPERTY) {
      navigate(PATH_DASHBOARD.property.view(propertyId));
    } else {
      navigate(PATH_DASHBOARD.general.properties);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLastUrlRooms(''));
      if (!propertyId) {
        await dispatch(getRooms());
      } else {
        await dispatch(getPropertyRooms(Number(propertyId)));
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    setTableData(rooms);
  }, [rooms]);

  useEffect(() => {
    const savedPage = getPageFromStorage('room');
    setPage(savedPage ?? 0);
    const savedRowsPerPage = getRowsPerPageFromStorage('room');
    setRowsPerPage(savedRowsPerPage ?? 5);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${translate('room')}`} | Properio.io</title>
      </Helmet>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth={false}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5 }}>
            {allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
              <Button
                component={RouterLink}
                to={
                  propertyId
                    ? PATH_DASHBOARD.room.create(propertyId)
                    : PATH_DASHBOARD.property.create
                }
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                {`${translate('pages.room.newRoom')}`}
              </Button>
            )}
            <Button
              onClick={navigateBackHandler}
              sx={{ float: 'right', marginLeft: '13px' }}
              type="button"
              variant="contained"
            >
              {`${translate('back')}`}
            </Button>
          </Box>

          <Card>
            <Divider />
            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id.toString())
                  )
                }
                action={
                  <Tooltip title={`${translate('delete')}`}>
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHead}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={
                      !allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES)
                        ? undefined
                        : (checked) =>
                            onSelectAllRows(
                              checked,
                              tableData.map((row) => row.id.toString())
                            )
                    }
                  />

                  <TableBody>
                    <>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <RoomTableRow
                            key={row.id}
                            row={row}
                            selected={selected.includes(row.id.toString())}
                            onSelectRow={() => onSelectRow(row.id.toString())}
                            onDeleteRow={() =>
                              handleDeleteRow(row.id.toString())
                            }
                            onEditRow={() => handleEditRow(row.id.toString())}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          tableData.length
                        )}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </>
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {selected.length > 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong>{' '}
                {selected.length > 4
                  ? translate('item_five_more')
                  : translate('item_two_four')}
                ?
              </>
            )}
            {selected.length === 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong> {translate('item_one')}?
              </>
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  inputData: IRoom[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  return inputData;
}
