import { FC, ReactNode } from 'react';
import { useAuthContext } from './useAuthContext';
import { Navigate } from 'react-router-dom';

interface RoleGuardProps {
  children: ReactNode;
  allowedRoles: string[];
}

const RoleGuard: FC<RoleGuardProps> = ({ children, allowedRoles }) => {
  const { user } = useAuthContext();

  const hasAllowedRole = allowedRoles.some((role: string) =>
    user?.roles.includes(role)
  );

  if (!hasAllowedRole && user) {
    return <Navigate to="/404" />;
  }

  return <>{children}</>;
};

export default RoleGuard;
