import { useState } from 'react';
import { Button, Checkbox, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import ConfirmDialog from '../../../../components/confirm-dialog';
import { ONLY_ADMIN_ROLES, SERVICE_REQUEST_STATUS } from 'src/constants';
import { IServiceRequestLight } from 'src/@types/serviceRequest';
import { useLocales } from 'src/locales';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAuthContext } from 'src/auth/useAuthContext';
import { allowTo } from 'src/utils/permissionHandler';
import { dispatch } from 'src/redux/store';
import { updateToDoneServiceRequest } from 'src/redux/slices/serviceRequest';
import { useSnackbar } from 'src/components/snackbar';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

// ----------------------------------------------------------------------

enum ServiceRequestStatus {
  CREATED = 'created',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

type Props = {
  row: IServiceRequestLight;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  itemName: string;
  onHandleUpdateToDone: (id: number) => void;
};

export default function ServiceRequestTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  itemName,
  onHandleUpdateToDone,
}: Props) {
  const { id, name, description, customer, property, author, status } = row;
  const { user } = useAuthContext();
  const [openConfirm, setOpenConfirm] = useState(false);
  const { translate } = useLocales();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleUpdateToDone = () => {
    onHandleUpdateToDone(+id);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        {allowTo(user?.roles, ONLY_ADMIN_ROLES) && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell>
        )}

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/*<Avatar alt={name} src={avatarUrl} />*/}

            <Typography
              variant="subtitle2"
              onClick={() => onEditRow()}
              style={{ cursor: 'pointer' }}
              noWrap
            >
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">{description}</TableCell>
        <TableCell align="left">{customer?.name}</TableCell>
        <TableCell align="left">{property?.name}</TableCell>
        <TableCell align="left">
          {author
            ? `${author?.firstName} ${author?.lastName}`
            : `${translate('unknown')}`}
        </TableCell>
        <TableCell align="left">{itemName}</TableCell>
        <TableCell align="left">
          {`${translate(
            `${
              SERVICE_REQUEST_STATUS.find(
                (loopedStatus: { value: string; label: string }) =>
                  loopedStatus.value === status
              )?.value
            }`
          )}`}
        </TableCell>

        <TableCell
          align="right"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          {status === ServiceRequestStatus.IN_PROGRESS && (
            <Tooltip title={`${translate('done')}`}>
              <CheckCircleOutlineIcon
                onClick={handleUpdateToDone}
                sx={{ cursor: 'pointer' }}
              />
            </Tooltip>
          )}
          <Tooltip title={`${translate('edit')}`} sx={{ cursor: 'pointer' }}>
            <EditIcon
              onClick={() => {
                onEditRow();
                handleClosePopover();
              }}
            />
          </Tooltip>
          {allowTo(user ? user.roles : [], ONLY_ADMIN_ROLES) && (
            <Tooltip
              title={`${translate('delete')}`}
              sx={{ cursor: 'pointer' }}
            >
              <DeleteIcon
                onClick={() => {
                  handleOpenConfirm();
                  handleClosePopover();
                }}
              />
            </Tooltip>
          )}
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('infoMessages.deletePromptV2')}`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
