import * as Yup from 'yup';
import { useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// auth
import { useAuthContext } from '../../../../auth/useAuthContext';
import { countries } from '../../../../assets/data';
// components
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getCustomers } from '../../../../redux/slices/customer';
import { createProperty } from 'src/redux/slices/property';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../../locales';
import { getPropertyWithPropertyManagersRole } from 'src/redux/slices/user';

// ----------------------------------------------------------------------

type FormValuesProps = {
  id: string | null;
  name: string;
  company: string;
  country: string;
  street: string;
  city: string;
  zip: string;
  customerId: number | string;
  managerId: number | string;
};

type CustomerCreateFormProps = {
  customerId?: number;
};

export default function PropertyCreateForm({
  customerId,
}: CustomerCreateFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customers } = useSelector((state) => state.customer);
  const { users } = useSelector((state) => state.user);
  const { user } = useAuthContext();
  const { translate } = useLocales();

  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    country: Yup.string().required(`${translate('isRequired.country')}`),
    street: Yup.string().required(`${translate('isRequire d.street')}`),
    city: Yup.string().required(`${translate('isRequired.city')}`),
    zip: Yup.string().required(`${translate('isRequired.zipCode')}`),
    customerId: Yup.string().required(`${translate('isRequired.customerId')}`),
    managerId: Yup.string().required(`${translate('isRequired.manager')}`),
  });

  const customer = customerId ? customerId : user?.customerId;
  const defaultValues = {
    name: '',
    country: 'Slovakia',
    street: '',
    city: '',
    zip: '',
    customerId: customer ? customer : '',
    managerId: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(createProperty(data));
      navigateHandler();
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
      reset();
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  const navigateHandler = () => {
    switch (customerId) {
      case undefined:
        navigate(PATH_DASHBOARD.general.properties);
        break;
      default:
        navigate(PATH_DASHBOARD.customer.properties(customerId.toString()));
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getCustomers()),
        dispatch(getPropertyWithPropertyManagersRole()),
      ]);
    };
    fetchData();
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
        <Button
          onClick={navigateHandler}
          sx={{ float: 'right' }}
          type="button"
          variant="contained"
        >
          {`${translate('back')}`}
        </Button>
      </Stack>
      <Card sx={{ p: 3 }}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField name="name" label={`${translate('name')}`} required />
          <RHFSelect
            native
            name="managerId"
            label={`${translate('manager')}`}
            placeholder={`${translate('manager')}`}
            required
          >
            <option value=""></option>
            {users.map((manager) => {
              return (
                <option key={manager.id} value={manager.id}>
                  {manager.firstName} {manager.lastName}
                </option>
              );
            })}
          </RHFSelect>
          <RHFSelect
            native
            name="customerId"
            label={`${translate('customer')}`}
            placeholder={`${translate('customer')}`}
            disabled={customer ? true : false}
            required
          >
            <option value="0" />
            {customers &&
              customers.map((customer) => {
                return (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                );
              })}
          </RHFSelect>
          <RHFTextField
            name="street"
            label={`${translate('street')}`}
            required
          />
          <RHFTextField name="zip" label={`${translate('zipCode')}`} required />
          <RHFTextField name="city" label={`${translate('city')}`} required />
          <RHFSelect
            native
            name="country"
            label={`${translate('country')}`}
            placeholder={`${translate('country')}`}
            required
          >
            <option value="" />
            {countries.map((country) => (
              <option key={country.label} value={country.label}>
                {country.label}
              </option>
            ))}
          </RHFSelect>
        </Box>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          {/*<RHFTextField name="about" multiline rows={4} label="About" />*/}

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('pages.property.createProperty')}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
