import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable
} from '../../../components/table';
import { useDispatch, useSelector } from '../../../redux/store';
import { getCustomerProperties, getFilteredProperties, getProperties, removeProperty } from 'src/redux/slices/property';
import PropertyTableRow from 'src/sections/@dashboard/property/list/PropertyTableRow';
import { FilterData, IProperty } from 'src/@types/property';
import PropertyTableToolbar from 'src/sections/@dashboard/property/list/PropertyTableToolbar';
import { useSnackbar } from 'notistack';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useLocales } from '../../../locales';
import { IUserOriginal } from 'src/@types/user';
import { ICustomer } from 'src/@types/customer';
import { getPropertyManagers } from 'src/redux/slices/user';
import { getCustomers, setCustomerToNull } from 'src/redux/slices/customer';
import {
  CUSTOMER,
  FILTERED_CITY,
  FILTERED_CUSTOMERS_IDS,
  FILTERED_MANAGER_IDS,
  ONLY_ADMIN_COMPANY_MANAGER_ROLES,
  ROLES_PERMISSIONS
} from 'src/constants';
import CustomFilter from '../CustomFilter';
import CustomSimpleFilter from '../CustomSimpleFilter';
import useDeleteRowsHandler from 'src/hooks/useDeleteHandler';
import { setLastUrlProperties } from 'src/redux/slices/setting';
import { allowTo } from 'src/utils/permissionHandler';
import { getPageFromStorage, savePageToStorage } from 'src/utils/pageStorageHandler';
import { getRowsPerPageFromStorage, saveRowsPerPageToStorage } from 'src/utils/rowsPerPageStorageHandler';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

type PropertyListPageProps = {
  customerId?: string;
};

type Filter = {
  cities: string[];
  managers: IUserOriginal[];
  customers: ICustomer[];
};

// ----------------------------------------------------------------------

export default function PropertyListPage({
  customerId,
}: PropertyListPageProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { deleteHandlers } = useDeleteRowsHandler();
  const dispatch = useDispatch();
  const { properties, isLoading: propertyLoading } = useSelector(
    (state) => state.property
  );
  const { users, isLoading: userLoading } = useSelector((state) => state.user);
  const { customers, isLoading: customerLoading } = useSelector(
    (state) => state.customer
  );
  const [tableData, setTableData] = useState<IProperty[]>([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const [dataToFilter, setDataToFilter] = useState<Filter>({
    cities: [],
    managers: [],
    customers: [],
  });
  const [filteredData, setFilteredData] = useState<FilterData>({
    [FILTERED_CITY]: [],
    [FILTERED_MANAGER_IDS]: [],
    [FILTERED_CUSTOMERS_IDS]: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const tableHead = [
    { id: 'name', label: `${translate('name')}`, align: 'left' },
    { id: 'customer', label: `${translate('customer')}`, align: 'left' },
    { id: 'street', label: `${translate('street')}`, align: 'left' },
    { id: 'country', label: `${translate('country')}`, align: 'left' },
    { id: 'city', label: `${translate('city')}`, align: 'left' },
    { id: 'zip', label: `${translate('zipCode')}`, align: 'left' },
    { id: 'actions', label: `${translate('actions')}`, align: 'right' },
  ];

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 52 : 72;

  const isFiltered =
    filterName !== '' || filterRole !== 'all' || filterStatus !== 'all';

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await dispatch(removeProperty(Number(id)));
      if (!customerId) {
        await dispatch(getProperties());
      } else {
        await dispatch(getCustomerProperties(Number(customerId)));
      }
      const deleteRow = tableData.filter(
        (row) => Number(row.id) !== Number(id)
      );
      setSelected([]);
      setTableData(deleteRow);

      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    try {
      if (await deleteHandlers.deleteRows(selectedRows, removeProperty)) {
        return;
      }

      const filteredRows = tableData.filter(
        (row: any) => !selectedRows.includes(row.id.toString())
      );
      setSelected([]);
      setTableData(filteredRows);

      if (page > 0) {
        if (selectedRows.length === dataInPage.length) {
          setPage(page - 1);
        } else if (selectedRows.length === dataFiltered.length) {
          setPage(0);
        } else if (selectedRows.length > dataInPage.length) {
          const newPage =
            Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
            1;
          setPage(newPage);
        }
      }

      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleEditRow = (id: string) => {
    savePageToStorage('property', page);
    saveRowsPerPageToStorage('property', rowsPerPage);
    if (customerId) {
      dispatch(setLastUrlProperties(CUSTOMER));
    }
    dispatch(setCustomerToNull());
    navigate(PATH_DASHBOARD.property.view(id));
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterRole('all');
    setFilterStatus('all');
  };

  const handleChange = async (
    event: SelectChangeEvent<typeof filteredData>,
    updating: string
  ) => {
    const {
      target: { value },
    } = event;
    setFilteredData({ ...filteredData, [updating]: value });
    await dispatch(
      getFilteredProperties({ ...filteredData, [updating]: value })
    );
  };

  const uniqueHandler = (arr: any[]) => {
    return arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  };

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getPropertyManagers());
      dispatch(getCustomers());
      if (!customerId) {
        dispatch(getProperties());
      } else {
        dispatch(getCustomerProperties(Number(customerId)));
      }
    };
    fetchData();
    dispatch(setLastUrlProperties(''));
  }, [customerId]);

  useEffect(() => {
    if (
      dataToFilter.cities.length === 0 ||
      dataToFilter.managers.length === 0 ||
      dataToFilter.customers.length === 0
    ) {
      setDataToFilter({
        cities: properties
          .map((customer) => customer.address.city)
          .filter((city, index, self) => self.indexOf(city) === index),
        managers: uniqueHandler(users),
        customers: uniqueHandler(customers),
      });
    }
  }, [properties, users, customers]);

  useEffect(() => {
    setTableData(properties);
  }, [properties]);

  useEffect(() => {
    const savedPage = getPageFromStorage('property');
    setPage(savedPage ?? 0);
    const savedRowsPerPage = getRowsPerPageFromStorage('property');
    setRowsPerPage(savedRowsPerPage ?? 5);
  }, []);

  useEffect(() => {
    const _loading = propertyLoading || userLoading || customerLoading;
    setIsLoading(_loading);
  }, [propertyLoading, userLoading, customerLoading]);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.property.title')}`}</title>
      </Helmet>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth={false}>
          {allowTo(user ? user.roles : [], ONLY_ADMIN_COMPANY_MANAGER_ROLES) &&
            customerId && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5 }}>
                <Button
                  component={RouterLink}
                  to={
                    customerId
                      ? PATH_DASHBOARD.property.createPropertyFromCustomer(
                          customerId
                        )
                      : PATH_DASHBOARD.property.create
                  }
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {`${translate('pages.property.newProperty')}`}
                </Button>
                <Button
                  onClick={() => navigate(PATH_DASHBOARD.general.customers)}
                  sx={{ float: 'right', marginLeft: '13px' }}
                  type="button"
                  variant="contained"
                >
                  {`${translate('back')}`}
                </Button>
              </Box>
            )}
          {!customerId && (
            <CustomBreadcrumbs
              heading={`${translate('pages.property.heading')}`}
              links={[
                {
                  name: `${translate('dashboard')}`,
                  href: PATH_DASHBOARD.root,
                },
                {
                  name: `${translate('properties')}`,
                  href: PATH_DASHBOARD.general.properties,
                },
                { name: `${translate('pages.property.heading')}` },
              ]}
              action={
                allowTo(
                  user ? user.roles : [],
                  ONLY_ADMIN_COMPANY_MANAGER_ROLES
                ) && (
                  <Button
                    component={RouterLink}
                    to={
                      customerId
                        ? PATH_DASHBOARD.property.createPropertyFromCustomer(
                            customerId
                          )
                        : PATH_DASHBOARD.property.create
                    }
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    {`${translate('pages.property.newProperty')}`}
                  </Button>
                )
              }
            />
          )}

          <Card>
            <Divider />
            <div style={{ marginLeft: 20 }}>
              <CustomSimpleFilter
                dataToFilter={dataToFilter.cities}
                filteredData={filteredData.filteredCity}
                handleChange={handleChange}
                updating={FILTERED_CITY}
              />
              <FormControl sx={{ mt: 3, width: 260, mr: 2 }}>
                <InputLabel id="demo-multiple-checkbox-label">{`${translate(
                  'filteredManagerIds'
                )}`}</InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filteredData.filteredManagerIds.map((id) =>
                    String(id)
                  )}
                  onChange={(e: any) => handleChange(e, FILTERED_MANAGER_IDS)}
                  input={
                    <OutlinedInput
                      label={`${translate('filteredManagerIds')}`}
                    />
                  }
                  renderValue={(selected) =>
                    filteredData.filteredManagerIds
                      .map((id) => {
                        const manager = dataToFilter.managers.find(
                          (manager) => Number(manager.id) === Number(id)
                        );
                        return manager
                          ? manager.firstName + ' ' + manager.lastName
                          : '';
                      })
                      .join(', ')
                  }
                  MenuProps={MenuProps}
                >
                  {dataToFilter.managers.map((manager: IUserOriginal) => (
                    <MenuItem key={manager.id} value={manager.id.toString()}>
                      <Checkbox
                        checked={
                          filteredData.filteredManagerIds
                            .toString()
                            .indexOf(manager.id.toString()) > -1
                        }
                      />
                      <p style={{ fontSize: 14 }}>
                        {manager.firstName + ' ' + manager.lastName}
                      </p>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {user?.roles.includes(ROLES_PERMISSIONS.admin) && (
                <CustomFilter
                  dataToFilter={dataToFilter.customers}
                  filteredData={filteredData.filteredCustomerIds}
                  handleChange={handleChange}
                  updating={FILTERED_CUSTOMERS_IDS}
                />
              )}
            </div>

            <PropertyTableToolbar
              isFiltered={isFiltered}
              filterName={filterName}
              onFilterName={handleFilterName}
              onResetFilter={handleResetFilter}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id.toString())
                  )
                }
                action={
                  <Tooltip title={`${translate('delete')}`}>
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHead}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={
                      !allowTo(
                        user ? user.roles : [],
                        ONLY_ADMIN_COMPANY_MANAGER_ROLES
                      )
                        ? undefined
                        : (checked: boolean) =>
                            onSelectAllRows(
                              checked,
                              tableData.map((row) => row.id.toString())
                            )
                    }
                  />

                  <TableBody>
                    {isLoading || dataFiltered.length === 0 ? (
                      <TableNoData isNotFound={true} />
                    ) : (
                      <>
                        {dataFiltered
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((row) => (
                            <PropertyTableRow
                              key={row.id}
                              row={row}
                              selected={selected.includes(row.id.toString())}
                              onSelectRow={() => onSelectRow(row.id.toString())}
                              onDeleteRow={() =>
                                handleDeleteRow(row.id.toString())
                              }
                              onEditRow={() => handleEditRow(row.id.toString())}
                            />
                          ))}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(
                            page,
                            rowsPerPage,
                            tableData.length
                          )}
                        />

                        <TableNoData isNotFound={isNotFound} />
                      </>
                    )}
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {selected.length > 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong>{' '}
                {selected.length > 4
                  ? translate('item_five_more')
                  : translate('item_two_four')}
                ?
              </>
            )}
            {selected.length === 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong> {translate('item_one')}?
              </>
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  inputData: IProperty[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (property) =>
        property.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  // if (filterStatus !== 'all') {
  //   inputData = inputData.filter((user) => user.status === filterStatus);
  // }
  //
  // if (filterRole !== 'all') {
  //   inputData = inputData.filter((user) => user.role === filterRole);
  // }

  return inputData;
}
