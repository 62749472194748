import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFTextField } from '../../../../components/hook-form';
import { useDispatch } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../../locales';
import { createRequestComment } from 'src/redux/slices/requestComment';

type RequestCommentCreateFormProps = {
  serviceRequestId?: string;
};

type FormValuesProps = {
  content: string;
  serviceRequestId: string;
};

export default function RequestCommentCreateForm({
  serviceRequestId,
}: RequestCommentCreateFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [defaultValues, setDefaultValues] = useState<FormValuesProps>({
    content: '',
    serviceRequestId: serviceRequestId || '',
  });

  const UpdateRequestCommentSchema = Yup.object().shape({
    content: Yup.string()
      .typeError(`${translate('isRequired.content')}`)
      .required(`${translate('isRequired.content')}`),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateRequestCommentSchema),
    values: defaultValues,
  });

  const {
    watch,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(createRequestComment(data));
      reset();
      navigate(
        PATH_DASHBOARD.serviceRequest.comments(
          serviceRequestId ? serviceRequestId : '1'
        )
      );
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
        <Button
          onClick={() =>
            navigate(
              PATH_DASHBOARD.serviceRequest.comments(
                serviceRequestId ? serviceRequestId : '1'
              )
            )
          }
          sx={{ float: 'right' }}
          type="button"
          variant="contained"
        >
          {`${translate('back')}`}
        </Button>
      </Stack>
      <Card sx={{ p: 3 }}>
        <Box rowGap={3} columnGap={2} display="grid">
          <RHFTextField
            name="content"
            label={`${translate('content')}`}
            value={values.content}
            multiline
            minRows={3}
            required
            fullWidth
          />
        </Box>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('pages.serviceRequest.createComment')}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
