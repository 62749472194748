import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// _mock_
// sections
import UserNewEditForm from '../../../sections/@dashboard/user/UserNewEditForm';
import { useEffect } from 'react';
import { useDispatch } from '../../../redux/store';
import { getCustomer } from 'src/redux/slices/customer';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

export default function CustomerEditPage() {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { customer } = useSelector((state: any) => state.customer);

  useEffect(() => {
    dispatch(getCustomer(id ? Number(id) : 1));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title> Property: Edit property | Properio.io</title>
      </Helmet>

      <Container maxWidth={false}>
        <UserNewEditForm isEdit currentUser={customer} />
      </Container>
    </>
  );
}
