import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosAPI';
import { IReminder, IReminderLight, IReminderState } from 'src/@types/reminder';

// ----------------------------------------------------------------------

const initialState: IReminderState = {
  isLoading: true,
  error: null,
  reminders: [],
  reminder: null,
  redirect: null,
};

const slice = createSlice({
  name: 'reminder',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET reminders
    getRemindersSuccess(state, action) {
      state.reminders = action.payload;
      state.isLoading = false;
    },

    // GET reminder
    getReminderSuccess(state, action) {
      state.reminder = action.payload;
      state.isLoading = false;
    },

    // UPDATE reminder
    updateReminderSuccess(state, action) {
      state.reminder = action.payload;
      state.reminders = state.reminders.map((reminder) => {
        if (reminder.id === action.payload.id) {
          return action.payload;
        }
        return reminder;
      });
      state.isLoading = false;
    },

    // CREATE reminder
    createReminderSuccess(state, action) {
      state.reminder = action.payload;
      state.isLoading = false;
    },

    // REMOVE reminder
    removeReminderSuccess(state, action) {
      state.isLoading = false;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.reminders = [];
      state.reminder = null;
      state.redirect = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
function mapToReminder<T>(reminder: any): T {
  return {
    id: reminder?.id ? reminder.id : '',
    name: reminder?.name ? reminder.name : '',
    time: reminder?.time ? reminder.time : '',
    description: reminder?.description ? reminder.description : '',
    status: reminder?.status ? reminder.status : '',
    property: reminder?.property ? reminder.property : '',
    customer: reminder?.customer ? reminder.customer : '',
    room: reminder?.room ? reminder.room : '',
    item: reminder?.item ? reminder.item : '',
    author: reminder?.author ? reminder.author : '',
    assignee: reminder?.assignee ? reminder.assignee : '',
    createdAt: reminder?.createdAt ? reminder.createdAt : '',
    updatedAt: reminder?.updatedAt ? reminder.updatedAt : '',
    type: reminder?.type ? reminder.type : '',
  } as T;
}

const mapReminderAccount = (reminder: any) => {
  return {
    name: reminder?.name,
    description: reminder?.description,
    customerId: reminder?.customerId !== '' ? reminder?.customerId : null,
    propertyId: reminder?.propertyId !== '' ? reminder?.propertyId : null,
    roomId: reminder?.roomId !== '' ? reminder?.roomId : null,
    itemId: reminder?.itemId !== '' ? reminder?.itemId : null,
    assigneeId: reminder?.assigneeId !== '' ? reminder?.assigneeId : null,
    time: reminder?.time,
    type: reminder?.type,
  };
};

// ----------------------------------------------------------------------

export function getReminders() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/reminders');
      const result: IReminder[] = response?.data?.data?.map((reminder: any) =>
        mapToReminder<IReminderLight>(reminder)
      );
      dispatch(slice.actions.getRemindersSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getReminderItems(itemId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`/items/${itemId}/reminders`);
      const result: IReminder[] = response?.data?.data?.map((reminder: any) =>
        mapToReminder<IReminder>(reminder)
      );
      dispatch(slice.actions.getRemindersSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getReminder(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/reminders/' + id);
      const result: IReminder = mapToReminder<IReminder>(response?.data?.data);
      dispatch(slice.actions.getReminderSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateReminder(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        '/reminders/' + id,
        mapReminderAccount(data)
      );
      const result: IReminder = mapToReminder<IReminder>(response?.data?.data);
      dispatch(slice.actions.updateReminderSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createReminder(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/reminders/',
        mapReminderAccount(data)
      );
      const result: IReminder = mapToReminder<IReminder>(response?.data?.data);
      dispatch(slice.actions.createReminderSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeReminder(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/reminders/' + id);
      const result = response?.data;
      dispatch(slice.actions.removeReminderSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('Error deleting reminder: ' + error.message);
    }
  };
}

export function updateToSeenReminder(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/reminders/' + id + '/seen');
      const result = response?.data;
      dispatch(slice.actions.updateReminderSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function updateToSentReminder(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/reminders/' + id + '/send');
      const result = response?.data;
      dispatch(slice.actions.updateReminderSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function resetReminders() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
