import React, { useState } from 'react';
// @mui
import { Button, Checkbox, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
// @types
// components
import ConfirmDialog from '../../../../components/confirm-dialog';
import { ADMIN_MANAGERS_ROLES, SERVICE_REQUEST_STATUS } from 'src/constants';
import { ITask } from 'src/@types/task';
import { useLocales } from 'src/locales';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAuthContext } from 'src/auth/useAuthContext';
import { allowTo } from 'src/utils/permissionHandler';

// ----------------------------------------------------------------------

type Props = {
  row: ITask;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function TaskTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { title, assignee, status, property } = row;

  const [openConfirm, setOpenConfirm] = useState(false);
  const { user } = useAuthContext();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { translate } = useLocales();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const isCustomerUser =
    user?.roles.includes('customer') && user.roles.length === 1;

  return (
    <>
      <TableRow hover selected={selected}>
        {allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell>
        )}

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {isCustomerUser ? (
              <Typography variant="subtitle2">{title}</Typography>
            ) : (
              <Typography
                variant="subtitle2"
                onClick={() => onEditRow()}
                style={{ cursor: 'pointer' }}
              >
                {title}
              </Typography>
            )}
          </Stack>
        </TableCell>

        <TableCell align="left">
          {property ? `${property?.name}` : `${translate('unknown')}`}
        </TableCell>
        <TableCell align="left">
          {assignee
            ? `${assignee?.firstName} ${assignee?.lastName}`
            : `${translate('unknown')}`}
        </TableCell>
        <TableCell align="left">
          {`${translate(
            `${
              SERVICE_REQUEST_STATUS.find(
                (loopedStatus: { value: string; label: string }) =>
                  loopedStatus.value === status.toLocaleLowerCase()
              )?.value
            }`
          )}`}
        </TableCell>
        {!isCustomerUser && (
          <TableCell align="right">
            <Tooltip title={`${translate('edit')}`} sx={{ cursor: 'pointer' }}>
              <EditIcon
                onClick={() => {
                  onEditRow();
                  handleClosePopover();
                }}
              />
            </Tooltip>
            {allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
              <Tooltip
                title={`${translate('delete')}`}
                sx={{ cursor: 'pointer' }}
              >
                <DeleteIcon
                  onClick={() => {
                    handleOpenConfirm();
                    handleClosePopover();
                  }}
                />
              </Tooltip>
            )}
          </TableCell>
        )}
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('infoMessages.deletePromptV2')}`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
