import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useLocales } from '../../../locales';
import RequestCommentEditForm from 'src/sections/@dashboard/requestComment/RequestCommentEditForm';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { dispatch, useSelector } from 'src/redux/store';
import { getRequestComment } from 'src/redux/slices/requestComment';

// ----------------------------------------------------------------------

export default function RequestCommentViewPage() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { requestComment } = useSelector((state) => state.requestComment);
  const { id } = useParams();
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

  useEffect(() => {
    setIsDataFetched(false);
  }, [id]);

  useEffect(() => {
    if (id && !isDataFetched) {
      const fetchRequestComments = async () => {
        try {
          await dispatch(getRequestComment(Number(id)));
          setIsDataFetched(true);
        } catch (error) {
          navigate(PATH_PAGE.page404);
        }
      };
      fetchRequestComments();
    }
  }, [id, isDataFetched, navigate]);

  if (!id) {
    return <Navigate to={PATH_PAGE.page404} replace={true} />;
  }

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.serviceRequest.titleViewComment')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.serviceRequest.viewComment')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('serviceRequest')}`,
              href: PATH_DASHBOARD.serviceRequest.view(
                String(requestComment?.serviceRequest.id)
              ),
            },
            { name: `${translate('pages.serviceRequest.viewComment')}` },
          ]}
        />
        <RequestCommentEditForm
          disabledEdit={true}
          requestComment={requestComment}
        />
      </Container>
    </>
  );
}
