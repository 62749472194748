import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Box, Card, Container, Grid, Tab, Tabs } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useSelector } from 'react-redux';
import { getServiceRequest } from 'src/redux/slices/serviceRequest';
import { useEffect, useState } from 'react';
import { dispatch } from 'src/redux/store';
import ServiceRequestEditForm from 'src/sections/@dashboard/serviceRequest/ServiceRequestNewEditForm';
import Iconify from 'src/components/iconify';
import TaskListPage from '../task/TaskListPage';
import { useLocales } from '../../../locales';
import AttachmentListOfServiceRequestPage from '../attachment/AttachmentListOfServiceRequestPage';
import RequestCommentListPage from '../requestComment/RequestCommentListPage';
import { urlHandler } from 'src/utils/navigateHandler';
import { PROPERTY } from 'src/constants';
import { setLastUrlServiceRequests } from 'src/redux/slices/setting';
import useFlushNavigate from 'src/hooks/useFlushNavigate';

// ----------------------------------------------------------------------

export default function ServiceRequestEditPage() {
  const { id } = useParams();
  const { serviceRequest } = useSelector((state: any) => state.serviceRequest);
  const { lastUrlServiceRequests } = useSelector((state: any) => state.setting);
  const [currentTab, setCurrentTab] = useState<string>(
    `service-request/${id}/general`
  );
  const location = useLocation();
  const { translate } = useLocales();
  const { property } = useSelector((state: any) => state.property);
  const { flushRedux } = useFlushNavigate();

  const tabHandler = (tab: string) => {
    flushRedux();
    setCurrentTab(tab);
  };

  useEffect(() => {
    const newPathname = `/dashboard/${currentTab}`;
    window.history.pushState(null, '', newPathname);
  }, [currentTab]);

  useEffect(() => {
    let tabFromPathname = location.pathname.replace('/dashboard/', '');
    const url = urlHandler(PROPERTY, tabFromPathname);
    if (url.length > 0) dispatch(setLastUrlServiceRequests(url));
    tabFromPathname = tabFromPathname.replace('/property', '');
    tabHandler(tabFromPathname);
  }, [location.pathname]);

  const TABS = [
    {
      value: `service-request/${id}/general`,
      label: `${translate('general')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <ServiceRequestEditForm
          isEdit
          currentRequest={serviceRequest}
          fromUrl={lastUrlServiceRequests}
        />
      ),
    },
    {
      value: `service-request/${id}/tasks`,
      label: `${translate('tasks')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <TaskListPage
          serviceRequestId={id}
          propertyId={property ? property.id : undefined}
        />
      ),
    },
    {
      value: `service-request/${id}/comments`,
      label: `${translate('comments')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <RequestCommentListPage
          serviceRequestId={id}
          propertyId={property ? property.id : undefined}
        />
      ),
    },
    {
      value: `service-request/${id}/attachments`,
      label: `${translate('attachments')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <AttachmentListOfServiceRequestPage
          propertyId={property ? property.id : undefined}
        />
      ),
      roles: [],
    },
  ];

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        dispatch(getServiceRequest(id ? Number(id) : 1));
      };
      fetchData();
    }
  }, [id, dispatch, currentTab]);

  const content = (
    <>
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => tabHandler(newValue)}
      >
        {TABS.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            icon={tab.icon}
            value={tab.value}
          />
        ))}
      </Tabs>

      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box key={tab.value} sx={{ mt: 5 }}>
              {tab.component}
            </Box>
          )
      )}
    </>
  );

  const renderContent = () => {
    if (currentTab === TABS[0].value) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              {content}
            </Card>
          </Grid>
        </Grid>
      );
    }
    return (
      <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
        {content}
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.serviceRequest.titleEdit')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.serviceRequest.editRequest')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('serviceRequests')}`,
              href: PATH_DASHBOARD.general.serviceRequest,
            },
            { name: serviceRequest?.name ?? '' },
          ]}
        />
        {renderContent()}
      </Container>
    </>
  );
}
