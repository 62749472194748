import { useEffect, useState } from 'react';
import { Box, Card, Tab, Tabs } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Container } from '@mui/system';
import ItemTagListPage from '../itemTag/ItemTagListPage';
import ItemCategoryListPage from '../itemCategory/ItemCategoryListPage';
import { useLocation } from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { useLocales } from '../../../locales';
import useFlushNavigate from 'src/hooks/useFlushNavigate';

export default function ConfigutationListPage() {
  const [currentTab, setCurrentTab] = useState('configuration/itemTags');
  const location = useLocation();
  const { translate } = useLocales();
  const { flushRedux } = useFlushNavigate();

  const tabHandler = (tab: string) => {
    flushRedux();
    setCurrentTab(tab);
  };

  const TABS = [
    {
      value: 'configuration/itemTags',
      label: `${translate('itemTags')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: <ItemTagListPage />,
    },
    {
      value: 'configuration/itemCategories',
      label: `${translate('itemCategories')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: <ItemCategoryListPage />,
    },
  ];

  useEffect(() => {
    const newPathname = `/dashboard/${currentTab}`;
    window.history.pushState(null, '', newPathname);
  }, [currentTab]);

  useEffect(() => {
    const tabFromPathname = location.pathname.replace('/dashboard/', '');
    tabHandler(tabFromPathname);
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.configuration.title')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <Card sx={{ padding: '16px 16px 0 24px', marginBottom: '40px' }}>
          <Tabs
            value={currentTab}
            onChange={(event, newValue) => tabHandler(newValue)}
            sx={{
              width: 1,
              zIndex: 9,
              marginBottom: 5,
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                icon={tab.icon}
                label={tab.label}
              />
            ))}
          </Tabs>
        </Card>
        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value}>{tab.component}</Box>
            )
        )}
      </Container>
    </>
  );
}
