import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { IUserOriginal } from '../../../@types/user';
// _mock_
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable
} from '../../../components/table';
// sections
import { getCustomerUsers, getUsers, removeUser } from 'src/redux/slices/user';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import UserOriginalTableRow from 'src/sections/@dashboard/userOriginal/list/UserOriginalTableRow';
import { useSnackbar } from 'notistack';
import { useLocales } from '../../../locales';
import UserOriginalToolbar from 'src/sections/@dashboard/userOriginal/list/UserOriginalTableToolbar';
import { useAuthContext } from 'src/auth/useAuthContext';
import { ONLY_ADMIN_COMPANY_MANAGER_ROLES, ROLES_PERMISSIONS } from 'src/constants';
import useDeleteRowsHandler from 'src/hooks/useDeleteHandler';
import { allowTo } from 'src/utils/permissionHandler';
import { getPageFromStorage, savePageToStorage } from 'src/utils/pageStorageHandler';
import { getRowsPerPageFromStorage, saveRowsPerPageToStorage } from 'src/utils/rowsPerPageStorageHandler';

interface IUserListPageProps {
  customerId?: number;
}

export default function UserOriginalListPage({
  customerId,
}: IUserListPageProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tableData, setTableData] = useState<IUserOriginal[]>([]);
  const { users, isLoading } = useSelector((state: any) => state.user);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const { translate } = useLocales();
  const { deleteHandlers } = useDeleteRowsHandler();

  const tableHead = [
    { id: 'lastName', label: `${translate('lastName')}`, align: 'left' },
    { id: 'firstName', label: `${translate('firstName')}`, align: 'left' },
    { id: 'email', label: `${translate('email')}`, align: 'left' },
    { id: 'roles', label: `${translate('role')}`, align: 'left' },
    { id: 'actions', label: `${translate('actions')}`, align: 'right' },
  ];

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 52 : 72;

  const isFiltered =
    filterName !== '' || filterRole !== 'all' || filterStatus !== 'all';

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await dispatch(removeUser(Number(id)));
      await dispatch(getUsers());
      const deleteRow = tableData.filter(
        (row) => Number(row.id) !== Number(id)
      );
      setSelected([]);
      setTableData(deleteRow);

      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    try {
      if (await deleteHandlers.deleteRows(selectedRows, removeUser)) {
        return;
      }

      const filteredRows = tableData.filter(
        (row: any) => !selectedRows.includes(row.id.toString())
      );
      setSelected([]);
      setTableData(filteredRows);

      if (page > 0) {
        if (selectedRows.length === dataInPage.length) {
          setPage(page - 1);
        } else if (selectedRows.length === dataFiltered.length) {
          setPage(0);
        } else if (selectedRows.length > dataInPage.length) {
          const newPage =
            Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
            1;
          setPage(newPage);
        }
      }

      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleEditRow = (id: string) => {
    savePageToStorage('user', page);
    saveRowsPerPageToStorage('user', rowsPerPage);
    navigate(PATH_DASHBOARD.user.edit(paramCase(id)));
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterRole('all');
    setFilterStatus('all');
  };

  useEffect(() => {
    const fetchData = async () => {
      if (customerId) {
        await dispatch(getCustomerUsers(customerId));
      } else {
        await dispatch(getUsers());
      }
    };
    fetchData();
  }, [dispatch, customerId]);

  useEffect(() => {
    if (users.length && user?.roles.includes(ROLES_PERMISSIONS.customer)) {
      setTableData(
        users.filter(
          (loopedUser: IUserOriginal) =>
            user?.customerId === loopedUser?.customerId
        )
      );
    } else {
      setTableData(users);
    }
  }, [users]);

  useEffect(() => {
    const savedPage = getPageFromStorage('user');
    setPage(savedPage ?? 0);
    const savedRowsPerPage = getRowsPerPageFromStorage('user');
    setRowsPerPage(savedRowsPerPage ?? 5);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.user.title')}`}</title>
      </Helmet>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth={false}>
          {customerId ? (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5 }}>
              {!user?.roles.includes(ROLES_PERMISSIONS.customer) && (
                <>
                  <Button
                    component={RouterLink}
                    to={
                      customerId
                        ? PATH_DASHBOARD.user.createUserFromCustomer(
                            customerId.toString()
                          )
                        : PATH_DASHBOARD.user.create
                    }
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    {`${translate('pages.user.newUser')}`}
                  </Button>
                  <Button
                    onClick={() => navigate(PATH_DASHBOARD.general.customers)}
                    sx={{ float: 'right', marginLeft: '13px' }}
                    type="button"
                    variant="contained"
                  >
                    {`${translate('back')}`}
                  </Button>
                </>
              )}
            </Box>
          ) : (
            <CustomBreadcrumbs
              heading={`${translate('pages.user.heading')}`}
              links={[
                {
                  name: `${translate('dashboard')}`,
                  href: PATH_DASHBOARD.root,
                },
                {
                  name: `${translate('users')}`,
                  href: PATH_DASHBOARD.user.root,
                },
                { name: `${translate('pages.user.heading')}` },
              ]}
              action={
                !user?.roles.includes(ROLES_PERMISSIONS.customer) && (
                  <Button
                    component={RouterLink}
                    to={
                      customerId
                        ? PATH_DASHBOARD.user.createUserFromCustomer(
                            customerId.toString()
                          )
                        : PATH_DASHBOARD.user.create
                    }
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    {`${translate('pages.user.newUser')}`}
                  </Button>
                )
              }
            />
          )}

          <Card>
            <Divider />

            <UserOriginalToolbar
              isFiltered={isFiltered}
              filterName={filterName}
              onFilterName={handleFilterName}
              onResetFilter={handleResetFilter}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id.toString())
                  )
                }
                action={
                  <Tooltip title={`${translate('delete')}`}>
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHead}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={
                      !allowTo(
                        user ? user.roles : [],
                        ONLY_ADMIN_COMPANY_MANAGER_ROLES
                      )
                        ? undefined
                        : (checked) =>
                            onSelectAllRows(
                              checked,
                              tableData.map((row) => row.id.toString())
                            )
                    }
                  />

                  <TableBody>
                    <>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <UserOriginalTableRow
                            key={row.id}
                            row={row}
                            selected={selected.includes(row.id.toString())}
                            onSelectRow={() => onSelectRow(row.id.toString())}
                            onDeleteRow={() =>
                              handleDeleteRow(row.id.toString())
                            }
                            onEditRow={() => handleEditRow(row.id.toString())}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          tableData.length
                        )}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </>
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {selected.length > 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong>{' '}
                {selected.length > 4
                  ? translate('item_five_more')
                  : translate('item_two_four')}
                ?
              </>
            )}
            {selected.length === 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong> {translate('item_one')}?
              </>
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  inputData: IUserOriginal[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);
  if (filterName) {
    inputData = inputData.filter(
      (user) =>
        user.firstName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        user.lastName.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  // if (filterStatus !== 'all') {
  //   inputData = inputData.filter(user => user.firstName === filterStatus)
  // }

  // if (filterRole !== 'all') {
  //   inputData = inputData.filter(user => user.firstName === filterRole)
  // }

  return inputData;
}
