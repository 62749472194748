import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import ReminderNewEditForm from 'src/sections/@dashboard/reminder/ReminderNewEditForm';
import { getReminder } from 'src/redux/slices/reminder';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from 'src/redux/store';
import { useLocales } from '../../../locales';
import { urlHandler } from 'src/utils/navigateHandler';
import { ITEM } from 'src/constants';
import { setLastUrlReminder } from 'src/redux/slices/setting';

export default function ReminderEditPage() {
  const { translate } = useLocales();
  const location = useLocation();
  const { lastUrlReminder } = useSelector((state: any) => state.setting);
  const { id } = useParams();

  const { reminder } = useSelector((state: any) => state.reminder);

  useEffect(() => {
    const url = urlHandler(ITEM, location.pathname);
    if (url.length > 0) dispatch(setLastUrlReminder(url));
    dispatch(getReminder(id ? Number(id) : 1));
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.reminder.titleEdit')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.reminder.editReminder')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('reminders')}`,
              href: PATH_DASHBOARD.general.reminder,
            },
            { name: `${translate('pages.reminder.editReminder')}` },
          ]}
        />

        <ReminderNewEditForm reminder={reminder} fromUrl={lastUrlReminder} />
      </Container>
    </>
  );
}
