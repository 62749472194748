// ----------------------------------------------------------------------
function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_REDIRECTS = '/redirects';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  forgottenPassword: path(ROOTS_AUTH, '/forgotten-password'),
  resetPassword: (token: string) =>
    path(ROOTS_AUTH, `/password-reset/${token}`),
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
  redirects: (uuid: string) => path(ROOTS_REDIRECTS, `${uuid}`),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    properties: path(ROOTS_DASHBOARD, '/property/list'),
    customers: path(ROOTS_DASHBOARD, '/customer/list'),
    items: path(ROOTS_DASHBOARD, '/item/list'),
    serviceRequest: path(ROOTS_DASHBOARD, '/service-request/list'),
    task: path(ROOTS_DASHBOARD, '/task/list'),
    reminder: path(ROOTS_DASHBOARD, '/reminder/list'),
    configuration: path(ROOTS_DASHBOARD, '/configuration/itemTags'),
    user: path(ROOTS_DASHBOARD, '/user/list'),

    app: path(ROOTS_DASHBOARD, '/app'),
    file: path(ROOTS_DASHBOARD, '/file'),
    scanQr: path(ROOTS_DASHBOARD, '/scan-qr'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user/list'),
    create: path(ROOTS_DASHBOARD, '/user/create'),
    profile: path(ROOTS_DASHBOARD, '/profile'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    createUserFromCustomer: (customerId: string) =>
      path(ROOTS_DASHBOARD, `/customer/${customerId}/user/create`),
  },
  room: {
    view: (propertyId: string, roomId: string) =>
      path(ROOTS_DASHBOARD, `/property/${propertyId}/room/${roomId}/general`),
    back: (id: string) => path(ROOTS_DASHBOARD, `/property/${id}/rooms`),
    create: (propertyId: string) =>
      path(ROOTS_DASHBOARD, `/property/${propertyId}/room/create`),
    items: (id: string, propertyId: string) =>
      path(ROOTS_DASHBOARD, `/property/${propertyId}/room/${id}/items`),
    tasks: (id: string, propertyId: string) =>
      path(ROOTS_DASHBOARD, `/property/${propertyId}/room/${id}/tasks`),
    qrCode: (id: string, propertyId: string) =>
      path(ROOTS_DASHBOARD, `/room/${propertyId}/edit/${id}/qr-code`),
  },
  serviceRequest: {
    create: path(ROOTS_DASHBOARD, '/service-request/create'),
    view: (id: string) =>
      path(ROOTS_DASHBOARD, `/service-request/${id}/general`),
    createServiceRequestFromProperty: (propertyId: string) =>
      path(ROOTS_DASHBOARD, `/property/${propertyId}/create/service-request`),
    createComment: (id: string) =>
      path(ROOTS_DASHBOARD, `/service-request/${id}/comments/create`),
    comments: (id: string) =>
      path(ROOTS_DASHBOARD, `/service-request/${id}/comments`),
    attachments: (id: string) =>
      path(ROOTS_DASHBOARD, `/service-request/${id}/attachments`),
    editComment: (id: string, requestCommentId: string) =>
      path(
        ROOTS_DASHBOARD,
        `/service-request/${id}/comments/${requestCommentId}/edit`
      ),
    tasks: (id: string) =>
      path(ROOTS_DASHBOARD, `/service-request/${id}/tasks`),
  },
  comment: {
    view: (id: string) => path(ROOTS_DASHBOARD, `/comments/${id}`),
  },
  reminder: {
    create: path(ROOTS_DASHBOARD, '/reminder/create'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/reminder/${id}/general`),
    createReminderFromItem: (itemId: string) =>
      path(ROOTS_DASHBOARD, `/item/${itemId}/reminders/create`),
    attachments: (id: string) =>
      path(ROOTS_DASHBOARD, `/reminder/${id}/attachments`),
  },
  configuration: {
    itemCategoryList: path(ROOTS_DASHBOARD, '/configuration/itemCategories'),
    itemTagList: path(ROOTS_DASHBOARD, '/configuration/itemTags'),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/configuration/${id}/edit`),
  },
  task: {
    create: path(ROOTS_DASHBOARD, '/task/create'),
    createTaskFromProperty: (propertyId: string) =>
      path(ROOTS_DASHBOARD, `/property/${propertyId}/task/create`),
    createTaskFromServiceRequest: (id: string) =>
      path(ROOTS_DASHBOARD, `/service-request/${id}/task/create`),
    createRoomTask: (id: string, propertyId: string) =>
      path(ROOTS_DASHBOARD, `/task/${propertyId}/create/${id}`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/task/${id}/general`),
  },
  itemTag: {
    root: path(ROOTS_DASHBOARD, '/itemTags'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/itemTag/${id}/general`),
  },
  itemCategory: {
    root: path(ROOTS_DASHBOARD, '/itemCategories'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/itemCategory/${id}/general`),
  },
  customer: {
    create: path(ROOTS_DASHBOARD, '/customer/create'),
    list: path(ROOTS_DASHBOARD, '/customer/list'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/customer/${id}/general`),
    properties: (id: string) =>
      path(ROOTS_DASHBOARD, `/customer/${id}/properties`),
    users: (id: string) => path(ROOTS_DASHBOARD, `/customer/${id}/users`),
  },
  property: {
    create: path(ROOTS_DASHBOARD, '/property/create'),
    tasks: (id: string) => path(ROOTS_DASHBOARD, `/property/${id}/tasks`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/property/${id}/general`),
    customer: (id: string) =>
      path(ROOTS_DASHBOARD, `/property/${id}/general/customer`),
    rooms: (id: string) => path(ROOTS_DASHBOARD, `/property/${id}/rooms`),
    items: (id: string) => path(ROOTS_DASHBOARD, `/property/${id}/items`),
    serviceRequests: (id: string) =>
      path(ROOTS_DASHBOARD, `/property/${id}/service-requests`),
    createPropertyFromCustomer: (customerId: string) =>
      path(ROOTS_DASHBOARD, `/customer/${customerId}/property/create`),
    qrCode: (id: string) => path(ROOTS_DASHBOARD, `/property/${id}/qr-code`),
  },
  item: {
    create: path(ROOTS_DASHBOARD, '/item/create'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/item/${id}/general`),
    reminders: (id: string) => path(ROOTS_DASHBOARD, `/item/${id}/reminders`),
    createItemFromProperty: (propertyId: string) =>
      path(ROOTS_DASHBOARD, `/property/${propertyId}/item/create`),
    createItemFromRoom: (propertyId: string, roomId: string) =>
      path(
        ROOTS_DASHBOARD,
        `/property/${propertyId}/room/${roomId}/item/create`
      ),
    createItemLog: (id: string) =>
      path(ROOTS_DASHBOARD, `/item/${id}/item-log/create`),
    attachments: (itemId: string) =>
      path(ROOTS_DASHBOARD, `/item/${itemId}/attachments`),
    itemLogs: (id: string) => path(ROOTS_DASHBOARD, `/item/${id}/item-logs`),
    viewItemLog: (itemId: string, itemLogId: string) =>
      path(ROOTS_DASHBOARD, `/item/${itemId}/item-logs/${itemLogId}/general`),
    itemLogsAttachments: (itemId: string, itemLogId: string) =>
      path(
        ROOTS_DASHBOARD,
        `/item/${itemId}/item-logs/${itemLogId}/attachments`
      ),
    qrCode: (id: string) => path(ROOTS_DASHBOARD, `/item/${id}/qr-code`),
  },
  attachment: {
    createItemLogAttachment: (itemId: string, itemLogId: string) =>
      path(
        ROOTS_DASHBOARD,
        `/item/${itemId}/item-logs/${itemLogId}/attachments/create`
      ),
    createItemAttachment: (itemId: string) =>
      path(ROOTS_DASHBOARD, `/item/${itemId}/attachments/create`),
    createReminderAttachment: (reminderId: string) =>
      path(ROOTS_DASHBOARD, `/reminder/${reminderId}/attachments/create`),
    createServiceRequestAttachment: (serviceRequestId: string) =>
      path(
        ROOTS_DASHBOARD,
        `/service-request/${serviceRequestId}/attachments/create`
      ),
    editServiceRequestAttachment: (
      serviceRequestId: string,
      attachmentId: string
    ) =>
      path(
        ROOTS_DASHBOARD,
        `/service-request/${serviceRequestId}/attachments/${attachmentId}/edit`
      ),
    editReminderAttachment: (reminderId: string, attachmentId: string) =>
      path(
        ROOTS_DASHBOARD,
        `/reminder/${reminderId}/attachments/${attachmentId}/edit`
      ),
    editItemLogAttachment: (
      itemId: string,
      itemLogId: string,
      attachmentId: string
    ) =>
      path(
        ROOTS_DASHBOARD,
        `/item/${itemId}/item-logs/${itemLogId}/attachments/${attachmentId}/edit`
      ),
    editItemAttachment: (itemId: string, attachmentId: string) =>
      path(ROOTS_DASHBOARD, `/item/${itemId}/attachments/${attachmentId}/edit`),
    viewAttachment: (id: string) => path(ROOTS_DASHBOARD, `/attachments/${id}`),
  },
};
