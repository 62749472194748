export function convertDateTime(
  dateTime: string | number | undefined
): null | string {
  if (dateTime === undefined) {
    return null;
  }
  const originalDateTime = new Date(dateTime);
  const year = originalDateTime.getFullYear();
  const month = String(originalDateTime.getMonth() + 1).padStart(2, '0');
  const day = String(originalDateTime.getDate()).padStart(2, '0');
  const hour = String(originalDateTime.getHours()).padStart(2, '0');
  const minute = String(originalDateTime.getMinutes()).padStart(2, '0');

  const formattedDateTime = `${day}.${month}.${year} ${hour}:${minute}`;
  return formattedDateTime;
}
