import { createSlice } from '@reduxjs/toolkit';
import {
  ENotificationStatus,
  INotification,
  INotificationState,
} from 'src/@types/notification';
import axios from '../../utils/axiosAPI';

const initialState: INotificationState = {
  isLoading: false,
  error: null,
  notifications: [],
  notification: null,
  totalUnRead: 0,
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.error = action.payload;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
      state.totalUnRead = action.payload.filter(
        (notification: INotification) =>
          notification.status === ENotificationStatus.CREATED
      ).length;
    },

    readNotificationSuccess(state, action) {
      state.isLoading = false;
      state.notifications = state.notifications.map(
        (notification: INotification) => {
          if (notification.id === action.payload.id) {
            return action.payload;
          }
          return notification;
        }
      );
      state.totalUnRead = state.notifications.filter(
        (notification) => notification.status === ENotificationStatus.CREATED
      ).length;
    },

    // getNotificationSuccess(state, action) {
    //   state.isLoading = false;
    //   state.notification = action.payload;
    // },

    // updateNotificationSuccess(state, action) {
    //   state.isLoading = false;
    //   state.notification = action.payload;
    // },

    // // CREATE Notification
    // createNotificationSuccess(state, action) {
    //   state.isLoading = false;
    //   state.notification = action.payload;
    // },

    // // REMOVE Notification
    // removeNotificationSuccess(state, action) {
    //   state.isLoading = false;
    // },

    // // SET Notification TO NULL
    // setNotificationToNull(state) {
    //   state.notification = null;
    // }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
function mapToNotification<T>(notification: any): T {
  return {
    id: notification?.id ? notification.id : '',
    title: notification?.title ? notification.title : '',
    entityType: notification?.entityType ? notification.entityType : '',
    entityId: notification?.entityId ? notification.entityId : '',
    userId: notification?.userId ? notification.userId : '',
    message: notification?.message ? notification.message : '',
    status: notification?.status ? notification.status : '',
    readAt: notification?.readAt ? notification.readAt : '',
    created: notification?.created ? notification.created : '',
    updated: notification?.updated ? notification.updated : '',
  } as T;
}

export function readNotification(id: number) {
  return async (dispatch: any) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.get(`/badge-notification/${id}/read`);
      const result = mapToNotification<INotification>(response?.data);
      dispatch(slice.actions.readNotificationSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotifications() {
  return async (dispatch: any) => {
    try {
      dispatch(slice.actions.startLoading());
      const response = await axios.get('/badge-notification/user');
      const result = response?.data?.map((notification: any) =>
        mapToNotification<INotification>(notification)
      );

      dispatch(slice.actions.getNotificationsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
