import { useState } from 'react';
// @mui
import {
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
} from '@mui/material';
// components
import ConfirmDialog from '../../../../components/confirm-dialog';
import React from 'react';
import { ADMIN_MANAGERS_ROLES } from 'src/constants';
import { IRoom } from 'src/@types/room';
import { useLocales } from 'src/locales';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAuthContext } from 'src/auth/useAuthContext';
import { allowTo } from 'src/utils/permissionHandler';

// ----------------------------------------------------------------------

type Props = {
  row: IRoom;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function RoomTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, property, parentRoom, subRooms } = row;
  const { user } = useAuthContext();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { translate } = useLocales();

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        {allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell>
        )}

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/*<Avatar alt={name} src={avatarUrl} />*/}

            <Typography
              variant="subtitle2"
              onClick={() => onEditRow()}
              style={{ cursor: 'pointer' }}
              noWrap
            >
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">{property.name}</TableCell>
        <TableCell align="left">{parentRoom?.name}</TableCell>
        <TableCell align="left">
          {subRooms
            ? subRooms.map((room: any, index: number) => {
                const fullName = `${room?.name}`;
                const isLastItem = index === subRooms.length - 1;
                return isLastItem ? fullName : `${fullName}, `;
              })
            : `${translate('unknown')}`}
        </TableCell>

        <TableCell align="right">
          <Tooltip title={`${translate('edit')}`} sx={{ cursor: 'pointer' }}>
            <EditIcon
              onClick={() => {
                onEditRow();
                handleClosePopover();
              }}
            />
          </Tooltip>
          {allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
            <Tooltip
              title={`${translate('delete')}`}
              sx={{ cursor: 'pointer' }}
            >
              <DeleteIcon
                onClick={() => {
                  handleOpenConfirm();
                  handleClosePopover();
                }}
              />
            </Tooltip>
          )}
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('infoMessages.deletePrompt')}`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
