import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { CREATED, DONE, IN_PROGRESS, LOW, PROPERTY, ROLES_PERMISSIONS, SERVICE_REQUEST_PRIORITY } from 'src/constants';
import { dispatch, useSelector } from 'src/redux/store';
import { IServiceRequest } from 'src/@types/serviceRequest';
import { getCustomerByUserCustomerRole, getCustomers } from 'src/redux/slices/customer';
import { getProperties } from 'src/redux/slices/property';
import {
  updateServiceRequest,
  updateToDoneServiceRequest,
  updateToProgressServiceRequest
} from 'src/redux/slices/serviceRequest';
import { useLocales } from '../../../locales';
import { useAuthContext } from 'src/auth/useAuthContext';
import { getPropertyItems } from 'src/redux/slices/item';
import { IItem } from 'src/@types/item';

// ----------------------------------------------------------------------

type FormValuesProps = {
  propertyId: number | string | undefined;
  customerId: number | string | undefined;
  name: string;
  description: string;
  priority: string;
  status: string | undefined;
  itemId: number | string | undefined;
};

type Props = {
  isEdit?: boolean;
  currentRequest?: IServiceRequest;
  fromUrl: string;
};

export default function ServiceRequestNewEditForm({
  currentRequest,
  fromUrl = '',
}: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const [editing, setEditing] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { customers, customer } = useSelector((state) => state.customer);
  const { properties } = useSelector((state) => state.property);
  const { items } = useSelector((state) => state.item);
  const { user } = useAuthContext();

  const UpdatePropertySchema = Yup.object().shape({
    description: Yup.string().required(
      `${translate('isRequired.description')}`
    ),
    name: Yup.string().required(`${translate('isRequired.name')}`),
    customerId: Yup.number()
      .typeError(`${translate('isRequired.customer')}`)
      .required(`${translate('isRequired.customer')}`),
    propertyId: Yup.number()
      .typeError(`${translate('isRequired.property')}`)
      .required(`${translate('isRequired.property')}`),
    priority: Yup.string().required('isRequired.priority'),
    itemId: Yup.string().optional(),
  });

  const isCustomer =
    user?.roles.includes(ROLES_PERMISSIONS.customer) && user.roles.length === 1;

  const defaultValues = {
    name: currentRequest?.name || '',
    description: currentRequest?.description || '',
    customerId: Number(currentRequest?.customer?.id) || '',
    propertyId: Number(currentRequest?.property?.id) || '',
    priority: currentRequest?.priority || LOW,
    status: currentRequest?.status || CREATED,
    itemId: currentRequest?.itemId || '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(updateServiceRequest(Number(currentRequest?.id), data));
      reset();
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      handlerNavigate();
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  const updateStatusToProgress = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      await dispatch(
        updateToProgressServiceRequest(Number(currentRequest?.id))
      );
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      handlerNavigate();
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  const updateStatusToDone = async () => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      reset();
      await dispatch(updateToDoneServiceRequest(Number(currentRequest?.id)));
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      handlerNavigate();
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  const handlerNavigate = () => {
    switch (fromUrl) {
      case PROPERTY:
        navigate(
          PATH_DASHBOARD.property.serviceRequests(
            values.propertyId?.toString() || ''
          )
        );
        break;
      default:
        navigate(PATH_DASHBOARD.general.serviceRequest);
        break;
    }
  };

  const renderStatusButton = () => {
    if (user?.roles.includes('customer') && user.roles.length === 1) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {currentRequest?.status === CREATED && (
            <Button disabled variant="contained">
              {`${translate('created')}`}
            </Button>
          )}
          {currentRequest?.status === IN_PROGRESS && (
            <Button onClick={updateStatusToDone} variant="contained">
              {`${translate('done')}`}
            </Button>
          )}
          {currentRequest?.status === DONE && (
            <Button disabled variant="contained">
              {`${translate('done')}`}
            </Button>
          )}
        </Box>
      );
    }
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {currentRequest?.status === CREATED && (
          <Button onClick={updateStatusToProgress} variant="contained">
            {`${translate('inProgress')}`}
          </Button>
        )}
        {currentRequest?.status === IN_PROGRESS && (
          <Button onClick={updateStatusToDone} variant="contained">
            {`${translate('done')}`}
          </Button>
        )}
      </Box>
    );
  };

  const createOptionsForSelect = () => {
    if (isCustomer) {
      return <option>{customer?.name}</option>;
    }
    return (
      <>
        {customers.map((customer) => (
          <option key={customer.id} value={customer.id}>
            {customer.name}
          </option>
        ))}
      </>
    );
  };

  const propertyHandler = async (propertyId: string) => {
    setValue('propertyId', propertyId);
    await dispatch(getPropertyItems(Number(propertyId)));
  };

  const handleEdit = (_isEditing: boolean) => {
    setEditing(_isEditing);
    reset();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isCustomer) {
        await Promise.all([dispatch(getCustomerByUserCustomerRole())]);
      }
      if (currentRequest?.property) {
        await dispatch(getPropertyItems(Number(currentRequest.property?.id)));
      }
      await Promise.all([dispatch(getProperties()), dispatch(getCustomers())]);
    };
    fetchData();
  }, [currentRequest]);

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 5 }}>
          <Button
            onClick={handlerNavigate}
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          {renderStatusButton()}
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('name')}`}
              value={values.name}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
            <RHFSelect
              native
              name="customerId"
              label={`${translate('customer')}`}
              placeholder={`${translate('customer')}`}
              value={values.customerId}
              disabled={!editing || isCustomer}
              variant={!editing ? 'standard' : 'outlined'}
              required
            >
              {createOptionsForSelect()}
            </RHFSelect>
            <RHFSelect
              native
              name="propertyId"
              label={`${translate('property')}`}
              placeholder={`${translate('property')}`}
              value={values?.propertyId}
              disabled={!editing}
              onChange={(e) => propertyHandler(e.target.value)}
              variant={!editing ? 'standard' : 'outlined'}
              required
            >
              {properties.map((property) => (
                <option key={property.id} value={property.id}>
                  {property.name}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect
              native
              name="priority"
              label={`${translate('pages.serviceRequest.priority')}`}
              placeholder={`${translate('pages.serviceRequest.priority')}`}
              value={values.priority}
              disabled={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            >
              {SERVICE_REQUEST_PRIORITY.map((priority) => (
                <option key={priority.value} value={priority.value}>
                  {`${translate(`${priority.value}`)}`}
                </option>
              ))}
            </RHFSelect>
            <RHFTextField
              name="description"
              label={`${translate('description')}`}
              value={values.description}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              multiline
              minRows={3}
              required
            />
            <RHFSelect
              native
              name="itemId"
              label={`${translate('item')}`}
              placeholder={`${translate('item')}`}
              variant={!editing ? 'standard' : 'outlined'}
              readOnly={!editing}
              disabled={(values.propertyId ? false : true) || !editing}
              value={values.itemId}
            >
              {<option key={''} value=""></option>}
              {items.map((item: IItem) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </RHFSelect>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            {editing && (
              <>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {`${translate('saveChanges')}`}
                </LoadingButton>
                <Button
                  onClick={() => handleEdit(false)}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  {`${translate('close')}`}
                </Button>
              </>
            )}
            {!editing && (
              <Button
                onClick={() => setEditing(true)}
                sx={{ ml: 1 }}
                variant="contained"
              >
                {`${translate('edit')}`}
              </Button>
            )}
          </Box>
        </Card>
      </FormProvider>
    </Container>
  );
}
