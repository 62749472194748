import { ElementType, lazy, Suspense } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(
  lazy(() => import('../pages/auth/LoginPage'))
);
export const RegisterPage = Loadable(
  lazy(() => import('../pages/auth/RegisterPage'))
);
export const VerifyCodePage = Loadable(
  lazy(() => import('../pages/auth/VerifyCodePage'))
);
export const NewPasswordPage = Loadable(
  lazy(() => import('../pages/auth/NewPasswordPage'))
);
export const ResetPasswordPage = Loadable(
  lazy(() => import('../pages/auth/ResetPasswordPage'))
);

// DASHBOARD: USER
export const UserProfilePage = Loadable(
  lazy(() => import('../pages/dashboard/UserProfilePage'))
);
export const UserOriginalListPage = Loadable(
  lazy(() => import('../pages/dashboard/userOriginal/UserOriginalListPage'))
);
export const UserOriginalCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/userOriginal/UserOriginalCreatePage'))
);
export const UserOriginalEditPage = Loadable(
  lazy(() => import('../pages/dashboard/userOriginal/UserOriginalEditPage'))
);

// DASHBOARD: Properties
export const PropertyListPage = Loadable(
  lazy(() => import('../pages/dashboard/property/PropertyListPage'))
);
export const PropertyCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/property/PropertyCreatePage'))
);

// DASHBOARD: Task
export const TaskListPage = Loadable(
  lazy(() => import('../pages/dashboard/task/TaskListPage'))
);
export const TaskCreatePage = Loadable(
  lazy(() => import('../pages/dashboard/task/TaskCreatePage'))
);
export const TaskEditPage = Loadable(
  lazy(() => import('../pages/dashboard/task/TaskEditPage'))
);

// MAIN
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
