import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getProperty } from 'src/redux/slices/property';
import { useNavigate } from 'react-router-dom';
import { createRoom, getPropertyRooms } from 'src/redux/slices/room';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../../locales';

// ----------------------------------------------------------------------

type FormValuesProps = {
  propertyId: number | string;
  name: string;
  parentRoomId: number | string;
};

type RoomCreateFormProps = {
  propertyId?: number | string;
};

export default function RoomCreateForm({ propertyId }: RoomCreateFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();

  const [isDataFetched, setIsDataFetched] = useState(false);
  const { rooms } = useSelector((state) => state.room);
  const { property } = useSelector((state) => state.property);

  useEffect(() => {
    if (!isDataFetched) {
      Promise.all([
        dispatch(getPropertyRooms(Number(propertyId))),
        dispatch(getProperty(Number(propertyId))),
      ]).then(() => {
        setIsDataFetched(true);
      });
    }
  }, [dispatch, isDataFetched]);

  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    propertyId: Yup.number()
      .typeError(`${translate('isRequired.property')}`)
      .required(`${translate('property')}`),
  });

  const defaultValues = {
    name: '',
    propertyId: propertyId || '',
    parentRoomId: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(createRoom(data));
      reset();
      navigate(PATH_DASHBOARD.property.rooms(propertyId?.toString() || ''));
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  // @TODO: remove if this won't be used in future
  // const handleDrop = useCallback(
  //   (acceptedFiles: File[]) => {
  //     const file = acceptedFiles[0]

  //     const newFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file)
  //     })

  // if (file) {
  //   setValue('photoURL', newFile, { shouldValidate: true });
  // }
  // },
  // [setValue]
  // )

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
        <Button
          onClick={() =>
            navigate(
              PATH_DASHBOARD.property.rooms(propertyId?.toString() || '')
            )
          }
          sx={{ float: 'right' }}
          type="button"
          variant="contained"
        >
          {`${translate('back')}`}
        </Button>
      </Stack>
      <Card sx={{ p: 3 }}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField
            name="name"
            label={`${translate('name')}`}
            value={values.name}
            required
          />
          <RHFSelect
            native
            name="propertyId"
            label={`${translate('property')}`}
            placeholder={`${translate('property')}`}
            value={values.propertyId}
            disabled={propertyId ? true : false}
            required
          >
            <option key={propertyId} value={propertyId}>
              {property?.name}
            </option>
          </RHFSelect>
          <RHFSelect
            native
            name="parentRoomId"
            label={`${translate('parentRoom')}`}
            placeholder={`${translate('parentRoom')}`}
            value={values.parentRoomId}
          >
            <option key={''} value=""></option>
            {rooms?.map((room) => (
              <option key={room.id} value={room.id}>
                {room.name}
              </option>
            ))}
          </RHFSelect>
        </Box>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          {/*<RHFTextField name="about" multiline rows={4} label="About" />*/}

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('pages.room.createRoom')}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
