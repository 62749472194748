import axios from 'axios';
import { HOST_API_KEY_BE } from '../config-global';
import localStorageAvailable from './localStorageAvailable';
import { permissionUrlHandler } from './permissionHandler';

const getToken = () => {
  const storageAvailable = localStorageAvailable();
  const accessToken = storageAvailable
    ? localStorage.getItem('accessToken')
    : '';
  return accessToken;
};

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY_BE });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    permissionUrlHandler(error.response.status);
    throw error;
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    // Assuming `getToken` is a function that retrieves the current token
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    throw error;
  }
);

export default axiosInstance;
