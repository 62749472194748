import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import CustomerCreateForm from 'src/sections/@dashboard/customer/create/CustomerCreateForm';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function CustomerCreatePage() {
  const { translate } = useLocales();

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.customer.createNewCustomerTitle')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.customer.createNewCustomerHeading')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('customers')}`,
              href: PATH_DASHBOARD.general.customers,
            },
            { name: `${translate('pages.customer.newCustomer')}` },
          ]}
        />
        {/*<UserNewEditForm />*/}
        <CustomerCreateForm />
      </Container>
    </>
  );
}
