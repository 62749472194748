import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { useDispatch, useSelector } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { getItem } from 'src/redux/slices/item';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import { useLocales } from '../../../../locales';
import { createItemLog } from 'src/redux/slices/itemLog';

// ----------------------------------------------------------------------

type FormValuesProps = {
  name: string;
  content: string;
  type: string;
  itemId: string | number;
};

type ItemLogCreateFormProps = {
  itemId: number;
};

export default function ItemLogCreateForm({ itemId }: ItemLogCreateFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { item } = useSelector((state) => state.item);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const typesOfLogs = [
    `${translate('pages.itemLog.technicalCheck')}`,
    `${translate('pages.itemLog.generalInfo')}`,
  ];

  useEffect(() => {
    if (!isDataFetched) {
      dispatch(getItem(itemId));
      setIsDataFetched(true);
    }
  }, [dispatch, isDataFetched]);

  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    content: Yup.string().required(`${translate('isRequired.content')}`),
    type: Yup.string(),
  });

  const defaultValues = {
    name: '',
    content: '',
    type: '',
    itemId: itemId || '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(createItemLog(itemId, data));
      navigate(PATH_DASHBOARD.item.itemLogs(itemId.toString()));
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
        <Button
          onClick={() =>
            navigate(PATH_DASHBOARD.item.itemLogs(itemId.toString()))
          }
          sx={{ float: 'right' }}
          type="button"
          variant="contained"
        >
          {`${translate('back')}`}
        </Button>
      </Stack>
      <Card sx={{ p: 3 }}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: '1fr',
            sm: '1fr',
            md: '1fr',
          }}
        >
          <RHFTextField
            name="name"
            label={`${translate('pages.itemLog.itemLogName')}`}
            value={values.name}
            required
          />
          <RHFTextField
            name="content"
            label={`${translate('content')}`}
            multiline
            rows={9}
            value={values.content}
            required
          />
          <div style={{ width: '50%' }}>
            <RHFTextField
              name="itemId"
              label={`${translate('item')}`}
              value={item ? item.name : ''}
              disabled={item ? true : false}
            />
          </div>
          <div style={{ width: '50%' }}>
            <RHFSelect
              native
              name="type"
              label={`${translate('pages.itemLog.typeOfLog')}`}
              placeholder={`${translate('pages.itemLog.typeOfLog')}`}
              value={values.type}
            >
              <option key={''} value=""></option>
              {typesOfLogs.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </RHFSelect>
          </div>
        </Box>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('pages.itemLog.createNewItemLog')}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
