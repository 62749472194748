import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getUsers } from 'src/redux/slices/user';
import { useNavigate } from 'react-router-dom';
import { getPropertyServiceRequests, getServiceRequests } from 'src/redux/slices/serviceRequest';
import { createTask } from 'src/redux/slices/task';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getProperties } from 'src/redux/slices/property';
import { getPropertyRooms } from 'src/redux/slices/room';
import { useLocales } from '../../../../locales';
import { ONLY_OPERATORS_ROLES } from 'src/constants';
import { IUserOriginal } from 'src/@types/user';
import { IProperty } from 'src/@types/property';
import { IServiceRequest } from 'src/@types/serviceRequest';
import { IRoom } from 'src/@types/room';
import { useAuthContext } from 'src/auth/useAuthContext';

type FormValuesProps = {
  assigneeId?: number | string;
  title?: string;
  description?: string;
  serviceRequestId?: number | string;
  propertyId?: number | string | undefined;
  roomId?: number | string;
};

export default function TaskCreateForm({
  propertyId,
  serviceRequestId,
  roomId,
}: FormValuesProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { serviceRequests } = useSelector((state) => state.serviceRequest);
  const { users } = useSelector((state) => state.user);
  const { properties } = useSelector((state) => state.property);
  const { rooms } = useSelector((state) => state.room);
  const [operators, setOperators] = useState<IUserOriginal[]>([]);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const defaultValues = {
    title: '',
    description: '',
    assigneeId: '',
    serviceRequestId: serviceRequestId || '',
    propertyId: propertyId || '',
    roomId: roomId || '',
  };

  const UpdatePropertySchema = Yup.object().shape({
    description: Yup.string().required(
      `${translate('isRequired.description')}`
    ),
    title: Yup.string().required(`${translate('isRequired.name')}`),
    assigneeId: Yup.number()
      .typeError(`${translate('isRequired.assigneeName')}`)
      .required(`${translate('isRequired.assigneeName')}`),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  function getNavigateBackDestination(
    propertyId: number | string | undefined,
    serviceRequestId: number | string | undefined
  ): string | undefined {
    const propertyTasksDestination =
      propertyId && !roomId && !serviceRequestId
        ? PATH_DASHBOARD.property.tasks(propertyId.toString())
        : '';
    const serviceRequestTasksDestination =
      serviceRequestId && !roomId && !propertyId
        ? PATH_DASHBOARD.serviceRequest.tasks(serviceRequestId.toString())
        : '';
    const roomTasksDestination =
      roomId && propertyId && !serviceRequestId
        ? PATH_DASHBOARD.room.tasks(roomId.toString(), propertyId.toString())
        : '';
    return (
      propertyTasksDestination ||
      serviceRequestTasksDestination ||
      roomTasksDestination
    );
  }

  const navigateBackDestination = getNavigateBackDestination(
    propertyId,
    serviceRequestId
  );

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(createTask(data));
      reset();
      navigate(
        navigateBackDestination
          ? navigateBackDestination
          : PATH_DASHBOARD.general.task
      );
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  const onChangeProperty = async (propertyId: string) => {
    setValue('propertyId', propertyId);
    if (propertyId !== '') {
      await Promise.all([
        dispatch(getPropertyRooms(Number(propertyId))),
        dispatch(getPropertyServiceRequests(Number(propertyId))),
      ]);
    } else {
      setValue('roomId', '');
      await dispatch(getServiceRequests());
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([dispatch(getUsers()), dispatch(getProperties())]);
      if (propertyId) {
        await Promise.all([
          await dispatch(getPropertyRooms(Number(propertyId))),
          await dispatch(getPropertyServiceRequests(Number(propertyId))),
        ]);
      } else {
        await dispatch(getServiceRequests());
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (users) {
      const operators = users.filter((user: IUserOriginal) =>
        user.roles.some((role: string) => ONLY_OPERATORS_ROLES.includes(role))
      );
      setOperators(operators);
    }
  }, [users]);

  useEffect(() => {
    if (serviceRequests && serviceRequestId) {
      const foundServiceRequest = serviceRequests.find(
        (serviceRequest: IServiceRequest) =>
          serviceRequest.id === serviceRequestId
      );
      const _propertyId = foundServiceRequest?.property?.id;
      setValue('propertyId', _propertyId ?? '');
      setIsDisable(true);
    }
  }, [serviceRequests]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
        <Button
          onClick={() =>
            navigate(
              navigateBackDestination
                ? navigateBackDestination
                : PATH_DASHBOARD.general.task
            )
          }
          sx={{ float: 'right' }}
          type="button"
          variant="contained"
        >
          {`${translate('back')}`}
        </Button>
      </Stack>
      <Card sx={{ p: 3 }}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField
            name="title"
            label={`${translate('name')}`}
            value={values.title}
            required
          />
          <RHFTextField
            name="description"
            label={`${translate('description')}`}
            value={values.description}
            required
          />
          <RHFSelect
            native
            name="propertyId"
            label={`${translate('property')}`}
            placeholder={`${translate('property')}`}
            onChange={(e) => onChangeProperty(e.target.value)}
            value={values.propertyId}
            disabled={propertyId || isDisable ? true : false}
          >
            <option key={''} value=""></option>
            {properties.map((property: IProperty) => (
              <option key={property.id} value={property.id}>
                {property.name}
              </option>
            ))}
          </RHFSelect>
          <RHFSelect
            native
            name="serviceRequestId"
            label={`${translate('serviceRequest')}`}
            placeholder={`${translate('serviceRequest')}`}
            disabled={serviceRequestId || isDisable ? true : false}
            value={values.serviceRequestId}
          >
            <option key={''} value=""></option>
            {serviceRequests.map((serviceRequests: IServiceRequest) => (
              <option key={serviceRequests.id} value={serviceRequests.id}>
                {serviceRequests.name}
              </option>
            ))}
          </RHFSelect>
          <RHFSelect
            native
            name="assigneeId"
            label={`${translate('assigneeName')}`}
            placeholder={`${translate('assigneeName')}`}
            value={values.assigneeId}
            required
          >
            <option key={''} value=""></option>
            {operators.map((user: IUserOriginal) => (
              <option key={user.id} value={user.id}>
                {user.firstName} {user.lastName}
              </option>
            ))}
          </RHFSelect>
          <RHFSelect
            native
            name="roomId"
            label={`${translate('room')}`}
            placeholder={`${translate('room')}`}
            value={values.roomId}
            disabled={roomId || values.propertyId === '' ? true : false}
          >
            <option key={''} value=""></option>
            {rooms?.map((room: IRoom) => (
              <option key={room.id} value={room.id}>
                {room.name}
              </option>
            ))}
          </RHFSelect>
        </Box>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('pages.task.createTask')}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
