export enum ENotificationStatus {
  READ = 'read',
  CREATED = 'created',
}

export enum ENotificationType {
  SERVICE_REQUEST = 'service-request',
  SERVICE_REQUEST_COMMENT = 'service-request-comment',
  TASK = 'task',
  ATTACHMENT = 'attachment',
  SERVICE_REQUEST_ACCEPT = 'service-request-accept', //TODO: REWORK THIS AFTER DEPLOY
  REMINDER = 'reminder',
}

export type INotification = {
  id: number;
  title: string;
  entityType: string;
  entityId: number;
  userId: number;
  message: string;
  status: string;
  readAt: string;
  created: string;
  updated: string;
};

export type INotificationState = {
  isLoading: boolean;
  error: null | boolean;
  notifications: INotification[];
  notification: null | INotification;
  totalUnRead: number;
};
