import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
// @mui
import { Card, Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import TaskNewEditForm from 'src/sections/@dashboard/task/TaskNewEditForm';
import { dispatch, useSelector } from 'src/redux/store';
import { getTask } from 'src/redux/slices/task';
import { useEffect } from 'react';
import { useLocales } from '../../../locales';
import { urlHandler } from 'src/utils/navigateHandler';
import { ROOM, SERVICE_REQUEST } from 'src/constants';
import { setLastUrlTasks } from 'src/redux/slices/setting';

// ----------------------------------------------------------------------

export default function TaskEditPage() {
  const { lastUrlTasks } = useSelector((state: any) => state.setting);
  const { translate } = useLocales();
  const location = useLocation();

  const { id } = useParams();
  const { task } = useSelector((state: any) => state.task);

  useEffect(() => {
    const url = urlHandler(SERVICE_REQUEST, location.pathname, ROOM);
    if (url.length > 0) dispatch(setLastUrlTasks(url));
    dispatch(getTask(id ? Number(id) : 1));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.task.editTaskTitle')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.task.editTask')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('tasks')}`,
              href: PATH_DASHBOARD.general.task,
            },
            { name: task?.title ?? '' },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              <TaskNewEditForm isEdit task={task} fromUrl={lastUrlTasks} />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
