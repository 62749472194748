import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import axiosInstance from '../utils/axiosAPI';
import { Box } from '@mui/system';
import { Button, CircularProgress } from '@mui/material';
import { useLocales } from 'src/locales';

const RedirectPage = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const { translate } = useLocales();

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const response = await axiosInstance.get(`redirects/${uuid}`);
        const url = response.data.data;
        const urlParser = new URL(url);
        navigate(urlParser.pathname, { replace: true });
      } catch (error) {
        setIsError(true);
      }
    };

    if (uuid) {
      fetchUrl();
    }
  }, [uuid]);

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!isError ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h1>{`${translate('somethingWentWrong')}`}</h1>
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/')}
            >
              {`${translate('pages.qrCode.back')}`}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RedirectPage;
