import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable
} from '../../../components/table';
// sections
import { IReminderLight } from 'src/@types/reminder';
import { ReminderTableRow } from 'src/sections/@dashboard/reminder/list';
import { getReminderItems, getReminders, removeReminder } from 'src/redux/slices/reminder';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useLocales } from '../../../locales';
import ReminderToolbar from 'src/sections/@dashboard/reminder/list/ReminderTableToolBar';
import useDeleteRowsHandler from 'src/hooks/useDeleteHandler';
import { setLastUrlReminder } from 'src/redux/slices/setting';
import { getPageFromStorage, savePageToStorage } from 'src/utils/pageStorageHandler';
import { getRowsPerPageFromStorage, saveRowsPerPageToStorage } from 'src/utils/rowsPerPageStorageHandler';
import { ITEM, PROPERTY, ROOM } from 'src/constants';

type ReminderListPageProps = {
  itemId?: string;
  propertyId?: string;
  roomId?: string;
  fromUrl?: string;
};

export default function ReminderListPage({
  itemId,
  propertyId,
  roomId,
  fromUrl,
}: ReminderListPageProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [tableData, setTableData] = useState<IReminderLight[]>([]);
  const { deleteHandlers } = useDeleteRowsHandler();
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [openConfirm, setOpenConfirm] = useState(false);
  const { reminders, isLoading } = useSelector((state: any) => state.reminder);
  const [filterStatus, setFilterStatus] = useState('all');
  const { translate } = useLocales();

  const dataFiltered = applyFilter({
    inputData: tableData as IReminderLight[],
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 52 : 72;

  const isFiltered =
    filterName !== '' || filterRole !== 'all' || filterStatus !== 'all';

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const tableHead = [
    { id: 'name', label: `${translate('name')}`, align: 'left' },
    { id: 'description', label: `${translate('description')}`, align: 'left' },
    { id: 'customerName', label: `${translate('customer')}`, align: 'left' },
    { id: 'propertyName', label: `${translate('property')}`, align: 'left' },
    { id: 'authorName', label: `${translate('authorName')}`, align: 'left' },
    {
      id: 'assigneeName',
      label: `${translate('assigneeName')}`,
      align: 'left',
    },
    { id: 'roomName', label: `${translate('room')}`, align: 'left' },
    { id: 'item', label: `${translate('item')}`, align: 'left' },
    { id: 'status', label: `${translate('status')}`, align: 'left' },
    { id: 'time', label: `${translate('time')}`, align: 'left' },
    { id: 'type', label: `${translate('repeating')}`, align: 'left' },
    { id: 'actions', label: `${translate('actions')}`, align: 'right' },
  ];

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await dispatch(removeReminder(Number(id)));
      await dispatch(getReminders());
      const deleteRow = tableData.filter(
        (row) => Number(row.id) !== Number(id)
      );
      setSelected([]);
      setTableData(deleteRow);

      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    try {
      if (await deleteHandlers.deleteRows(selectedRows, removeReminder)) {
        return;
      }

      const filteredRows = tableData.filter(
        (row: any) => !selectedRows.includes(row.id.toString())
      );
      setSelected([]);
      setTableData(filteredRows);

      if (page > 0) {
        if (selectedRows.length === dataInPage.length) {
          setPage(page - 1);
        } else if (selectedRows.length === dataFiltered.length) {
          setPage(0);
        } else if (selectedRows.length > dataInPage.length) {
          const newPage =
            Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
            1;
          setPage(newPage);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleEditRow = (id: string) => {
    savePageToStorage('reminder', page);
    saveRowsPerPageToStorage('reminder', rowsPerPage);
    if (itemId) {
      dispatch(setLastUrlReminder(ITEM));
    }
    navigate(PATH_DASHBOARD.reminder.view(paramCase(id)));
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterRole('all');
    setFilterStatus('all');
  };

  const handlerNavigate = () => {
    switch (fromUrl) {
      case PROPERTY:
        navigate(PATH_DASHBOARD.property.items(propertyId?.toString() || '1'));
        return;
      case ROOM:
        navigate(
          PATH_DASHBOARD.room.items(
            roomId?.toString() || '1',
            propertyId?.toString() || '1'
          )
        );
        return;
      default:
        break;
    }

    if (itemId) {
      navigate(PATH_DASHBOARD.general.items);
    } else {
      navigate(PATH_DASHBOARD.general.reminder);
    }
  };

  useEffect(() => {
    if (itemId) {
      dispatch(getReminderItems(Number(itemId)));
    } else {
      dispatch(getReminders());
    }
    dispatch(setLastUrlReminder(''));
  }, [dispatch, itemId]);

  useEffect(() => {
    setTableData(reminders);
  }, [reminders]);

  useEffect(() => {
    const savedPage = getPageFromStorage('reminder');
    setPage(savedPage ?? 0);
    const savedRowsPerPage = getRowsPerPageFromStorage('reminder');
    setRowsPerPage(savedRowsPerPage ?? 5);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.reminder.title')}`}</title>
      </Helmet>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth={false}>
          {itemId ? (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5 }}>
              <Button
                component={RouterLink}
                to={
                  itemId
                    ? PATH_DASHBOARD.reminder.createReminderFromItem(
                        itemId.toString()
                      )
                    : PATH_DASHBOARD.reminder.create
                }
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                {`${translate('pages.reminder.newReminder')}`}
              </Button>
              <Button
                onClick={handlerNavigate}
                sx={{ float: 'right', marginLeft: '13px' }}
                type="button"
                variant="contained"
              >
                {`${translate('back')}`}
              </Button>
            </Box>
          ) : (
            <>
              <CustomBreadcrumbs
                heading={`${translate('pages.reminder.heading')}`}
                links={[
                  {
                    name: `${translate('dashboard')}`,
                    href: PATH_DASHBOARD.root,
                  },
                  {
                    name: `${translate('reminders')}`,
                    href: PATH_DASHBOARD.general.reminder,
                  },
                  { name: `${translate('pages.reminder.heading')}` },
                ]}
                action={
                  <Button
                    component={RouterLink}
                    to={
                      itemId
                        ? PATH_DASHBOARD.reminder.createReminderFromItem(
                            itemId.toString()
                          )
                        : PATH_DASHBOARD.reminder.create
                    }
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    {`${translate('pages.reminder.newReminder')}`}
                  </Button>
                }
              />
            </>
          )}

          <Card>
            <Divider />
            <ReminderToolbar
              isFiltered={isFiltered}
              filterName={filterName}
              onFilterName={handleFilterName}
              onResetFilter={handleResetFilter}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id.toString())
                  )
                }
                action={
                  <Tooltip title={`${translate('delete')}`}>
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHead}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id.toString())
                      )
                    }
                  />

                  <TableBody>
                    <>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <ReminderTableRow
                            key={row.id}
                            row={row}
                            selected={selected.includes(row.id.toString())}
                            onSelectRow={() => onSelectRow(row.id.toString())}
                            onDeleteRow={() =>
                              handleDeleteRow(row.id.toString())
                            }
                            onEditRow={() => handleEditRow(row.id.toString())}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          tableData.length
                        )}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </>
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {selected.length > 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong>{' '}
                {selected.length > 4
                  ? translate('item_five_more')
                  : translate('item_two_four')}
                ?
              </>
            )}
            {selected.length === 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong> {translate('item_one')}?
              </>
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  inputData: IReminderLight[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  if (inputData.length > 0) {
    const stabilizedThis = inputData.map((el, index) => [el, index] as const);

    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    inputData = stabilizedThis.map((el) => el[0]);

    if (filterName) {
      inputData = inputData.filter(
        (reminder) =>
          reminder.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
      );
    }

    // if (filterStatus !== 'all') {
    //   inputData = inputData.filter((user) => user.status === filterStatus);
    // }
    //
    // if (filterRole !== 'all') {
    //   inputData = inputData.filter((user) => user.role === filterRole);
    // }

    return inputData;
  } else {
    return [];
  }
}
