import { Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { FC } from 'react';
import { useLocales } from 'src/locales';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

interface CustomFilterProps {
  dataToFilter: any;
  filteredData: number[];
  handleChange: (e: any, name: string) => void;
  updating: string;
}

const CustomFilter: FC<CustomFilterProps> = (props) => {
  const { translate } = useLocales();
  const { dataToFilter, filteredData, handleChange, updating } = props;

  return (
    <FormControl sx={{ mt: 3, width: 260, mr: 2 }}>
      <InputLabel id="demo-multiple-checkbox-label">{`${translate(
        `${updating}`
      )}`}</InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={filteredData}
        onChange={(e: any) => handleChange(e, updating)}
        input={<OutlinedInput label={`${translate(`${updating}`)}`} />}
        renderValue={(selected) =>
          filteredData
            .map((id: number) => {
              const filter = dataToFilter.find(
                (filter: any) => filter.id.toString() === id.toString()
              );
              return filter ? filter.name : '';
            })
            .join(', ')
        }
        MenuProps={MenuProps}
      >
        {dataToFilter.map((filter: any) => (
          <MenuItem key={filter.id} value={filter.id}>
            <Checkbox
              checked={filteredData.includes(filter.id)}
              sx={{
                '&.Mui-checked': {
                  color: 'green',
                },
              }}
            />
            <p style={{ fontSize: 14 }}>{filter.name}</p>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomFilter;
