import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useLocales } from '../../../locales';
import { useParams } from 'react-router';
import { ReminderCreateForm } from 'src/sections/@dashboard/reminder/create/ReminderCreateForm';

// ----------------------------------------------------------------------

export default function ReminderItemCreatePage() {
  const { translate } = useLocales();
  // const {item} = useSelector(state => state.item)
  const { id } = useParams();

  // useEffect(() => {
  //   const fetchData = async () => {
  //     await dispatch(getItem(Number(id)))
  //   }
  //   fetchData()
  // }, [id])

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.reminder.titleCreate')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.reminder.createNewReminder')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('reminders')}`,
              href: PATH_DASHBOARD.general.reminder,
            },
            { name: `${translate('pages.reminder.createReminder')}` },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              <ReminderCreateForm itemId={id} />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
