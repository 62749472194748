import * as Yup from 'yup';
import { useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getCustomers } from '../../../../redux/slices/customer';
import { getProperties } from 'src/redux/slices/property';
import { getItemTags } from 'src/redux/slices/itemTag';
import { getItemCategories } from 'src/redux/slices/itemCategory';
import { createItem } from 'src/redux/slices/item';
import { useNavigate } from 'react-router';
import { getPropertyRooms } from 'src/redux/slices/room';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../../locales';

// ----------------------------------------------------------------------

type FormValuesProps = {
  name: string;
  description: string;
  roomId: number | string;
  propertyId: number | string;
  itemTagId: number | string;
  itemCategoryId: number | string;
};

type ItemCreateFormProps = {
  propertyId?: number;
  roomId?: number;
};

export default function ItemCreateForm({
  propertyId,
  roomId,
}: ItemCreateFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { properties } = useSelector((state) => state.property);
  const { itemCategories } = useSelector((state) => state.itemCategory);
  const { itemTags } = useSelector((state) => state.itemTag);
  const { rooms } = useSelector((state) => state.room);
  const { translate } = useLocales();

  // TODO: proper validation
  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    description: Yup.string().required(
      `${translate('isRequired.description')}`
    ),
    roomId: Yup.number()
      .typeError(`${translate('isRequired.room')}`)
      .required(`${translate('isRequired.room')}`),
    propertyId: Yup.number()
      .typeError(`${translate('isRequired.property')}`)
      .required(`${translate('isRequired.property')}`),
    itemCategoryId: Yup.number()
      .typeError(`${translate('isRequired.itemCategory')}`)
      .required(`${translate('isRequired.itemCategory')}`),
  });

  const defaultValues = {
    name: '',
    description: '',
    roomId: roomId || '',
    propertyId: propertyId || '',
    itemTagId: '',
    itemCategoryId: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(createItem(data));
      navigate(checkReturn());
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  const checkReturn = () => {
    if (propertyId && !roomId) {
      return PATH_DASHBOARD.property.items(propertyId.toString());
    } else if (roomId && propertyId) {
      return PATH_DASHBOARD.room.items(
        roomId.toString(),
        propertyId.toString()
      );
    } else {
      return PATH_DASHBOARD.general.items;
    }
  };

  const onChangeProperty = async (propertyId: string) => {
    setValue('propertyId', propertyId);
    if (propertyId !== '') await dispatch(getPropertyRooms(Number(propertyId)));
    else setValue('roomId', '');
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(getCustomers()),
        dispatch(getProperties()),
        dispatch(getItemTags()),
        dispatch(getItemCategories()),
      ]);
      if (propertyId) await dispatch(getPropertyRooms(Number(propertyId)));
    };
    fetchData();
  }, []);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
        <Button
          onClick={() => navigate(checkReturn())}
          sx={{ float: 'right' }}
          type="button"
          variant="contained"
        >
          {`${translate('back')}`}
        </Button>
      </Stack>
      <Card sx={{ p: 3 }}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField
            name="name"
            label={`${translate('name')}`}
            value={values.name}
            required
          />

          <RHFTextField
            name="description"
            label={`${translate('description')}`}
            value={values.description}
            multiline
            minRows={3}
            required
          />

          <RHFSelect
            native
            name="propertyId"
            label={`${translate('property')}`}
            placeholder={`${translate('property')}`}
            onChange={(e) => onChangeProperty(e.target.value)}
            value={values.propertyId}
            disabled={propertyId ? true : false}
            required
          >
            <option key={''} value={''} />
            {properties.map((property) => (
              <option key={property.id} value={property.id}>
                {property.name}
              </option>
            ))}
          </RHFSelect>

          <RHFSelect
            native
            name="roomId"
            label={`${translate('room')}`}
            placeholder={`${translate('room')}`}
            value={values.roomId}
            disabled={roomId || values.propertyId === '' ? true : false}
            required
          >
            <option key={''} value={''} />
            {rooms?.map((room) => (
              <option key={room.id} value={room.id}>
                {room.name}
              </option>
            ))}
          </RHFSelect>

          <RHFSelect
            native
            name="itemTagId"
            label={`${translate('itemTag')}`}
            placeholder={`${translate('itemTag')}`}
            value={values.itemTagId}
          >
            <option key={''} value={''} />
            {itemTags.map((itemTag) => (
              <option key={itemTag.id} value={itemTag.id}>
                {itemTag.name}
              </option>
            ))}
          </RHFSelect>

          <RHFSelect
            native
            name="itemCategoryId"
            label={`${translate('itemCategory')}`}
            placeholder={`${translate('itemCategory')}`}
            value={values.itemCategoryId}
            required
          >
            <option key={''} value={''} />
            {itemCategories.map((itemCategory) => (
              <option key={itemCategory.id} value={itemCategory.id}>
                {itemCategory.name}
              </option>
            ))}
          </RHFSelect>
        </Box>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          {/*<RHFTextField name="about" multiline rows={4} label="About" />*/}

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('pages.item.createItem')}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
