import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useLocales } from '../../../locales';
import RequestCommentEditForm from 'src/sections/@dashboard/requestComment/RequestCommentEditForm';
import { Navigate, useNavigate, useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { dispatch, useSelector } from 'src/redux/store';
import { getRequestComment } from 'src/redux/slices/requestComment';

// ----------------------------------------------------------------------

export default function RequestCommentEditPage() {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const { requestComment } = useSelector((state) => state.requestComment);
  const { serviceRequestId, requestCommentId } = useParams();
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

  useEffect(() => {
    if (requestCommentId && !isDataFetched) {
      const fetchRequestComments = async () => {
        try {
          await dispatch(getRequestComment(Number(requestCommentId)));
          setIsDataFetched(true);
        } catch (error) {
          navigate(PATH_PAGE.page404);
        }
      };
      fetchRequestComments();
    }
  }, [requestCommentId, isDataFetched, navigate]);

  if (!serviceRequestId || !requestCommentId) {
    return <Navigate to={PATH_PAGE.page404} replace={true} />;
  }

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.serviceRequest.titleEditComment')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.serviceRequest.editComment')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('serviceRequest')}`,
              href: PATH_DASHBOARD.serviceRequest.view(serviceRequestId),
            },
            { name: `${translate('pages.serviceRequest.editComment')}` },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              <RequestCommentEditForm requestComment={requestComment} />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
