import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useParams } from 'react-router';
import { useLocales } from '../../../locales';
import ServiceRequestCreateForm from 'src/sections/@dashboard/serviceRequest/create/ServiceRequestCreateForm';

// ----------------------------------------------------------------------

export default function PropertyServiceRequestCreatePage() {
  const { translate } = useLocales();
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.task.createNewTaskInProperty')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.serviceRequest.createNewRequest')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('property')}`,
              href: PATH_DASHBOARD.general.properties,
            },
            { name: `${translate('pages.serviceRequest.newRequest')}` },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              <ServiceRequestCreateForm
                propertyId={Number(id)}
                customerId={''}
                name={''}
                description={''}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
