import { CUSTOMER, PROPERTY } from 'src/constants';
import { clearItems, getPropertyItems } from 'src/redux/slices/item';
import { clearProperties, getCustomerProperties } from 'src/redux/slices/property';
import { useDispatch } from 'src/redux/store';

export const useActiveSelectReminder = () => {
  const dispatch = useDispatch();

  const clearForm = (setValue: any) => {
    setValue('propertyId', '');
    setValue('itemId', '');
    dispatch(clearItems());
  };

  const customerOnChangeHandler = (id: string, setValue: any) => {
    clearForm(setValue);
    dispatch(clearProperties());
    dispatch(getCustomerProperties(Number(id)));
    setValue('customerId', id);
  };

  const propertyOnChangeHandler = (
    id: string,
    setValue: any,
    setError: any
  ) => {
    clearForm(setValue);
    dispatch(clearItems());
    dispatch(getPropertyItems(Number(id)));
    setValue('propertyId', id);
    setError('propertyId', { message: '' });
  };

  const onChangeHandler = (
    entity: string,
    id: string,
    setValue: any,
    setError: any
  ) => {
    switch (entity) {
      case CUSTOMER:
        customerOnChangeHandler(id, setValue);
        break;
      case PROPERTY:
        propertyOnChangeHandler(id, setValue, setError);
        break;
      default:
        break;
    }
  };

  return {
    onChangeHandler,
  };
};
