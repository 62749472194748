import { useState } from 'react';
// @mui
import { Button, Checkbox, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
// @types
// components
import ConfirmDialog from '../../../../components/confirm-dialog';
import { IProperty } from 'src/@types/property';
import { useAuthContext } from 'src/auth/useAuthContext';
import { ONLY_ADMIN_COMPANY_MANAGER_ROLES } from 'src/constants';
import { useLocales } from '../../../../locales';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { allowTo } from 'src/utils/permissionHandler';

// ----------------------------------------------------------------------

type Props = {
  row: IProperty;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function PropertyTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name, address, customer } = row;
  const { translate } = useLocales();
  const { user } = useAuthContext();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        {allowTo(user ? user.roles : [], ONLY_ADMIN_COMPANY_MANAGER_ROLES) && (
          <TableCell padding="checkbox">
            <Checkbox checked={selected} onClick={onSelectRow} />
          </TableCell>
        )}

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {/*<Avatar alt={name} src={avatarUrl} />*/}

            <Typography
              variant="subtitle2"
              onClick={() => onEditRow()}
              style={{ cursor: 'pointer' }}
              noWrap
            >
              {name}
            </Typography>
          </Stack>
        </TableCell>

        <TableCell align="left">
          {customer?.name ? customer.name : 'No Customer'}
        </TableCell>

        <TableCell align="left">{address.street}</TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {address.country}
        </TableCell>

        <TableCell align="left">{address.city}</TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {address.zip}
        </TableCell>

        <TableCell align="right">
          <Tooltip title={`${translate('edit')}`} sx={{ cursor: 'pointer' }}>
            <EditIcon
              onClick={() => {
                onEditRow();
                handleClosePopover();
              }}
            />
          </Tooltip>
          {allowTo(
            user ? user.roles : [],
            ONLY_ADMIN_COMPANY_MANAGER_ROLES
          ) && (
            <Tooltip
              title={`${translate('delete')}`}
              sx={{ cursor: 'pointer' }}
            >
              <DeleteIcon
                onClick={() => {
                  handleOpenConfirm();
                  handleClosePopover();
                }}
              />
            </Tooltip>
          )}
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('infoMessages.deletePromptV2')}`}
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
