import { Navigate, Outlet, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  LoginPage,
  NewPasswordPage,
  Page403,
  Page404,
  Page500,
  PropertyCreatePage,
  PropertyListPage,
  RegisterPage,
  ResetPasswordPage,
  UserOriginalCreatePage,
  UserOriginalEditPage,
  UserOriginalListPage,
  UserProfilePage,
  VerifyCodePage,
} from './elements';
import ServiceRequestListPage from '../pages/dashboard/serviceRequest/ServiceRequestListPage';
import ReminderListPage from '../pages/dashboard/reminder/ReminderListPage';
import TaskListPage from '../pages/dashboard/task/TaskListPage';
import CustomerListPage from '../pages/dashboard/customer/CustomerListPage';
import ItemListPage from '../pages/dashboard/item/ItemListPage';
import CustomerCreatePage from '../pages/dashboard/customer/CustomerCreatePage';
import CustomerEditPage from '../pages/dashboard/customer/CustomerEditPage';
import ItemCreatePage from 'src/pages/dashboard/item/ItemCreatePage';
import TaskCreatePage from 'src/pages/dashboard/task/TaskCreatePage';
import TaskEditPage from '../pages/dashboard/task/TaskEditPage';
import ReminderCreatePage from '../pages/dashboard/reminder/ReminderCreatePage';
import ReminderEditPage from '../pages/dashboard/reminder/ReminderEditPage';
import ServiceRequestCreatePage from '../pages/dashboard/serviceRequest/ServiceRequestCreatePage';
import ServiceRequestEditPage from '../pages/dashboard/serviceRequest/ServiceRequestEditPage';
import CustomerAccountPage from 'src/pages/dashboard/customer/CustomerAccountPage';
import PropertyDetailPage from 'src/pages/dashboard/property/PropertyDetailPage';
import ItemDetailPage from 'src/pages/dashboard/item/ItemDetailPage';
import ConfigurationListPage from 'src/pages/dashboard/configuration/ConfigurationListPage';
import ItemCategoryEditPage from 'src/pages/dashboard/itemCategory/ItemCategoryEditPage';
import ItemTagEditPage from 'src/pages/dashboard/itemTag/ItemTagEditPage';
import CustomerPropertyCreatePage from 'src/pages/dashboard/customer/CustomerPropertyCreatePage';
import RoomCreatePage from 'src/pages/dashboard/room/RoomCreatePage';
import RoomEditPage from 'src/pages/dashboard/room/RoomEditPage';
import PropertyItemCreatePage from 'src/pages/dashboard/property/PropertyItemCreatePage';
import PropertyTaskCreatePage from 'src/pages/dashboard/property/PropertyTaskCreatePage';
import DashboardPage from '../pages/dashboard/DahboardPage';
import ServiceRequestTaskCreatePage from 'src/pages/dashboard/serviceRequest/ServiceRequestTaskCreatePage';
import RoomTaskCreatePage from 'src/pages/dashboard/room/RoomTaskCreatePage';
import RoomItemCreatePage from 'src/pages/dashboard/room/RoomItemCreatePage';
import {
  ADMIN_MANAGERS_ROLES,
  ALL_ROLES,
  NO_CUSTOMER_ROLES,
  NO_OPERATORS_ROLES,
  ONLY_ADMIN_COMPANY_CUSTOMER_ROLES,
  ONLY_ADMIN_COMPANY_MANAGER_ROLES,
  ONLY_ADMIN_ROLES,
} from 'src/constants';
import RoleGuard from 'src/auth/RoleGuard';
import PropertyServiceRequestCreatePage from 'src/pages/dashboard/property/PropertyServiceRequestCreatePage';
import ItemLogCreatePage from 'src/pages/dashboard/itemLogs/ItemLogCreatePage';
import ItemLogEditPage from 'src/pages/dashboard/itemLogs/ItemLogEditPage';
import AttachmentCreatePage from 'src/pages/dashboard/attachment/AttachmentCreatePage';
import ReminderItemCreatePage from 'src/pages/dashboard/reminder/ReminderItemCreatePage';
import AttachmentCreateForServiceRequestPage from '../pages/dashboard/attachment/AttachmentCreateForServiceRequestPage';
import RequestCommentCreatePage from 'src/pages/dashboard/requestComment/RequestCommentCreatePage';
import AttachmentEditPage from 'src/pages/dashboard/attachment/AttachmentEditPage';
import RequestCommentEditPage from 'src/pages/dashboard/requestComment/RequestCommentEditPage';
import RequestCommentViewPage from 'src/pages/dashboard/requestComment/RequestCommentViewPage';
import AttachmentViewPage from 'src/pages/dashboard/attachment/AttachmentViewPage';
import ScanQrPage from 'src/pages/dashboard/qr/ScanQrPage';
import RedirectPage from 'src/pages/RedirectPage';
import ReminderDetailPage from 'src/pages/dashboard/reminder/ReminderDetailPage';
import AttachmentCreateForReminderPage from 'src/pages/dashboard/attachment/AttachmentCreateForReminderPage';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        { path: 'forgotten-password', element: <ResetPasswordPage /> },
        { path: 'password-reset', element: <NewPasswordPage /> },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        { path: 'register-unprotected', element: <RegisterPage /> },
        {
          element: <CompactLayout />,
          children: [{ path: 'verify', element: <VerifyCodePage /> }],
        },
      ],
    },

    // Dashboard
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'profile', element: <UserProfilePage /> },
        { path: 'app', element: <DashboardPage /> },
        {
          path: 'user',
          element: (
            <RoleGuard allowedRoles={ONLY_ADMIN_COMPANY_CUSTOMER_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [
            { path: 'list', element: <UserOriginalListPage /> },
            {
              path: 'create',
              element: (
                <RoleGuard allowedRoles={ONLY_ADMIN_COMPANY_MANAGER_ROLES}>
                  <UserOriginalCreatePage />
                </RoleGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <RoleGuard allowedRoles={NO_OPERATORS_ROLES}>
                  <UserOriginalEditPage />
                </RoleGuard>
              ),
            },
            {
              path: ':id/create',
              element: (
                <RoleGuard allowedRoles={ONLY_ADMIN_COMPANY_MANAGER_ROLES}>
                  <UserOriginalCreatePage />
                </RoleGuard>
              ),
            },
          ],
        },
        {
          path: 'customer',
          element: (
            <RoleGuard allowedRoles={ONLY_ADMIN_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [
            { path: 'list', element: <CustomerListPage /> },
            { path: 'create', element: <CustomerCreatePage /> },
            { path: ':id/edit', element: <CustomerEditPage /> },
            { path: ':id/general', element: <CustomerAccountPage /> },
            { path: ':id/properties', element: <CustomerAccountPage /> },
            { path: ':id/users', element: <CustomerAccountPage /> },
            {
              path: ':id/property/create',
              element: (
                <RoleGuard allowedRoles={ONLY_ADMIN_COMPANY_MANAGER_ROLES}>
                  <CustomerPropertyCreatePage />
                </RoleGuard>
              ),
            },
            {
              path: ':id/user/create',
              element: (
                <RoleGuard allowedRoles={ONLY_ADMIN_COMPANY_MANAGER_ROLES}>
                  <UserOriginalCreatePage />
                </RoleGuard>
              ),
            },
          ],
        },
        {
          path: 'property',
          element: (
            <RoleGuard allowedRoles={ALL_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [
            { path: 'list', element: <PropertyListPage /> },
            {
              path: 'create',
              element: (
                <RoleGuard allowedRoles={ONLY_ADMIN_COMPANY_MANAGER_ROLES}>
                  <PropertyCreatePage />
                </RoleGuard>
              ),
            },
            { path: ':id', element: <PropertyDetailPage /> },
            {
              path: ':id/task/create',
              element: (
                <RoleGuard allowedRoles={ADMIN_MANAGERS_ROLES}>
                  <PropertyTaskCreatePage />
                </RoleGuard>
              ),
            },
            {
              path: ':id/room/create',
              element: (
                <RoleGuard allowedRoles={ADMIN_MANAGERS_ROLES}>
                  <RoomCreatePage />
                </RoleGuard>
              ),
            },
            { path: ':id/general', element: <PropertyDetailPage /> },
            { path: ':id/general/customer', element: <PropertyDetailPage /> },
            { path: ':id/rooms', element: <PropertyDetailPage /> },
            { path: ':id/items', element: <PropertyDetailPage /> },
            { path: ':id/item/create', element: <PropertyItemCreatePage /> },
            { path: ':id/tasks', element: <PropertyDetailPage /> },
            { path: ':id/qr-code', element: <PropertyDetailPage /> },
            {
              path: ':propertyId/room/:roomId/general',
              element: <RoomEditPage />,
            },
            {
              path: ':propertyId/room/:roomId/items',
              element: <RoomEditPage />,
            },
            {
              path: ':id/room/:roomId/item/create',
              element: <RoomItemCreatePage />,
            },
            {
              path: ':propertyId/room/:roomId/tasks',
              element: <RoomEditPage />,
            },
            {
              path: ':propertyId/room/:roomId/qr-code',
              element: <RoomEditPage />,
            },
            {
              path: ':id/service-requests',
              element: (
                <RoleGuard allowedRoles={NO_OPERATORS_ROLES}>
                  <PropertyDetailPage />
                </RoleGuard>
              ),
            },
            {
              path: ':id/create/service-request',
              element: (
                <RoleGuard allowedRoles={NO_OPERATORS_ROLES}>
                  <PropertyServiceRequestCreatePage />
                </RoleGuard>
              ),
            },
          ],
        },
        {
          path: 'room',
          element: (
            <RoleGuard allowedRoles={ALL_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [{ path: ':roomId/items', element: <RoomEditPage /> }],
        },
        {
          path: 'item',
          element: (
            <RoleGuard allowedRoles={ALL_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [
            {
              path: 'list',
              element: (
                <RoleGuard allowedRoles={ADMIN_MANAGERS_ROLES}>
                  <ItemListPage />
                </RoleGuard>
              ),
            },
            {
              path: 'create',
              element: (
                <RoleGuard allowedRoles={ADMIN_MANAGERS_ROLES}>
                  <ItemCreatePage />
                </RoleGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RoleGuard allowedRoles={ADMIN_MANAGERS_ROLES}>
                  <Outlet />
                </RoleGuard>
              ),
              children: [
                { path: 'reminders', element: <ItemDetailPage /> },
                {
                  path: 'reminders/create',
                  element: <ReminderItemCreatePage />,
                },
              ],
            },
            { path: ':id/general/room', element: <ItemDetailPage /> },
            { path: ':id/general/property', element: <ItemDetailPage /> },
            { path: ':id/general', element: <ItemDetailPage /> },
            { path: ':id/item-log/create', element: <ItemLogCreatePage /> },
            { path: ':id/item-logs', element: <ItemDetailPage /> },
            { path: ':id/attachments', element: <ItemDetailPage /> },
            { path: ':id/qr-code', element: <ItemDetailPage /> },
            {
              path: ':id/item-logs/:itemLogId/general',
              element: <ItemLogEditPage />,
            },
            {
              path: ':id/item-logs/:itemLogId/attachments',
              element: <ItemLogEditPage />,
            },
            {
              path: ':itemId/item-logs/:itemLogId/attachments/create',
              element: <AttachmentCreatePage />,
            },
            {
              path: ':id/item-logs/:itemLogId/attachments/:attachmentId/edit',
              element: <AttachmentEditPage />,
            },
            {
              path: ':itemId/attachments/create',
              element: <AttachmentCreatePage />,
            },
            {
              path: ':itemId/attachments/:attachmentId/edit',
              element: <AttachmentEditPage />,
            },
          ],
        },
        {
          path: 'task',
          element: (
            <RoleGuard allowedRoles={ALL_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [
            {
              path: 'list',
              element: (
                <RoleGuard allowedRoles={NO_CUSTOMER_ROLES}>
                  <TaskListPage />
                </RoleGuard>
              ),
            },
            {
              path: 'create',
              element: (
                <RoleGuard allowedRoles={ADMIN_MANAGERS_ROLES}>
                  <TaskCreatePage />
                </RoleGuard>
              ),
            },
            { path: ':id/general', element: <TaskEditPage /> },
            { path: ':id/edit/service-request', element: <TaskEditPage /> },
            { path: ':id/edit/room', element: <TaskEditPage /> },
            {
              path: ':id/create/:roomId',
              element: (
                <RoleGuard allowedRoles={ADMIN_MANAGERS_ROLES}>
                  <RoomTaskCreatePage />
                </RoleGuard>
              ),
            },
          ],
        },
        {
          path: 'configuration',
          element: (
            <RoleGuard allowedRoles={ADMIN_MANAGERS_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [
            { path: 'itemTags', element: <ConfigurationListPage /> },
            { path: 'itemCategories', element: <ConfigurationListPage /> },
          ],
        },
        {
          path: 'itemCategory/:id/general',
          element: <ItemCategoryEditPage />,
        },
        {
          path: 'itemTag',
          element: (
            <RoleGuard allowedRoles={ADMIN_MANAGERS_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [{ path: ':id/general', element: <ItemTagEditPage /> }],
        },
        {
          path: 'reminder',
          element: (
            <RoleGuard allowedRoles={ADMIN_MANAGERS_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [
            { path: 'list', element: <ReminderListPage /> },
            { path: 'create', element: <ReminderCreatePage /> },
            { path: ':id/general', element: <ReminderDetailPage /> },
            { path: ':id/edit/item', element: <ReminderEditPage /> },
            { path: ':id/attachments', element: <ReminderDetailPage /> },
            {
              path: ':reminderId/attachments/:attachmentId/edit',
              element: <AttachmentEditPage />,
            },
            {
              path: ':id/attachments/create',
              element: <AttachmentCreateForReminderPage />,
            },
          ],
        },
        {
          path: 'service-request',
          element: (
            <RoleGuard allowedRoles={ALL_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [
            { path: 'list', element: <ServiceRequestListPage /> },
            { path: 'create', element: <ServiceRequestCreatePage /> },
            { path: ':id/general', element: <ServiceRequestEditPage /> },
            { path: ':id/tasks', element: <ServiceRequestEditPage /> },
            {
              path: ':id/task/create',
              element: <ServiceRequestTaskCreatePage />,
            },
            {
              path: ':serviceRequestId/comments/create',
              element: <RequestCommentCreatePage />,
            },
            {
              path: ':serviceRequestId/attachments/create',
              element: <AttachmentCreateForServiceRequestPage />,
            },
            {
              path: ':serviceRequestId/attachments/:attachmentId/edit',
              element: <AttachmentEditPage />,
            },
            {
              path: ':serviceRequestId/comments/create',
              element: <RequestCommentCreatePage />,
            },
            { path: ':id/comments', element: <ServiceRequestEditPage /> },
            {
              path: ':serviceRequestId/comments/:requestCommentId/edit',
              element: <RequestCommentEditPage />,
            },
            {
              path: ':id/attachments',
              element: <ServiceRequestEditPage />,
            },
          ],
        },
        {
          path: 'scan-qr',
          element: <ScanQrPage />,
        },
        {
          path: 'comments',
          element: (
            <RoleGuard allowedRoles={ALL_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [
            {
              path: ':id',
              element: <RequestCommentViewPage />,
            },
          ],
        },
        {
          path: 'attachments',
          element: (
            <RoleGuard allowedRoles={ALL_ROLES}>
              <Outlet />
            </RoleGuard>
          ),
          children: [
            {
              // path: ':serviceRequestId/attachments/:attachmentId/edit',
              // element: <AttachmentEditPage />
              path: ':id',
              element: <AttachmentViewPage />,
            },
          ],
        },
      ],
    },

    // Main Routes
    {
      element: <Outlet />,
      children: [
        { element: <Navigate to="/dashboard" replace />, index: true },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    {
      path: '/redirect-to/:uuid',
      element: (
        <AuthGuard>
          <RedirectPage />
        </AuthGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
