import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosAPI';
import { FilterData, IItem, IItemState } from 'src/@types/item';
import { IRedirect } from 'src/@types/redirect';
// ----------------------------------------------------------------------

const initialState: IItemState = {
  isLoading: true,
  error: null,
  items: [],
  item: null,
  redirect: null,
};

const slice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET ITEMS
    getItemsSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },

    // GET ITEM
    getItemSuccess(state, action) {
      state.isLoading = false;
      state.item = action.payload;
    },

    // GET REDIRECT
    getRedirectSuccess(state, action) {
      state.isLoading = false;
      state.redirect = action.payload;
    },

    // UPDATE ITEM
    updateItemSuccess(state, action) {
      state.isLoading = false;
      state.item = action.payload;
    },

    // CREATE ITEM
    createItemSuccess(state, action) {
      state.isLoading = false;
      state.item = action.payload;
    },

    // REMOVE ITEM
    removeItemSuccess(state, action) {
      state.isLoading = false;
      // state.item = action.payload;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.items = [];
      state.item = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
const mapItem = (item: any): IItem => {
  return {
    id: item?.id ? item.id : '',
    name: item?.name ? item.name : '',
    description: item?.description ? item.description : '',
    hash: item?.hash ? item.hash : '',
    room: item?.room ? item.room : null,
    property: item?.property ? item.property : null,
    itemTag: item?.itemTag ? item.itemTag : null,
    itemCategory: item?.itemCategory ? item.itemCategory : null,
    createdAt: item?.createdAt ? item.createdAt : '',
    updatedAt: item?.updatedAt ? item.updatedAt : '',
  };
};

const mapItemAccount = (item: any) => {
  const result: {
    name: any;
    description: any;
    propertyId: any;
    itemCategoryId: any;
    itemTagId: any;
    roomId?: any;
  } = {
    name: item?.name,
    description: item?.description,
    propertyId: Number(item?.propertyId),
    itemCategoryId: item?.itemCategoryId !== '' ? item.itemCategoryId : null,
    itemTagId: item?.itemTagId !== '' ? item.itemTagId : null,
    roomId: item.roomId !== '' ? item.roomId : null,
  };

  // TODO: add rooms relation
  if (item?.room?.id) {
    result.roomId = item.room.id;
  }

  return result;
};

// ----------------------------------------------------------------------

export function clearItems() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getItemsSuccess([]));
  };
}

export function getItems() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/items');

      const result: IItem[] = response?.data?.data?.map((item: any) =>
        mapItem(item)
      );
      dispatch(slice.actions.getItemsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getFilteredItems(data: FilterData) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/items', {
        params: {
          properties: data.filteredPropertyIds,
          itemTags: data.filteredTag,
          customers: data.filteredCustomerIds,
          itemCategories: data.filteredCategory,
        },
      });
      const result: IItem[] = response?.data?.data?.map((item: any) =>
        mapItem(item)
      );
      dispatch(slice.actions.getItemsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getPropertyItems(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/properties/' + id + '/items');

      const result: IItem[] = response?.data?.data?.map((item: any) =>
        mapItem(item)
      );

      dispatch(slice.actions.getItemsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getItem(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/items/' + id);

      const result: IItem = mapItem(response?.data?.data);

      dispatch(slice.actions.getItemSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getRedirectForItem(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/items/' + id + '/redirect', {
        params: { id },
      });

      const result: IRedirect = response?.data?.data;

      dispatch(slice.actions.getRedirectSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateItem(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch('/items/' + id, mapItemAccount(data));

      const result: IItem = mapItem(response?.data?.data);

      dispatch(slice.actions.updateItemSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getRoomItems(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/rooms/' + id + '/items');

      const result: IItem[] = response?.data?.data?.map((item: any) =>
        mapItem(item)
      );

      dispatch(slice.actions.getItemsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createItem(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post('/items/', mapItemAccount(data));

      const result: IItem = mapItem(response?.data?.data);

      dispatch(slice.actions.createItemSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeItem(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/items/' + id, {
        params: { id },
      });

      const result = response?.data;

      dispatch(slice.actions.removeItemSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function resetItems() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
