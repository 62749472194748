import { resetAttachments } from 'src/redux/slices/attachment';
import { resetCustomers } from 'src/redux/slices/customer';
import { resetItems } from 'src/redux/slices/item';
import { resetItemCategories } from 'src/redux/slices/itemCategory';
import { resetItemLogs } from 'src/redux/slices/itemLog';
import { resetItemTag } from 'src/redux/slices/itemTag';
import { resetProperties } from 'src/redux/slices/property';
import { resetReminders } from 'src/redux/slices/reminder';
import { resetRequestComments } from 'src/redux/slices/requestComment';
import { resetRooms } from 'src/redux/slices/room';
import { resetServiceRequets } from 'src/redux/slices/serviceRequest';
import { resetTasks } from 'src/redux/slices/task';
import { resetUsers } from 'src/redux/slices/user';
import { useDispatch } from 'src/redux/store';

const useFlushNavigate = () => {
  const dispatch = useDispatch();
  const flushRedux = () => {
    dispatch(resetItems());
    dispatch(resetProperties());
    dispatch(resetAttachments());
    dispatch(resetCustomers());
    dispatch(resetItemCategories());
    dispatch(resetItemLogs());
    dispatch(resetItemTag());
    dispatch(resetUsers());
    dispatch(resetReminders());
    dispatch(resetRequestComments());
    dispatch(resetRooms());
    dispatch(resetServiceRequets());
    dispatch(resetTasks());
  };

  return {
    flushRedux,
  };
};

export default useFlushNavigate;
