import axios from 'axios';
// config
import { HOST_API_KEY, HOST_API_KEY_BE } from '../config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_KEY });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default axiosInstance;

const getToken = () => {
  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlVzZXIwQGNvcmVBcGkuY29tIiwic3ViIjoxLCJpYXQiOjE2ODYxMzk5NTl9.JSNE0vQg-HFlqMu48ZE2CPv-NdtevyxewkcmFzH6YFU';
};

export const axiosInstanceAPI = axios.create({ baseURL: HOST_API_KEY_BE });

axiosInstanceAPI.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'BE: Something went wrong'
    )
);

axiosInstanceAPI.interceptors.request.use(
  (config) => {
    // Assuming `getToken` is a function that retrieves the current token
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
