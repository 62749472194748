import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { dispatch } from 'src/redux/store';
import { useEffect, useState } from 'react';
import { useLocales } from '../../../locales';
import { IItemLog } from 'src/@types/itemLog';
import { updateItemLog } from 'src/redux/slices/itemLog';

interface FormValuesProps {
  name: string;
  content: string;
  type: string;
  itemId: string | number;
}

type Props = {
  itemLog?: IItemLog;
};

export default function ItemLogNewEditForm({ itemLog }: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const [editing, setEditing] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    content: Yup.string().required(`${translate('isRequired.content')}`),
    type: Yup.string(),
  });

  const typesOfLogs = [
    `${translate('pages.itemLog.technicalCheck')}`,
    `${translate('pages.itemLog.generalInfo')}`,
  ];

  const [defaultValues, setDefaultValues] = useState<FormValuesProps>({
    name: itemLog?.title || '',
    content: itemLog?.content || '',
    type: itemLog?.type || '',
    itemId: itemLog?.itemId || '',
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    setDefaultValues((prevDefaultValues) => ({
      ...prevDefaultValues,
      name: itemLog?.title || '',
      content: itemLog?.content || '',
      type: itemLog?.type || '',
      itemId: itemLog?.itemId || '',
    }));
    reset(defaultValues);
  }, [itemLog]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(updateItemLog(Number(itemLog?.id), data));
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      navigate(PATH_DASHBOARD.item.itemLogs(itemLog?.itemId.toString() || ''));
      reset();
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 5 }}>
          <Button
            onClick={() =>
              navigate(
                PATH_DASHBOARD.item.itemLogs(
                  itemLog ? itemLog.itemId.toString() : ''
                )
              )
            }
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: '1fr',
              sm: '1fr',
              md: '1fr',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('name')}`}
              value={values.name}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
            <RHFTextField
              name="content"
              label={`${translate('content')}`}
              multiline
              rows={9}
              value={values.content}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
            <div style={{ width: '50%' }}>
              <RHFSelect
                native
                name="type"
                label={`${translate('pages.itemLog.typeOfLog')}`}
                placeholder={`${translate('pages.itemLog.typeOfLog')}`}
                value={values.type}
                disabled={!editing}
                variant={!editing ? 'standard' : 'outlined'}
              >
                <option key={''} value=""></option>
                {typesOfLogs.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </RHFSelect>
            </div>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            {editing && (
              <>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {`${translate('saveChanges')}`}
                </LoadingButton>
                <Button
                  onClick={() => setEditing(false)}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  {`${translate('close')}`}
                </Button>
              </>
            )}
            {!editing && (
              <Button
                onClick={() => setEditing(true)}
                sx={{ ml: 1 }}
                variant="contained"
              >
                {`${translate('edit')}`}
              </Button>
            )}
          </Box>
        </Card>
      </FormProvider>
    </Container>
  );
}
