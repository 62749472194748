import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD, PATH_PAGE } from '../../../routes/paths';
// _mock_
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useDispatch, useSelector } from '../../../redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocales } from '../../../locales';
import AttachmentEditForm from 'src/sections/@dashboard/attachment/AttachmentEditForm';
import { getAttachment } from 'src/redux/slices/attachment';

// TODO: This page is not working if attachment is not owned by service request, not covering other cases because of deployment

export default function AttachmentView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { id } = useParams();
  const { attachment } = useSelector((state) => state.attachment);
  const serviceRequestId = attachment?.serviceRequestId;
  const [isDataFetched, setIsDataFetched] = useState<boolean>(false);

  useEffect(() => {
    setIsDataFetched(false);
  }, [id]);

  useEffect(() => {
    if (id && !isDataFetched) {
      try {
        dispatch(getAttachment(Number(id)));
        setIsDataFetched(true);
      } catch (error) {
        navigate(PATH_PAGE.page404);
      }
    }
  }, [id, isDataFetched]);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.attachment.titleViewAttachment')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.attachment.viewAttachment')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('serviceRequest')}`,
              href: PATH_DASHBOARD.serviceRequest.attachments(
                serviceRequestId ? serviceRequestId : 'page404'
              ),
            },
            { name: `${translate('pages.attachment.viewAttachment')}` },
          ]}
        />
        {/* if attachment create from is tight coupled with itemLogId, it is not possible to make it universal */}
        <AttachmentEditForm
          attachment={attachment}
          itemId={undefined}
          itemLogId={undefined}
          serviceRequestId={serviceRequestId}
          disabledEdit={true}
        />
      </Container>
    </>
  );
}
