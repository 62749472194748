import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable
} from '../../../components/table';
// sections
import { FilterData, ITask } from 'src/@types/task';
import {
  getFilteredTasks,
  getPropertyTasks,
  getRoomTasks,
  getServiceRequestTasks,
  getTasks,
  removeTask
} from 'src/redux/slices/task';
import { dispatch, useSelector } from 'src/redux/store';
import { useSnackbar } from 'notistack';
import { TaskTableRow } from 'src/sections/@dashboard/task/list';
import { useLocales } from 'src/locales';
import TaskToolbar from 'src/sections/@dashboard/task/list/TaskTableToolBar';
import {
  ADMIN_MANAGERS_ROLES,
  CREATED,
  DONE,
  FILTERED_STATUS,
  IN_PROGRESS,
  PROPERTY,
  ROOM,
  SERVICE_REQUEST
} from 'src/constants';
import CustomSimpleFilter from '../CustomSimpleFilter';
import useDeleteRowsHandler from 'src/hooks/useDeleteHandler';
import { setLastUrlTasks } from 'src/redux/slices/setting';
import { useAuthContext } from 'src/auth/useAuthContext';
import { allowTo } from 'src/utils/permissionHandler';
import { getPageFromStorage, savePageToStorage } from 'src/utils/pageStorageHandler';
import { getRowsPerPageFromStorage, saveRowsPerPageToStorage } from 'src/utils/rowsPerPageStorageHandler';

type TaskListPageProps = {
  propertyId?: string;
  serviceRequestId?: string;
  roomId?: string;
};

export default function TaskListPage({
  propertyId,
  serviceRequestId,
  roomId,
}: TaskListPageProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const { user } = useAuthContext();
  const navigate = useNavigate();
  const { deleteHandlers } = useDeleteRowsHandler();
  const [tableData, setTableData] = useState<ITask[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [openConfirm, setOpenConfirm] = useState(false);
  const { tasks, isLoading } = useSelector((state: any) => state.task);
  const [filterStatus, setFilterStatus] = useState('all');
  const [filteredData, setFilteredData] = useState<FilterData>({
    [FILTERED_STATUS]: [CREATED, IN_PROGRESS],
  });
  const { lastUrlRooms } = useSelector((state: any) => state.setting);
  const { translate } = useLocales();

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 52 : 72;

  const isFiltered =
    filterName !== '' || filterRole !== 'all' || filterStatus !== 'all';

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const isCustomerUser =
    user?.roles.includes('customer') && user.roles.length === 1;

  const tableHead = [
    { id: 'name', label: `${translate('name')}`, align: 'left' },
    { id: 'property', label: `${translate('property')}`, align: 'left' },
    {
      id: 'assigneeName',
      label: `${translate('assigneeName')}`,
      align: 'left',
    },
    { id: 'status', label: `${translate('status')}`, align: 'left' },
    ...(!isCustomerUser
      ? [{ id: 'actions', label: `${translate('actions')}`, align: 'right' }]
      : []),
  ];

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await dispatch(removeTask(Number(id)));
      if (!propertyId) {
        if (!serviceRequestId) {
          await dispatch(getTasks());
        } else {
          await dispatch(getServiceRequestTasks(Number(serviceRequestId)));
        }
      } else {
        await dispatch(getPropertyTasks(Number(propertyId)));
      }
      const deleteRow = tableData.filter(
        (row) => Number(row.id) !== Number(id)
      );
      setSelected([]);
      setTableData(deleteRow);

      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    try {
      if (await deleteHandlers.deleteRows(selectedRows, removeTask)) {
        return;
      }

      const filteredRows = tableData.filter((row: any) => {
        return !selectedRows.includes(row.id.toString());
      });
      setSelected([]);
      setTableData(filteredRows);

      if (page > 0) {
        if (selectedRows.length === dataInPage.length) {
          setPage(page - 1);
        } else if (selectedRows.length === dataFiltered.length) {
          setPage(0);
        } else if (selectedRows.length > dataInPage.length) {
          const newPage =
            Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
            1;
          setPage(newPage);
        }
      }

      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleEditRow = (id: string) => {
    savePageToStorage('task', page);
    saveRowsPerPageToStorage('task', rowsPerPage);
    if (serviceRequestId) {
      dispatch(setLastUrlTasks(SERVICE_REQUEST));
      navigate(PATH_DASHBOARD.task.view(id));
    } else if (roomId) {
      dispatch(setLastUrlTasks(ROOM));
      navigate(PATH_DASHBOARD.task.view(id));
    } else {
      navigate(PATH_DASHBOARD.task.view(paramCase(id)));
    }
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterRole('all');
    setFilterStatus('all');
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!propertyId && serviceRequestId && !roomId) {
        await dispatch(getServiceRequestTasks(Number(serviceRequestId)));
      } else if (propertyId) {
        if (roomId) {
          await dispatch(getRoomTasks(Number(roomId)));
        }
        await dispatch(getPropertyTasks(Number(propertyId)));
      } else {
        await dispatch(getFilteredTasks({ ...filteredData }));
      }
      dispatch(setLastUrlTasks(''));
    };
    fetchData();
  }, [dispatch, propertyId, serviceRequestId, roomId, filteredData]);

  useEffect(() => {
    setTableData(tasks);
  }, [tasks, propertyId, serviceRequestId, roomId, dispatch, filteredData]);

  function getTaskCreatePath(
    propertyId: string | undefined,
    serviceRequestId: string | undefined
  ): string | undefined {
    const propertyTasksCreate =
      propertyId && !roomId
        ? PATH_DASHBOARD.task.createTaskFromProperty(propertyId.toString())
        : '';
    const serviceRequestTasksCreate =
      serviceRequestId && !roomId
        ? PATH_DASHBOARD.task.createTaskFromServiceRequest(
            serviceRequestId.toString()
          )
        : '';
    const roomTasksCreate =
      roomId && propertyId
        ? PATH_DASHBOARD.task.createRoomTask(
            roomId.toString(),
            propertyId?.toString()
          )
        : '';
    return propertyTasksCreate || serviceRequestTasksCreate || roomTasksCreate;
  }
  const taskCreatePath = getTaskCreatePath(propertyId, serviceRequestId);

  const handleChange = async (
    event: SelectChangeEvent<typeof filteredData>,
    updating: string
  ) => {
    const {
      target: { value },
    } = event;
    setFilteredData({ ...filteredData, [updating]: value });
    await dispatch(getFilteredTasks({ ...filteredData, [updating]: value }));
  };

  const handlerNavigateBack = () => {
    if (propertyId && lastUrlRooms === PROPERTY) {
      navigate(PATH_DASHBOARD.property.rooms(propertyId));
    } else if (propertyId) {
      navigate(PATH_DASHBOARD.property.serviceRequests(propertyId));
    } else {
      navigate(PATH_DASHBOARD.general.serviceRequest);
    }
  };

  useEffect(() => {
    const savedPage = getPageFromStorage('task');
    setPage(savedPage ?? 0);
    const savedRowsPerPage = getRowsPerPageFromStorage('task');
    setRowsPerPage(savedRowsPerPage ?? 5);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.task.title')}`}</title>
      </Helmet>
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth={false}>
          {allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) &&
            taskCreatePath && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5 }}>
                <Button
                  component={RouterLink}
                  to={taskCreatePath}
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {`${translate('pages.task.newTask')}`}
                </Button>
                <Button
                  onClick={handlerNavigateBack}
                  sx={{ float: 'right', marginLeft: '13px' }}
                  type="button"
                  variant="contained"
                >
                  {`${translate('back')}`}
                </Button>
              </Box>
            )}
          {!taskCreatePath && (
            <CustomBreadcrumbs
              heading={`${translate('pages.task.heading')}`}
              links={[
                {
                  name: `${translate('dashboard')}`,
                  href: PATH_DASHBOARD.root,
                },
                {
                  name: `${translate('tasks')}`,
                  href: PATH_DASHBOARD.general.task,
                },
                { name: `${translate('pages.task.heading')}` },
              ]}
              action={
                allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES) && (
                  <Button
                    component={RouterLink}
                    to={PATH_DASHBOARD.task.create}
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    {`${translate('pages.task.newTask')}`}
                  </Button>
                )
              }
            />
          )}

          <Card>
            <Divider />
            <div style={{ display: 'flex' }}>
              <div style={{ marginLeft: 20 }}>
                <CustomSimpleFilter
                  dataToFilter={[CREATED, IN_PROGRESS, DONE]}
                  filteredData={filteredData.filteredStatus}
                  handleChange={handleChange}
                  updating={FILTERED_STATUS}
                />
              </div>
              <div style={{ width: '100%' }}>
                <TaskToolbar
                  isFiltered={isFiltered}
                  filterName={filterName}
                  onFilterName={handleFilterName}
                  onResetFilter={handleResetFilter}
                />
              </div>
            </div>

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id)
                  )
                }
                action={
                  <Tooltip title={`${translate('delete')}`}>
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={tableHead}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={
                      !allowTo(user ? user.roles : [], ADMIN_MANAGERS_ROLES)
                        ? undefined
                        : (checked) =>
                            onSelectAllRows(
                              checked,
                              tableData.map((row) => row.id)
                            )
                    }
                  />

                  <TableBody>
                    <>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <TaskTableRow
                            key={row.id}
                            row={row}
                            selected={selected.includes(row.id.toString())}
                            onSelectRow={() => onSelectRow(row.id.toString())}
                            onDeleteRow={() =>
                              handleDeleteRow(row.id.toString())
                            }
                            onEditRow={() => handleEditRow(row.id.toString())}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          tableData.length
                        )}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </>
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {selected.length > 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong>{' '}
                {selected.length > 4
                  ? translate('item_five_more')
                  : translate('item_two_four')}
                ?
              </>
            )}
            {selected.length === 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong> {translate('item_one')}?
              </>
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  inputData: ITask[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (task) =>
        task.title.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
    );
  }

  // if (filterStatus !== 'all') {
  //   inputData = inputData.filter((user) => user.status === filterStatus);
  // }
  //
  // if (filterRole !== 'all') {
  //   inputData = inputData.filter((user) => user.role === filterRole);
  // }

  return inputData;
}
