import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Box, Card, Container, Grid, Tab, Tabs } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import Iconify from '../../../components/iconify';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { AccountGeneral } from '../../../sections/@dashboard/property/account';
import { useDispatch, useSelector } from '../../../redux/store';
import { useLocation, useParams } from 'react-router-dom';
import { getProperty, getRedirectForProperty } from 'src/redux/slices/property';
import RoomtListPage from '../room/RoomListPage';
import ItemListPage from '../item/ItemListPage';
import { getPropertyItems } from 'src/redux/slices/item';
import { getPropertyRooms } from 'src/redux/slices/room';
import { getPropertyTasks } from 'src/redux/slices/task';
import { useLocales } from '../../../locales';
import ServiceRequestListPage from '../serviceRequest/ServiceRequestListPage';
import { urlHandler } from 'src/utils/navigateHandler';
import { ALL_ROLES, CUSTOMER, NO_OPERATOR_NO_CUSTOMER_ROLES } from 'src/constants';
import { setLastUrlProperties } from 'src/redux/slices/setting';
import { useAuthContext } from 'src/auth/useAuthContext';
import { allowTo } from 'src/utils/permissionHandler';
import QrCodePage from '../QrCodePage';
import useFlushNavigate from 'src/hooks/useFlushNavigate';

export default function PropertyDetailPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { property, redirect } = useSelector((state) => state.property);
  const { lastUrlProperties } = useSelector((state) => state.setting);
  const location = useLocation();
  const { translate } = useLocales();
  const [currentTab, setCurrentTab] = useState(`property/${id}/general`);
  const { user } = useAuthContext();
  const [customerId, setCustomerId] = useState<string | undefined>(undefined);
  const { flushRedux } = useFlushNavigate();

  const tabHandler = (tab: string) => {
    flushRedux();
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        await Promise.all([
          dispatch(getProperty(Number(id))),
          dispatch(getPropertyItems(Number(id))),
          dispatch(getPropertyRooms(Number(id))),
          dispatch(getPropertyTasks(Number(id))),
          dispatch(getRedirectForProperty(Number(id))),
        ]);
      };
      fetchData();
    }
  }, [dispatch, id, currentTab]);

  useEffect(() => {
    if (property) {
      setCustomerId(property.customer.id);
    }
  }, [property]);

  useEffect(() => {
    const newPathname = `/dashboard/${currentTab}`;
    window.history.pushState(null, '', newPathname);
  }, [currentTab]);

  useEffect(() => {
    let tabFromPathname = location.pathname.replace('/dashboard/', '');
    const url = urlHandler(CUSTOMER, tabFromPathname);
    if (url.length > 0) dispatch(setLastUrlProperties(url));
    tabFromPathname = tabFromPathname.replace('/customer', '');
    tabHandler(tabFromPathname);
  }, [location.pathname]);

  const TABS = [
    {
      value: `property/${id}/general`,
      label: `${translate('general')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral currentId={id} fromUrl={lastUrlProperties} />,
      roles: ALL_ROLES,
    },
    {
      value: `property/${id}/rooms`,
      label: `${translate('rooms')}`,
      icon: <Iconify icon="ic:round-receipt" />,
      component: (
        <RoomtListPage
          propertyId={id}
          customerId={customerId}
          fromUrl={lastUrlProperties}
        />
      ),
      roles: ALL_ROLES,
    },
    {
      value: `property/${id}/items`,
      label: `${translate('items')}`,
      icon: <Iconify icon="ic:round-receipt" />,
      component: <ItemListPage propertyId={id} customerId={customerId} />,
      roles: ALL_ROLES,
    },
    {
      value: `property/${id}/service-requests`,
      label: `${translate('serviceRequests')}`,
      icon: <Iconify icon="ic:round-receipt" />,
      component: (
        <ServiceRequestListPage
          propertyId={id}
          customerId={customerId}
          fromUrl={lastUrlProperties}
        />
      ),
      roles: NO_OPERATOR_NO_CUSTOMER_ROLES,
    },
    {
      value: `property/${id}/qr-code`,
      label: `${translate('pages.qrCode.heading')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <QrCodePage uuid={redirect?.uuid} uri={property?.name ?? ''} />
      ),
      roles: ALL_ROLES,
    },
  ];

  const content = (
    <>
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => tabHandler(newValue)}
      >
        {TABS.map(
          (tab) =>
            allowTo(user ? user?.roles : [], tab.roles) && (
              <Tab
                key={tab.value}
                label={tab.label}
                icon={tab.icon}
                value={tab.value}
              />
            )
        )}
      </Tabs>

      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box key={tab.value} sx={{ mt: 5 }}>
              {tab.component}
            </Box>
          )
      )}
    </>
  );

  const renderContent = () => {
    if (currentTab === TABS[0].value) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              {content}
            </Card>
          </Grid>
        </Grid>
      );
    }
    return (
      <Card sx={{ padding: '16px 16px', marginBottom: '40px' }}>{content}</Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`${translate('property')}`}: {property?.name ?? ''} | Properio.io
        </title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.property.propertyDetail')}`}
          links={[
            { name: `${translate('dashboard')}`, href: PATH_DASHBOARD.root },
            {
              name: `${translate('properties')}`,
              href: PATH_DASHBOARD.general.properties,
            },
            { name: property?.name ?? '' },
          ]}
        />

        {renderContent()}
      </Container>
    </>
  );
}
