// @mui
import { Box, BoxProps } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER, NAV } from '../../config-global';
// components
import { useSettingsContext } from '../../components/settings';
import backgroundImg from '../../assets/new-background-image.webp';

const SPACING = 8;

type Props = BoxProps & {
  setOpaqueBackground?: boolean;
};

export default function Main({
  children,
  setOpaqueBackground,
  sx,
  ...other
}: Props) {
  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          pt: `${HEADER.H_MOBILE + SPACING}px`,
          pb: `${HEADER.H_MOBILE + SPACING}px`,
          ...(isDesktop && {
            px: 2,
            pt: `${HEADER.H_DASHBOARD_DESKTOP + 80}px`,
            pb: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
          }),
          '&::before': {
            content: '""',
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: 'cover',
            opacity: setOpaqueBackground ? 0.5 : 1,
            zIndex: -1,
            backgroundAttachment: 'fixed',
          },
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        position: 'relative',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(isDesktop && {
          px: 2,
          py: `${HEADER.H_DASHBOARD_DESKTOP + SPACING}px`,
          pb: `16px`,
          width: `calc(100% - ${NAV.W_DASHBOARD}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI}px)`,
          }),
        }),
        '&::before': {
          content: '""',
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: 'cover',
          opacity: setOpaqueBackground ? 0.5 : 1,
          zIndex: -1,
        },
      }}
      {...other}
    >
      {children}
    </Box>
  );
}
