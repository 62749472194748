import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axiosAPI';
import { IAttachment, IAttachmentState } from 'src/@types/attachment';

const UPLOAD_ATTACHMENT_SUCCESS = 'upload_attachment_success';
const UPLOAD_ATTACHMENT_FAILURE = 'upload_attachment_failure';

const initialState: IAttachmentState = {
  isLoading: true,
  error: null,
  attachments: [],
  attachment: null,
};

const slice = createSlice({
  name: 'attachment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET ATTACHMENTS
    getAttachmentsSuccess(state, action) {
      state.isLoading = false;
      state.attachments = action.payload;
    },

    // GET ATTACHMENT
    getAttachmentSuccess(state, action) {
      state.isLoading = false;
      state.attachment = action.payload;
    },

    // UPDATE ATTACHMENT
    updateAttachmentSuccess(state, action) {
      state.isLoading = false;
      state.attachment = action.payload;
    },

    // CREATE ATTACHMENT
    createAttachmentSuccess(state, action) {
      state.isLoading = false;
      state.attachment = action.payload;
    },

    // REMOVE ATTACHMENT
    removeAttachmentSuccess(state, action) {
      state.isLoading = false;
      // state.item = action.payload;
    },

    uploadFileSuccess(state, action) {
      state.isLoading = false;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.attachments = [];
      state.attachment = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
const mapAttachment = (attachment: any): IAttachment => {
  return {
    id: attachment?.id ? attachment.id : '',
    name: attachment?.name ? attachment.name : '',
    description: attachment.description ? attachment.description : '',
    type: attachment?.type ? attachment?.type : null,
    authorId: attachment?.authorId ? attachment?.authorId : null,
    url: attachment?.url ? attachment?.url : null,
    createdAt: attachment?.created ? attachment.created : '',
    serviceRequestId: attachment?.serviceRequestId
      ? attachment.serviceRequestId
      : null,
    authorName: attachment?.authorName ? attachment.authorName : '',
  };
};

const mapAttachmentData = (attachment: any, fileName: string | null) => {
  return {
    name: attachment.name,
    description: attachment.description,
    fileName: fileName ? fileName : '',
  };
};

const mapAttachmentUpdateData = (attachment: any) => {
  return {
    name: attachment.name,
    description: attachment.description,
  };
};

// ----------------------------------------------------------------------

export function getAttachment(attachmentId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/attachments/' + attachmentId);

      const result: IAttachment = mapAttachment(response?.data?.data);

      dispatch(slice.actions.getAttachmentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getItemLogAttachments(itemLogId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(
        '/items/logs/' + itemLogId + '/attachments'
      );

      const result: IAttachment[] = response?.data?.data?.map(
        (attachment: any) => mapAttachment(attachment)
      );

      dispatch(slice.actions.getAttachmentsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getItemAttachments(itemId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/items/' + itemId + '/attachments');

      const result: IAttachment[] = response?.data?.data?.map(
        (attachment: any) => mapAttachment(attachment)
      );

      dispatch(slice.actions.getAttachmentsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// TODO: duplicate code
export function getServiceRequestAttachments(serviceRequestId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(
        '/service-requests/' + serviceRequestId + '/attachments'
      );

      const result: IAttachment[] = response?.data?.data?.map(
        (attachment: any) => mapAttachment(attachment)
      );

      dispatch(slice.actions.getAttachmentsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getReminderAttachments(reminderId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`/reminders/${reminderId}/attachments`);
      const result: IAttachment[] = response?.data?.data?.map(
        (attachment: any) => mapAttachment(attachment)
      );
      dispatch(slice.actions.getAttachmentsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createItemLogAttachment(
  itemLogId: number,
  data: any,
  fileName: string | null
) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/items/logs/' + itemLogId + '/attachments',
        mapAttachmentData(data, fileName)
      );

      const result: IAttachment = mapAttachment(response?.data?.data);

      dispatch(slice.actions.createAttachmentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createItemAttachment(
  itemId: number,
  data: any,
  fileName: string | null
) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/items/' + itemId + '/attachments',
        mapAttachmentData(data, fileName)
      );

      const result: IAttachment = mapAttachment(response?.data?.data);

      dispatch(slice.actions.createAttachmentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createServiceRequestAttachment(
  serviceRequestId: number,
  data: any,
  fileName: string | null
) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/service-requests/' + serviceRequestId + '/attachments',
        mapAttachmentData(data, fileName)
      );

      const result: IAttachment = mapAttachment(response?.data?.data);

      dispatch(slice.actions.createAttachmentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createReminderAttachment(
  reminderId: number,
  data: any,
  fileName: string | null
) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/reminders/' + reminderId + '/attachments',
        mapAttachmentData(data, fileName)
      );

      const result: IAttachment = mapAttachment(response?.data?.data);

      dispatch(slice.actions.createAttachmentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeItemLogAttachment(
  itemLogId: number,
  attachmentId: number
) {
  return async (dispatch: Dispatch) => {
    try {
      // TODO: I changed the EP because itemLogId is not relevant when targeting an Attachment
      // const response = await axios.delete('/items/logs' + itemLogId + '/attachments/' + attachmentId, {
      const response = await axios.delete('/attachments/' + attachmentId, {
        params: { attachmentId },
      });

      const result = response?.data;

      dispatch(slice.actions.removeAttachmentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateAttachment(attachmentId: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        '/attachments/' + attachmentId,
        mapAttachmentUpdateData(data)
      );

      const result = mapAttachment(response?.data);

      dispatch(slice.actions.updateAttachmentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeAttachment(attachmentId: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/attachments/' + attachmentId, {
        params: { attachmentId },
      });

      const result = response?.data;

      dispatch(slice.actions.removeAttachmentSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function uploadAttachment(file: File) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('file', file);

      // TODO: tidy up
      const response = await axios.post('/attachments', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const result = response?.data;

      // TODO: this is just quick hack
      dispatch({ type: UPLOAD_ATTACHMENT_SUCCESS, payload: result }); // Dispatch your success action
      // TODO: the is no reason to have result in the uploadFileSuccess function
      dispatch(slice.actions.uploadFileSuccess(result));

      return result;
    } catch (error) {
      dispatch({ type: UPLOAD_ATTACHMENT_FAILURE, payload: error.message });
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeUploadedFile(fileName: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // TODO: tidy up
      const response = await axios.delete(`/attachments/${fileName}/blob`);

      const result = response?.data;

      // TODO: the is no reason to have result in the uploadFileSuccess function
      // dispatch(slice.actions.uploadFileSuccess(result));

      return result;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function resetAttachments() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
