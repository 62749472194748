import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosAPI';
import { FilterData, ITask, ITaskState } from 'src/@types/task';

// ----------------------------------------------------------------------

const initialState: ITaskState = {
  isLoading: true,
  error: null,
  tasks: [],
  task: null,
};

const slice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET tasks
    getTasksSuccess(state, action) {
      state.tasks = action.payload;
      state.isLoading = false;
    },

    // GET task
    getTaskSuccess(state, action) {
      state.task = action.payload;
      state.isLoading = false;
    },

    // UPDATE task
    updateTaskSuccess(state, action) {
      state.task = action.payload;
      state.isLoading = false;
    },

    // CREATE task
    createTaskSuccess(state, action) {
      state.task = action.payload;
      state.tasks.push(action.payload);
      state.isLoading = false;
    },

    // REMOVE task
    removeTaskSuccess(state, action) {
      state.isLoading = false;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.tasks = [];
      state.task = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
const maptask = (task: any): ITask => {
  return {
    id: task?.id ? task.id : '',
    title: task?.title ? task.title : '',
    description: task?.description ? task.description : '',
    status: task?.status ? task.status : '',
    assignee: task?.assignee ? task.assignee : '',
    serviceRequest: task?.serviceRequest ? task.serviceRequest : '',
    serviceRequestId: task?.serviceRequestId ? task.serviceRequestId : '',
    createdAt: task?.createdAt ? task.createdAt : '',
    updatedAt: task?.updatedAt ? task.updatedAt : '',
    property: task?.property ? task.property : '',
    roomId: task?.roomId ? task.roomId : '',
    room: task?.room ? task.room : '',
  };
};

const maptaskAccount = (task: any) => {
  return {
    title: task?.title,
    description: task?.description,
    assigneeId: task?.assigneeId !== '' ? task.assigneeId : null,
    serviceRequestId:
      task?.serviceRequestId !== '' ? task.serviceRequestId : null,
    propertyId: task?.propertyId !== '' ? task.propertyId : null,
    roomId: task?.roomId !== '' ? task.roomId : null,
  };
};

// ----------------------------------------------------------------------

export function getTasks() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/tasks');
      const result: ITask[] = response?.data?.data?.map((task: any) =>
        maptask(task)
      );
      dispatch(slice.actions.getTasksSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function getPropertyTasks(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/properties/' + id + '/tasks');

      const result: ITask[] = response?.data?.data?.map((item: any) =>
        maptask(item)
      );

      dispatch(slice.actions.getTasksSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function getServiceRequestTasks(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/service-requests/' + id + '/tasks');
      const result: ITask[] = response?.data?.data?.map((task: any) =>
        maptask(task)
      );
      dispatch(slice.actions.getTasksSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function getRoomTasks(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/rooms/' + id + '/tasks');

      const result: ITask[] = response?.data?.data?.map((item: any) =>
        maptask(item)
      );

      dispatch(slice.actions.getTasksSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

// ----------------------------------------------------------------------

export function getTask(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/tasks/' + id);
      const result: ITask = maptask(response?.data?.data);
      dispatch(slice.actions.getTaskSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function updateTask(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch('/tasks/' + id, maptaskAccount(data));
      const result: ITask = maptask(response?.data?.data);
      dispatch(slice.actions.updateTaskSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function createTask(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post('/tasks/', maptaskAccount(data));
      const result: ITask = maptask(response?.data?.data);
      dispatch(slice.actions.createTaskSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function getFilteredTasks(data: FilterData) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/tasks', {
        params: {
          status: data.filteredStatus,
        },
      });

      const result: ITask[] = response?.data?.data?.map((serviceRequest: any) =>
        maptask(serviceRequest)
      );

      dispatch(slice.actions.getTasksSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeTask(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/tasks/' + id);
      const result = response?.data;
      dispatch(slice.actions.removeTaskSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('Error during removing tasks: ' + error.message);
    }
  };
}

export function updateToStartTask(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/tasks/' + id + '/start');
      const result = response?.data;
      dispatch(slice.actions.updateTaskSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function updateToCompleteTask(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/tasks/' + id + '/complete');
      const result = response?.data;
      dispatch(slice.actions.updateTaskSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new error();
    }
  };
}

export function resetTasks() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
