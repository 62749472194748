import { useState } from 'react';
// @mui
import {
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableCell,
  Typography,
  Tooltip,
} from '@mui/material';
// @types

// components
import ConfirmDialog from '../../../../components/confirm-dialog';
import React from 'react';
import { IItemTag } from 'src/@types/itemTag';
import { useLocales } from '../../../../locales';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// ----------------------------------------------------------------------

type Props = {
  row: IItemTag;
  selected: boolean;
  onEditRow: VoidFunction;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function UserOriginalTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const { name } = row;
  const { translate } = useLocales();

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell padding="checkbox">
          <Checkbox checked={selected} onClick={onSelectRow} />
        </TableCell>

        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography
              variant="subtitle2"
              onClick={() => onEditRow()}
              style={{ cursor: 'pointer' }}
              noWrap
            >
              {name}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell align="right">
          <Tooltip title={`${translate('edit')}`} sx={{ cursor: 'pointer' }}>
            <EditIcon
              onClick={() => {
                onEditRow();
                handleClosePopover();
              }}
            />
          </Tooltip>
          <Tooltip title={`${translate('delete')}`} sx={{ cursor: 'pointer' }}>
            <DeleteIcon
              onClick={() => {
                handleOpenConfirm();
                handleClosePopover();
              }}
            />
          </Tooltip>
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={`${translate('infoMessages.deletePromptV2')}`}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDeleteRow();
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}
