import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosAPI';
import { IRoom, IRoomState } from '../../@types/room';
import { IRedirect } from 'src/@types/redirect';

// ----------------------------------------------------------------------

const initialState: IRoomState = {
  isLoading: false,
  error: null,
  rooms: [],
  room: null,
  redirect: null,
};

const slice = createSlice({
  name: 'room',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET rooms
    getRoomsSuccess(state, action) {
      state.rooms = action.payload;
      state.isLoading = false;
    },

    // GET room
    getRoomSuccess(state, action) {
      state.room = action.payload;
      state.isLoading = false;
    },

    getRedirectSuccess(state, action) {
      state.redirect = action.payload;
      state.isLoading = false;
    },

    // UPDATE room
    updateRoomSuccess(state, action) {
      state.room = action.payload;
      state.isLoading = false;
    },

    // CREATE room
    createRoomSuccess(state, action) {
      state.room = action.payload;
      state.isLoading = false;
    },

    // REMOVE room
    removeRoomSuccess(state, action) {
      state.isLoading = false;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.rooms = [];
      state.room = null;
      state.redirect = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
const mapRoom = (room: any): IRoom => {
  return {
    id: room?.id ? room.id : '',
    name: room?.name ? room.name : '',
    parentRoom: room?.parentRoom ? room.parentRoom : null,
    property: room?.property ? room.property : null,
    subRooms: room?.subRooms ? room.subRooms : [],
    created: room?.created ? room.created : '',
    updated: room?.updated ? room.updated : '',
  };
};

const mapRoomAccount = (room: any) => {
  return {
    name: room?.name,
    propertyId: room?.propertyId !== '' ? room.propertyId : null,
    parentRoomId: room?.parentRoomId !== '' ? room.parentRoomId : null,
  };
};

// ----------------------------------------------------------------------

export function getRooms() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/rooms');

      const result: IRoom[] = response?.data?.data?.map((room: any) =>
        mapRoom(room)
      );

      dispatch(slice.actions.getRoomsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getPropertyRooms(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/properties/' + id + '/rooms');

      const result: IRoom[] = response?.data?.data?.map((room: any) =>
        mapRoom(room)
      );

      dispatch(slice.actions.getRoomsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getRoom(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/rooms/' + id);

      const result: IRoom = mapRoom(response?.data?.data);

      dispatch(slice.actions.getRoomSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getRedirectForRoom(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/rooms/' + id + '/redirect', {
        params: { id },
      });

      const result: IRedirect = response?.data?.data;

      dispatch(slice.actions.getRedirectSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateRoom(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch('/rooms/' + id, mapRoomAccount(data));

      const result: IRoom = mapRoom(response?.data?.data);

      dispatch(slice.actions.updateRoomSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createRoom(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post('/rooms/', mapRoomAccount(data));

      const result: IRoom = mapRoom(response?.data?.data);

      dispatch(slice.actions.createRoomSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeRoom(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/rooms/' + id);

      const result = response?.data;

      dispatch(slice.actions.removeRoomSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('Error deleting room: ' + error.message);
    }
  };
}

export function resetRooms() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
