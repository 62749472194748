import { createSlice, Dispatch } from '@reduxjs/toolkit';

const initialState = {
  lastUrlItems: '',
  lastUrlTasks: '',
  lastUrlProperties: '',
  lastUrlServiceRequests: '',
  lastUrlReminder: '',
  lastUrlRooms: '',
};

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setLastUrlItems(state, action) {
      state.lastUrlItems = action.payload;
    },
    setLastUrlTasks(state, action) {
      state.lastUrlTasks = action.payload;
    },
    setLastUrlProperties(state, action) {
      state.lastUrlProperties = action.payload;
    },
    setLastUrlServiceRequests(state, action) {
      state.lastUrlServiceRequests = action.payload;
    },
    setLastUrlReminder(state, action) {
      state.lastUrlReminder = action.payload;
    },
    setLastUrlRooms(state, action) {
      state.lastUrlRooms = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

export function setLastUrlItems(url: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLastUrlItems(url));
  };
}

export function setLastUrlTasks(url: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLastUrlTasks(url));
  };
}

export function setLastUrlProperties(url: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLastUrlProperties(url));
  };
}

export function setLastUrlServiceRequests(url: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLastUrlServiceRequests(url));
  };
}

export function setLastUrlReminder(url: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLastUrlReminder(url));
  };
}

export function setLastUrlRooms(url: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setLastUrlRooms(url));
  };
}
