import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// assets
import { countries } from '../../../assets/data';
// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { getCustomer, updateCustomer } from '../../../redux/slices/customer';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type FormValuesProps = {
  id: string | null;
  name: string;
  country: string;
  street: string;
  city: string;
  zip: string;
  businessId: string;
  vatId: string;
  taxId: string;
  description: string;
};

type Props = {
  id: string | number | undefined;
};

export default function AccountGeneral({ id }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const [editing, setEditing] = useState<boolean>(false);

  const { customer } = useSelector((state) => state.customer);

  const [defaultValues, setDefaultValues] = useState<FormValuesProps>({
    id: null,
    name: '',
    country: '',
    street: '',
    city: '',
    zip: '',
    businessId: '',
    vatId: '',
    taxId: '',
    description: '',
  });

  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    if (id && !isDataFetched) {
      dispatch(getCustomer(Number(id))).then(() => {
        setIsDataFetched(true);
      });
    }
  }, [dispatch, id, isDataFetched]);

  useEffect(() => {
    if (customer) {
      setDefaultValues((prevDefaultValues) => ({
        ...prevDefaultValues,
        id: customer?.id || '2',
        name: customer?.name || '',
        country: customer?.address?.country || '',
        street: customer?.address?.street || '',
        city: customer?.address?.city || '',
        zip: customer?.address?.zip || '',
        businessId: customer?.businessId || '',
        vatId: customer?.vatId || '',
        taxId: customer?.taxId || '',
        description: customer?.description || '',
      }));
      reset(defaultValues);
    }
  }, [customer]);

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    country: Yup.string().required(`${translate('isRequired.country')}`),
    street: Yup.string().required(`${translate('isRequired.street')}`),
    city: Yup.string().required(`${translate('isRequired.city')}`),
    zip: Yup.string().required(`${translate('isRequired.zipCode')}`),
    description: Yup.string().required(
      `${translate('isRequired.description')}`
    ),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    values: defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(updateCustomer(Number(customer?.id), data));
      reset();
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      navigate(PATH_DASHBOARD.general.customers);
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 5 }}>
          <Button
            onClick={() => navigate(PATH_DASHBOARD.general.customers)}
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('name')}`}
              value={values.name}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
            <RHFTextField
              name="description"
              label={`${translate('description')}`}
              value={values.description}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
            <RHFTextField
              name="businessId"
              label={`${translate('businessId')}`}
              value={values.businessId}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
            />

            <RHFTextField
              name="vatId"
              label={`${translate('vatId')}`}
              value={values.vatId}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
            />

            <RHFTextField
              name="taxId"
              label={`${translate('taxId')}`}
              value={values.taxId}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
            />

            <RHFTextField
              name="street"
              label={`${translate('street')}`}
              value={values.street}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
            <RHFTextField
              name="city"
              label={`${translate('city')}`}
              value={values.city}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />

            <RHFSelect
              native
              name="country"
              label={`${translate('country')}`}
              placeholder={`${translate('country')}`}
              value={values.country}
              disabled={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            >
              <option value="" />
              {countries.map((country) => (
                <option key={country.code} value={country.label}>
                  {country.label}
                </option>
              ))}
            </RHFSelect>

            <RHFTextField
              name="zip"
              label={`${translate('zipCode')}`}
              value={values.zip}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
          </Box>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            {editing && (
              <>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {`${translate('saveChanges')}`}
                </LoadingButton>
                <Button
                  onClick={() => setEditing(false)}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  {`${translate('close')}`}
                </Button>
              </>
            )}
            {!editing && (
              <Button
                onClick={() => setEditing(true)}
                sx={{ ml: 1 }}
                variant="contained"
              >
                {`${translate('edit')}`}
              </Button>
            )}
          </Box>
        </Card>
      </FormProvider>
    </Container>
  );
}
