import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
import { countries } from '../../../assets/data';
// components
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { getProperty, updateProperty } from '../../../redux/slices/property';
import { getCustomers } from 'src/redux/slices/customer';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../locales';
import { getPropertyWithPropertyManagersRole } from 'src/redux/slices/user';
import { CUSTOMER, ONLY_ADMIN_COMPANY_MANAGER_ROLES } from 'src/constants';
import { allowTo } from 'src/utils/permissionHandler';
import { IUserOriginal } from 'src/@types/user';

// ----------------------------------------------------------------------

type FormValuesProps = {
  name: string;
  company: string;
  country: string;
  street: string;
  city: string;
  zip: string;
  customerId: number | string;
  managerId: number | string;
};

type Props = {
  currentId: string | number | undefined;
  creating?: boolean;
  fromUrl: string;
};

export default function AccountGeneral({
  currentId,
  creating,
  fromUrl,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { property } = useSelector((state) => state.property);
  const { customers } = useSelector((state) => state.customer);
  const { users } = useSelector((state) => state.user);
  const [editing, setEditing] = useState<boolean>(false);

  const defaultValues = {
    name: property?.name || '',
    country: property?.address?.country || '',
    street: property?.address?.street || '',
    city: property?.address?.city || '',
    zip: property?.address?.zip || '',
    customerId: creating ? Number(currentId) : property?.customerId || '',
    managerId: property?.managerId || '',
    company: property?.company || '',
  };

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.name')}`),
    country: Yup.string().required(`${translate('isRequired.country')}`),
    street: Yup.string().required(`${translate('isRequired.street')}`),
    city: Yup.string().required(`${translate('isRequired.city')}`),
    zip: Yup.string().required(`${translate('isRequired.zipCode')}`),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    values: defaultValues,
  });

  const {
    watch,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(updateProperty(Number(property?.id), data));
      handlerNavigate();
      reset();
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  const handlerNavigate = () => {
    switch (fromUrl) {
      case CUSTOMER:
        navigate(
          PATH_DASHBOARD.customer.properties(values.customerId.toString())
        );
        break;
      default:
        navigate(PATH_DASHBOARD.general.properties);
        break;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (currentId) {
        await Promise.all([
          dispatch(getProperty(Number(currentId))),
          dispatch(getCustomers()),
          dispatch(getPropertyWithPropertyManagersRole()),
        ]);
      }
    };
    fetchData();
  }, [currentId]);

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 5 }}>
          <Button
            onClick={handlerNavigate}
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('name')}`}
              value={values.name}
              variant={!editing ? 'standard' : 'outlined'}
              readOnly={!editing}
              required
            />
            <RHFSelect
              native
              name="managerId"
              label={`${translate('manager')}`}
              placeholder={`${translate('manager')}`}
              value={values?.managerId}
              variant={!editing ? 'standard' : 'outlined'}
              disabled={!editing}
              required
            >
              {users.map((manager: IUserOriginal) => (
                <option key={manager.id} value={manager.id}>
                  {manager.firstName} {manager.lastName}
                </option>
              ))}
            </RHFSelect>

            <RHFSelect
              native
              name="customerId"
              label={`${translate('customer')}`}
              placeholder={`${translate('customer')}`}
              value={values?.customerId}
              readOnly={creating || !editing}
              disabled={creating || !editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            >
              {customers.map((customer) => {
                return (
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                );
              })}
            </RHFSelect>
            <RHFTextField
              name="street"
              label={`${translate('street')}`}
              value={values.street}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
            <RHFTextField
              name="zip"
              label={`${translate('zipCode')}`}
              value={values.zip}
              variant={!editing ? 'standard' : 'outlined'}
              readOnly={!editing}
              required
            />
            <RHFTextField
              name="city"
              label={`${translate('city')}`}
              value={values.city}
              variant={!editing ? 'standard' : 'outlined'}
              readOnly={!editing}
              required
            />
            <RHFSelect
              native
              name="country"
              label={`${translate('country')}`}
              placeholder="Country"
              value={values.country}
              variant={!editing ? 'standard' : 'outlined'}
              disabled={!editing}
              required
            >
              {allowTo(
                user ? user.roles : [],
                ONLY_ADMIN_COMPANY_MANAGER_ROLES
              ) &&
                editing && <option value="" />}

              {allowTo(
                user ? user.roles : [],
                ONLY_ADMIN_COMPANY_MANAGER_ROLES
              ) && editing ? (
                countries.map((country) => (
                  <option key={country.label} value={country.label}>
                    {country.label}
                  </option>
                ))
              ) : (
                <option
                  value={
                    countries.find(
                      (country) => country.label === values.country
                    )?.label
                  }
                  key={values.country}
                >
                  {values.country}
                </option>
              )}
            </RHFSelect>
          </Box>

          {allowTo(user ? user.roles : [], ONLY_ADMIN_COMPANY_MANAGER_ROLES) &&
            editing && (
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ mr: 1 }}
                  loading={isSubmitting}
                >
                  {`${translate('saveChanges')}`}
                </LoadingButton>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => setEditing(false)}
                >
                  {`${translate('close')}`}
                </Button>
              </Box>
            )}
          {allowTo(user ? user.roles : [], ONLY_ADMIN_COMPANY_MANAGER_ROLES) &&
            !editing && (
              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => setEditing(true)}
                >
                  {`${translate('edit')}`}
                </Button>
              </Stack>
            )}
        </Card>
      </FormProvider>
    </Container>
  );
}
