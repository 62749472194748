import { Container } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useParams } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../locales';
import AttachmentCreateForm from 'src/sections/@dashboard/attachment/create/AttachmentCreateForm';
import { Card, Grid } from '@mui/material';

// TODO: this page duplicate
export default function AttachmentCreateForReminderPage() {
  const { translate } = useLocales();
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.attachment.createNewAttachment')}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.attachment.createNewAttachment')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            { name: `${translate('pages.attachment.createNewAttachment')}` },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              <AttachmentCreateForm reminderId={Number(id)} />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
