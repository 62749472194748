import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosAPI';
import { ICustomer, ICustomerState } from '../../@types/customer';

// ----------------------------------------------------------------------

const initialState: ICustomerState = {
  isLoading: true,
  error: null,
  customers: [],
  customer: null,
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CUSTOMERS
    getCustomersSuccess(state, action) {
      state.isLoading = false;
      state.customers = action.payload;
    },

    // GET CUSTOMER
    getCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customer = action.payload;
    },

    // UPDATE CUSTOMER
    updateCustomerSuccess(state, action) {
      state.customer = action.payload;
      state.isLoading = false;
    },

    // UPDATE CUSTOMER
    createCustomerSuccess(state, action) {
      state.isLoading = false;
      state.customer = action.payload;
    },

    // REMOVE CUSTOMER
    removeCustomerSuccess(state, action) {
      state.isLoading = false;
      // state.customer = action.payload;
    },

    // SET CUSTOMER TO NULL
    setCustomerToNull(state) {
      state.customer = null;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.customers = [];
      state.customer = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
const mapCustomer = (customer: any): ICustomer => {
  return {
    id: customer?.id ? customer.id : '',
    name: customer?.name ? customer.name : '',
    businessId: customer?.businessId ? customer.businessId : '',
    vatId: customer?.vatId ? customer.vatId : '',
    taxId: customer?.taxId ? customer.taxId : '',
    description: customer?.description ? customer.description : '',
    address: {
      city: customer?.city ? customer.city : '',
      street: customer?.street ? customer.street : '',
      zip: customer?.zip ? customer.zip : '',
      country: customer?.country ? customer.country : '',
    },
  };
};

const mapCustomerAccount = (customer: any) => {
  return {
    name: customer?.name,
    street: customer?.street,
    city: customer?.city,
    zip: customer?.zip,
    country: customer?.country,
    long: 'UpdatedLong',
    lat: 'UpdatedLat',
    businessId: customer?.businessId,
    vatId: customer?.vatId,
    taxId: customer?.taxId,
    description: customer?.description === '' ? null : customer?.description,
  };
};

// ----------------------------------------------------------------------

export function getCustomers() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/customers');

      const result: ICustomer[] = response?.data?.data?.map((customer: any) =>
        mapCustomer(customer)
      );

      dispatch(slice.actions.getCustomersSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getCustomerByUserCustomerRole() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/customers/getByUser');

      const result: ICustomer = response?.data?.data;

      dispatch(slice.actions.getCustomerSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getCustomerCities(cities: string[]) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/customers', {
        params: { city: cities },
      });

      const result: ICustomer[] = response?.data?.data?.map((customer: any) =>
        mapCustomer(customer)
      );

      dispatch(slice.actions.getCustomersSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getCustomer(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/customers/' + id);

      const result: ICustomer = mapCustomer(response?.data?.data);

      dispatch(slice.actions.getCustomerSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateCustomer(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        '/customers/' + id,
        mapCustomerAccount(data)
      );

      const result: ICustomer = mapCustomer(response?.data?.data);

      dispatch(slice.actions.updateCustomerSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createCustomer(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/customers/',
        mapCustomerAccount(data)
      );

      const result: ICustomer = mapCustomer(response?.data?.data);

      dispatch(slice.actions.createCustomerSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeCustomer(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/customers/' + id, {
        params: { id },
      });

      const result = response?.data;

      dispatch(slice.actions.removeCustomerSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function setCustomerToNull() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setCustomerToNull());
  };
}

export function resetCustomers() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
