import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Stack } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { dispatch, useSelector } from 'src/redux/store';
import { IItemCategory } from 'src/@types/itemCategory';
import { getItemCategoriesWithoutParent, updateItemCategory } from 'src/redux/slices/itemCategory';
import { useEffect, useState } from 'react';
import { useLocales } from '../../../locales';

interface FormValuesProps {
  parentId: string | number;
  name: string;
}

type Props = {
  itemCategory?: IItemCategory;
  itemCategorySuperior?: IItemCategory;
};

export default function ItemCategoryNewEditForm({
  itemCategory,
  itemCategorySuperior,
}: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const [editing, setEditing] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { itemCategories } = useSelector((state) => state.itemCategory);

  const UpdatePropertySchema = Yup.object().shape({
    name: Yup.string().required(`${translate('isRequired.firstName')}`),
  });

  const isItemCategorySuperior = itemCategorySuperior?.name !== '';

  useEffect(() => {
    dispatch(getItemCategoriesWithoutParent());
  }, [dispatch]);

  const defaultValues = {
    name: itemCategory?.name || '',
    parentId: itemCategory?.itemCategoryParent?.id
      ? itemCategory?.itemCategoryParent?.id
      : '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(updateItemCategory(Number(itemCategory?.id), data));
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      reset();
      navigate(PATH_DASHBOARD.configuration.itemCategoryList);
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 5 }}>
          <Button
            onClick={() =>
              navigate(PATH_DASHBOARD.configuration.itemCategoryList)
            }
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('name')}`}
              value={values.name}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />

            {!isItemCategorySuperior && (
              <RHFSelect
                native
                name="parentId"
                label={`${translate('parentCategory')}`}
                placeholder={`${translate('parentCategory')}`}
                value={values.parentId}
                disabled={!editing}
                variant={!editing ? 'standard' : 'outlined'}
              >
                {!itemCategory?.itemCategoryParent?.id && (
                  <option key={''} value=""></option>
                )}
                {itemCategories.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </RHFSelect>
            )}
          </Box>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            {editing && (
              <>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {`${translate('saveChanges')}`}
                </LoadingButton>
                <Button
                  onClick={() => setEditing(false)}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  {`${translate('close')}`}
                </Button>
              </>
            )}
            {!editing && (
              <Button
                onClick={() => setEditing(true)}
                sx={{ ml: 1 }}
                variant="contained"
              >
                {`${translate('edit')}`}
              </Button>
            )}
          </Box>
        </Card>
      </FormProvider>
    </Container>
  );
}
