import { Helmet } from 'react-helmet-async';
// @mui
import { Stack, Typography, useTheme } from '@mui/material';
// assets
import { useLocales } from '../../locales';
// ----------------------------------------------------------------------

export default function DashboardPage() {
  const { translate } = useLocales();

  const theme = useTheme();

  return (
    <div>
      <Helmet>
        <title>Dashboard | Properio.io</title>
      </Helmet>

      <Stack sx={{ alignItems: 'center' }}>
        <div
          style={{
            marginTop: 15,
            textAlign: 'center',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            paddingRight: '7px',
            paddingLeft: '7px',
          }}
        >
          <Typography variant="h4">
            {`${translate('pages.dashboard.title')}`},
            <br />
            {`${translate('pages.dashboard.text')}`}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            width: '100%',
            marginTop: '50px',
          }}
        ></div>
      </Stack>
    </div>
  );
}
