import * as Yup from 'yup';
import { useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Button, Card, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getCustomerByUserCustomerRole, getCustomers } from '../../../../redux/slices/customer';
import { getProperties, getProperty } from 'src/redux/slices/property';
import { useNavigate } from 'react-router-dom';
import { createServiceRequest } from 'src/redux/slices/serviceRequest';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useLocales } from '../../../../locales';
import { LOW, ROLES_PERMISSIONS, SERVICE_REQUEST_PRIORITY } from 'src/constants';
import { useAuthContext } from 'src/auth/useAuthContext';
import { IItem } from 'src/@types/item';
import { getPropertyItems } from 'src/redux/slices/item';

// ----------------------------------------------------------------------

type ServiceRequestCreateFormProps = {
  propertyId: number | string;
  customerId: number | string;
  name: string;
  description: string;
};

type FormValuesProps = {
  propertyId: number | string;
  customerId: number | string;
  name: string;
  description: string;
  priority: string;
  itemId?: number | string;
};

export default function ServiceRequestCreateForm({
  propertyId,
  customerId,
}: ServiceRequestCreateFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const { user } = useAuthContext();
  const { customers, customer } = useSelector((state) => state.customer);
  const { properties, property } = useSelector((state) => state.property);
  const { items } = useSelector((state) => state.item);

  const isCustomer =
    user?.roles.includes(ROLES_PERMISSIONS.customer) && user.roles.length === 1;
  const getInitialCustomerId = () => {
    if (isCustomer) {
      return user?.customerId;
    }
    if (propertyId && property?.customerId) {
      return property?.customerId;
    }
    return '';
  };

  const defaultValues = {
    name: '',
    description: '',
    customerId: getInitialCustomerId(),
    propertyId: propertyId || '',
    priority: LOW,
    itemId: '',
  };

  const UpdatePropertySchema = Yup.object().shape({
    description: Yup.string().required(
      `${translate('isRequired.description')}`
    ),
    name: Yup.string().required(`${translate('isRequired.name')}`),
    customerId: Yup.number()
      .typeError(`${translate('isRequired.customerId')}`)
      .required(`${translate('isRequired.customerId')}`),
    propertyId: Yup.number()
      .typeError(`${translate('isRequired.property')}`)
      .required(`${translate('isRequired.property')}`),
    priority: Yup.string().required(`${translate('isRequired.priority')}`),
    itemId: Yup.string().optional(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const backPath = propertyId
    ? PATH_DASHBOARD.property.serviceRequests(propertyId.toString())
    : PATH_DASHBOARD.general.serviceRequest;

  const {
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(createServiceRequest(data));
      reset();
      navigate(backPath);
      enqueueSnackbar(`${translate('infoMessages.successCreate')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotCreate')}`, {
        variant: 'error',
      });
    }
  };

  const createOptionsForSelect = () => {
    if (isCustomer) {
      return (
        <option value={customer?.id} key={customer?.id}>
          {customer?.name}
        </option>
      );
    }
    return (
      <>
        <option key={''} value=""></option>
        {customers.map((customer) => (
          <option key={customer.id} value={customer.id}>
            {customer.name}
          </option>
        ))}
      </>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        user?.roles.includes(ROLES_PERMISSIONS.customer) &&
        user.roles.length === 1
      ) {
        await dispatch(getCustomerByUserCustomerRole());
      } else {
        await dispatch(getCustomers());
      }
      if (propertyId) {
        await dispatch(getProperty(Number(propertyId)));
      }
      await dispatch(getProperties());
    };
    fetchData();
  }, []);

  const propertyHandler = async (propertyId: string) => {
    setValue('propertyId', propertyId);
    await dispatch(getPropertyItems(Number(propertyId)));
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} alignItems="flex-end" sx={{ mb: 3 }}>
        <Button
          onClick={() => navigate(backPath)}
          sx={{ float: 'right' }}
          type="button"
          variant="contained"
        >
          {`${translate('back')}`}
        </Button>
      </Stack>
      <Card sx={{ p: 3 }}>
        <Box
          rowGap={3}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
          }}
        >
          <RHFTextField
            name="name"
            label={`${translate('name')}`}
            value={values.name}
            required
          />
          <RHFSelect
            native
            name="customerId"
            label={`${translate('customer')}`}
            placeholder={`${translate('customer')}`}
            value={values.customerId}
            disabled={
              isCustomer || (propertyId && property?.customerId) ? true : false
            }
            required
          >
            {createOptionsForSelect()}
          </RHFSelect>
          <RHFTextField
            name="description"
            label={`${translate('description')}`}
            value={values.description}
            multiline
            minRows={3}
            required
          />
          <RHFSelect
            native
            name="propertyId"
            label={`${translate('property')}`}
            placeholder={`${translate('property')}`}
            onChange={(e) => propertyHandler(e.target.value)}
            value={values.propertyId}
            disabled={propertyId ? true : false}
            required
          >
            <option key={''} value=""></option>
            {properties.map((property) => (
              <option key={property.id} value={property.id}>
                {property.name}
              </option>
            ))}
          </RHFSelect>
          <RHFSelect
            native
            name="priority"
            label={`${translate('pages.serviceRequest.priority')}`}
            placeholder={`${translate('pages.serviceRequest.priority')}`}
            value={values.priority}
            required
          >
            {SERVICE_REQUEST_PRIORITY.map((priority) => (
              <option key={priority.value} value={priority.value}>
                {`${translate(`${priority.value}`)}`}
              </option>
            ))}
          </RHFSelect>
          <RHFSelect
            native
            name="itemId"
            label={`${translate('item')}`}
            placeholder={`${translate('item')}`}
            disabled={values.propertyId ? false : true}
            value={values.itemId}
          >
            <option key={''} value=""></option>
            {items.map((item: IItem) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </RHFSelect>
        </Box>

        <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
          {/*<RHFTextField name="about" multiline rows={4} label="About" />*/}

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate('pages.serviceRequest.createRequest')}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
