import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosAPI';
import { FilterData, IProperty, IPropertyState } from '../../@types/property';
import { IRedirect } from 'src/@types/redirect';

// ----------------------------------------------------------------------

const initialState: IPropertyState = {
  isLoading: false,
  error: null,
  properties: [],
  property: null,
  redirect: null,
};

const slice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET PROPERTIES
    getPropertiesSuccess(state, action) {
      state.properties = action.payload;
      state.isLoading = false;
    },

    // GET PROPERTY
    getPropertySuccess(state, action) {
      state.property = action.payload;
      state.isLoading = false;
    },

    getRedirectSuccess(state, action) {
      state.redirect = action.payload;
      state.isLoading = false;
    },

    // UPDATE PROPERTY
    updatePropertySuccess(state, action) {
      state.property = action.payload;
      state.isLoading = false;
    },

    // CREATE PROPERTY
    createPropertySuccess(state, action) {
      state.property = action.payload;
      state.isLoading = false;
    },

    // REMOVE PROPERTY
    removePropertySuccess(state, action) {
      state.isLoading = false;
    },

    // SET PROPERTY TO NULL
    setPropertyToNull(state) {
      state.property = null;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.properties = [];
      state.property = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
const mapProperty = (property: any): IProperty => {
  return {
    id: property?.id ? property.id : '',
    name: property?.name ? property.name : '',
    address: {
      city: property?.city ? property.city : '',
      street: property?.street ? property.street : '',
      zip: property?.zip ? property.zip : '',
      country: property?.country ? property.country : '',
    },
    customerId: property?.customerId ? property.customerId : null,
    managerId: property?.managerId ? property.managerId : null,
    company: 'Company',
    long: 'long1',
    lat: 'lat1',
    customer: property?.customer ? property.customer : null,
  };
};

const mapPropertyAccount = (property: any) => {
  return {
    name: property?.name,
    city: property?.city,
    zip: property?.zip,
    country: property?.country,
    long: 'long1',
    lat: 'lat1',
    street: property?.street,
    managerId: property?.managerId,
    customerId: property.customerId !== '' ? property.customerId : null,
  };
};

// ----------------------------------------------------------------------

export function clearProperties() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getPropertiesSuccess([]));
  };
}

export function getProperties() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/properties');

      const result: IProperty[] = response?.data?.data?.map((property: any) =>
        mapProperty(property)
      );

      dispatch(slice.actions.getPropertiesSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getFilteredProperties(data: FilterData) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/properties', {
        params: {
          city: data.filteredCity,
          customer: data.filteredCustomerIds,
          manager: data.filteredManagerIds,
        },
      });

      const result: IProperty[] = response?.data?.data?.map((property: any) =>
        mapProperty(property)
      );

      dispatch(slice.actions.getPropertiesSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getCustomerProperties(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`/customers/${id}/properties`);

      const result: IProperty[] = response?.data?.data?.map((property: any) =>
        mapProperty(property)
      );

      dispatch(slice.actions.getPropertiesSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getProperty(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/properties/' + id);

      const result: IProperty = mapProperty(response?.data?.data);

      dispatch(slice.actions.getPropertySuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function getRedirectForProperty(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/properties/' + id + '/redirect', {
        params: { id },
      });

      const result: IRedirect = response?.data?.data;

      dispatch(slice.actions.getRedirectSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateProperty(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        '/properties/' + id,
        mapPropertyAccount(data)
      );

      const result: IProperty = mapProperty(response?.data?.data);

      dispatch(slice.actions.updatePropertySuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createProperty(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/properties/',
        mapPropertyAccount(data)
      );

      const result: IProperty = mapProperty(response?.data?.data);

      dispatch(slice.actions.createPropertySuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeProperty(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/properties/' + id, {
        params: { id },
      });

      const result = response?.data;

      dispatch(slice.actions.removePropertySuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('Error while deleting property: ' + error.message);
    }
  };
}

export function setPropertyToNull() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.setPropertyToNull());
  };
}

export function resetProperties() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
