import React, { useState } from 'react';

import { QrScanner } from '@yudiel/react-qr-scanner';
import { useLocales } from 'src/locales';
import { Container } from '@mui/system';
import { Helmet } from 'react-helmet-async';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Button, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router';

const UKNOWN_ORIGIN = 'Unknown origin';
const PERMISSION_DENIED = 'Permission denied';
const DEVICE_NOT_FOUND = 'Requested device not found';

export default function ScanQrPage() {
  const [error, setError] = useState<string | null>(null);
  const { translate } = useLocales();

  const navigate = useNavigate();

  const isSameOrigin = (origin: string) => {
    const currentOrigin = window.location.origin;
    return currentOrigin === origin;
  };

  const handleScan = (data: any) => {
    if (data) {
      const urlParser = new URL(data);
      const origin = urlParser.origin;

      if (isSameOrigin(origin)) {
        const path = urlParser.pathname;
        navigate(path);
      } else {
        setError(UKNOWN_ORIGIN);
      }
    }
  };

  const handleError = (err: any) => {
    console.error(err);
    setError(err.message);
  };

  const getErrorMessage = () => {
    if (error === PERMISSION_DENIED) {
      return `${translate('pages.qrScan.errorPermissionDenied')}`;
    }
    if (error === DEVICE_NOT_FOUND) {
      return `${translate('pages.qrScan.errorDeviceNotFound')}`;
    }
    if (error === UKNOWN_ORIGIN) {
      return `${translate('pages.qrScan.errorUnknownOrigin')}`;
    }
    return `${translate('pages.qrScan.error')}${error}`;
  };

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.qrScan.title')}`}</title>
      </Helmet>
      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.qrScan.heading')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            { name: `${translate('pages.qrScan.heading')}` },
          ]}
        />
        <Card sx={{ padding: '16px 16px 16px 16px', marginBottom: '40px' }}>
          {!error ? (
            <QrScanner
              onDecode={(result) => handleScan(result)}
              onError={(error) => handleError(error)}
            />
          ) : (
            <div>
              <Typography>{getErrorMessage()}</Typography>
              <Button
                onClick={() => setError(null)}
                type="button"
                variant="contained"
                sx={{ mt: 2 }}
              >
                {`${translate('pages.qrScan.retry')}`}
              </Button>
            </div>
          )}
        </Card>
      </Container>
    </>
  );
}
