import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
// @mui
import { Card, Container, Grid, Typography } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useEffect } from 'react';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import ItemCategoryNewEditForm from 'src/sections/@dashboard/itemCategory/ItemCategoryNewEditForm';
import { getItemCategory, getItemCategorySuperior } from 'src/redux/slices/itemCategory';
import { useLocales } from '../../../locales';
import ItemCategoryListPage from './ItemCategoryListPage';

// ----------------------------------------------------------------------

export default function ItemCategoryEditPage() {
  const { translate } = useLocales();
  const { id } = useParams();
  const { itemCategory, itemCategorySuperior } = useSelector(
    (state: any) => state.itemCategory
  );

  useEffect(() => {
    dispatch(getItemCategory(id ? Number(id) : 1));
    dispatch(getItemCategorySuperior(id ? Number(id) : 1));
  }, [dispatch, id]);

  return (
    <>
      <Helmet>
        <title>{`${translate(
          'pages.configuration.titleEditItemCategory'
        )}`}</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.configuration.editItemCategory')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('configuration')}`,
              href: PATH_DASHBOARD.configuration.itemCategoryList,
            },
            { name: itemCategory?.name ?? '' },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 0 32px 0', marginBottom: '40px' }}>
              <ItemCategoryNewEditForm
                itemCategory={itemCategory}
                itemCategorySuperior={itemCategorySuperior}
              />
            </Card>
          </Grid>
        </Grid>

        <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
          <Typography variant="h6" style={{ marginTop: '0.5rem' }}>
            {`${translate('subCategories')}`}
          </Typography>
          <ItemCategoryListPage itemCategory={itemCategory} />
        </Card>
      </Container>
    </>
  );
}
