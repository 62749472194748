import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  SelectChangeEvent,
  Table,
  TableBody,
  TableContainer,
  Tooltip
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import {
  emptyRows,
  getComparator,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable
} from '../../../components/table';
import { FilterData, IServiceRequestLight } from 'src/@types/serviceRequest';
import {
  getFilteredServiceRequests,
  getPropertyServiceRequests,
  getServiceRequests,
  removeServiceRequest,
  updateToDoneServiceRequest,
} from 'src/redux/slices/serviceRequest';
import { dispatch } from 'src/redux/store';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useLocales } from '../../../locales';
import ServiceRequestToolbar from 'src/sections/@dashboard/serviceRequest/list/ServiceRequestTableToolBar';
import CustomSimpleFilter from '../CustomSimpleFilter';
import {
  CREATED,
  CUSTOMER,
  DONE,
  FILTERED_CUSTOMERS_IDS,
  FILTERED_PRIORITY,
  FILTERED_PROPERTY_IDS,
  FILTERED_STATUS,
  HIGH,
  IN_PROGRESS,
  LOW,
  MEDIUM,
  ONLY_ADMIN_COMPANY_CUSTOMER_ROLES,
  ONLY_ADMIN_ROLES,
  ROLES_PERMISSIONS,
} from 'src/constants';
import { IProperty } from 'src/@types/property';
import { ICustomer } from 'src/@types/customer';
import { getProperties, setPropertyToNull } from 'src/redux/slices/property';
import { getCustomers } from 'src/redux/slices/customer';
import CustomFilter from '../CustomFilter';
import { useAuthContext } from 'src/auth/useAuthContext';
import useDeleteRowsHandler from 'src/hooks/useDeleteHandler';
import { setLastUrlServiceRequests } from 'src/redux/slices/setting';
import { allowTo } from 'src/utils/permissionHandler';
import {
  getPageFromStorage,
  savePageToStorage,
} from 'src/utils/pageStorageHandler';
import {
  getRowsPerPageFromStorage,
  saveRowsPerPageToStorage,
} from 'src/utils/rowsPerPageStorageHandler';
import { getItems } from 'src/redux/slices/item';
import { IItem } from 'src/@types/item';
import { ServiceRequestTableRow } from 'src/sections/@dashboard/serviceRequest/list';

type ServiceRequestListPageProps = {
  propertyId?: string;
  customerId?: string;
  fromUrl?: string;
};

type Filter = {
  status: string[];
  properties: IProperty[];
  customers: ICustomer[];
  priorities: string[];
};

// ----------------------------------------------------------------------

export default function ServiceRequestListPage({
  propertyId,
  customerId,
  fromUrl,
}: ServiceRequestListPageProps) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const [tableData, setTableData] = useState<IServiceRequestLight[]>([]);
  const [filterName, setFilterName] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');
  const { serviceRequests, isLoading: serviceRequestLoading } = useSelector(
    (state: any) => state.serviceRequest
  );
  const { properties, isLoading: propertyLoading } = useSelector(
    (state: any) => state.property
  );
  const { customers, isLoading: customerLoading } = useSelector(
    (state: any) => state.customer
  );
  const { items, isLoading: itemLoading } = useSelector(
    (state: any) => state.item
  );
  const { translate } = useLocales();
  const [dataToFilter, setDataToFilter] = useState<Filter>({
    status: [CREATED, IN_PROGRESS, DONE],
    properties: [],
    customers: [],
    priorities: [LOW, MEDIUM, HIGH],
  });
  const [filteredData, setFilteredData] = useState<FilterData>({
    [FILTERED_STATUS]: [CREATED, IN_PROGRESS],
    [FILTERED_PROPERTY_IDS]: [],
    [FILTERED_CUSTOMERS_IDS]: [],
    [FILTERED_PRIORITY]: [],
  });
  const { deleteHandlers } = useDeleteRowsHandler();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterRole,
    filterStatus,
  });

  const dataInPage = dataFiltered.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const denseHeight = dense ? 52 : 72;

  const isFiltered =
    filterName !== '' || filterRole !== 'all' || filterStatus !== 'all';

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterRole) ||
    (!dataFiltered.length && !!filterStatus);

  const tableHead = [
    { id: 'name', label: `${translate('name')}`, align: 'left' },
    { id: 'description', label: `${translate('description')}`, align: 'left' },
    { id: 'customerName', label: `${translate('customer')}`, align: 'left' },
    { id: 'propertyName', label: `${translate('property')}`, align: 'left' },
    { id: 'creatorFullName', label: `${translate('creator')}`, align: 'left' },
    { id: 'items', label: `${translate('item')}`, align: 'left' },
    { id: 'status', label: `${translate('status')}`, align: 'left' },
    { id: 'actions', label: `${translate('actions')}`, align: 'right' },
  ];

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDeleteRow = async (id: string) => {
    try {
      await dispatch(removeServiceRequest(Number(id)));
      if (!propertyId) {
        await dispatch(getServiceRequests());
      } else {
        await dispatch(getPropertyServiceRequests(Number(id)));
      }
      const deleteRow = tableData.filter(
        (row) => Number(row.id) !== Number(id)
      );
      setSelected([]);
      setTableData(deleteRow);

      if (page > 0) {
        if (dataInPage.length < 2) {
          setPage(page - 1);
        }
      }
      enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
    }
  };

  const handleDeleteRows = async (selectedRows: string[]) => {
    try {
      if (await deleteHandlers.deleteRows(selectedRows, removeServiceRequest)) {
        return;
      }

      setTableData((prevData) =>
        prevData.filter((row) => !selectedRows.includes(row.id.toString()))
      );
      setSelected([]);

      // Check if any rows were deleted before showing the success message
      const rowsDeleted = selectedRows.some((selectedRow) =>
        tableData.some((row) => row.id === selectedRow)
      );

      if (rowsDeleted) {
        enqueueSnackbar(`${translate('infoMessages.successDelete')}`, {
          variant: 'success',
        });
      }

      if (page > 0) {
        if (selectedRows.length === dataInPage.length) {
          setPage(page - 1);
        } else if (selectedRows.length === dataFiltered.length) {
          setPage(0);
        } else if (selectedRows.length > dataInPage.length) {
          const newPage =
            Math.ceil((tableData.length - selectedRows.length) / rowsPerPage) -
            1;
          setPage(newPage);
        }
      }
    } catch (error) {
      const errorMessage =
        error.message || `${translate('infoMessages.couldNotDelete')}`;
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
  };

  const handleEditRow = (id: string) => {
    savePageToStorage('serviceRequest', page);
    saveRowsPerPageToStorage('serviceRequest', rowsPerPage);
    dispatch(setPropertyToNull());
    navigate(PATH_DASHBOARD.serviceRequest.view(paramCase(id)));
  };

  const handleUpdateToDone = async (id: number) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await dispatch(updateToDoneServiceRequest(Number(id)));
      setTableData((prevData) => prevData.filter((row) => +row.id !== id));
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  const handleBackNavigate = () => {
    switch (fromUrl) {
      case CUSTOMER:
        navigate(
          PATH_DASHBOARD.customer.properties(customerId?.toString() ?? '1')
        );
        return;
      default:
        navigate(PATH_DASHBOARD.general.properties);
        return;
    }
  };

  const handleResetFilter = () => {
    setFilterName('');
    setFilterRole('all');
    setFilterStatus('all');
  };

  const uniqueHandler = (arr: any[]) => {
    return arr.filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(getProperties()),
          dispatch(getCustomers()),
          dispatch(getItems()),
        ]);

        if (!propertyId) {
          await dispatch(getFilteredServiceRequests({ ...filteredData }));
        } else {
          await dispatch(
            getFilteredServiceRequests({ ...filteredData }, Number(propertyId))
          );
        }
      } catch (error: any) {
        const errorMessage =
          error.message || `${translate('infoMessages.couldNotDelete')}`;
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        });
      }
    };

    fetchData();
    dispatch(setLastUrlServiceRequests(''));
  }, [propertyId, filteredData]);

  useEffect(() => {
    setTableData(serviceRequests);
    if (
      dataToFilter.customers.length === 0 ||
      dataToFilter.properties.length === 0
    ) {
      setDataToFilter({
        ...dataToFilter,
        properties: uniqueHandler(properties),
        customers: uniqueHandler(customers),
      });
    }
  }, [serviceRequests, customers, properties]);

  const handleChange = async (
    event: SelectChangeEvent<typeof filteredData>,
    updating: string
  ) => {
    const {
      target: { value },
    } = event;
    setFilteredData({ ...filteredData, [updating]: value });
    await dispatch(
      getFilteredServiceRequests(
        { ...filteredData, [updating]: value },
        propertyId ? Number(propertyId) : undefined
      )
    );
  };

  const findItem = useCallback(
    (_id: number | undefined) => {
      if (!_id) return `${translate('unknown')}`;
      const _item = items.find(
        (item: IItem) => Number(item.id) === Number(_id)
      );
      return _item && _item.name ? _item.name : `${translate('unknown')}`;
    },
    [items]
  );

  useEffect(() => {
    const savedPage = getPageFromStorage('serviceRequest');
    setPage(savedPage ?? 0);
    const savedRowsPerPage = getRowsPerPageFromStorage('serviceRequest');
    setRowsPerPage(savedRowsPerPage ?? 5);
  }, []);

  useEffect(() => {
    const _loading =
      propertyLoading ||
      customerLoading ||
      serviceRequestLoading ||
      itemLoading;
    setIsLoading(_loading);
  }, [propertyLoading, customerLoading, serviceRequestLoading, itemLoading]);

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.serviceRequest.title')}`}</title>
      </Helmet>

      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Container maxWidth={false}>
          {propertyId &&
            allowTo(
              user ? user?.roles : [],
              ONLY_ADMIN_COMPANY_CUSTOMER_ROLES
            ) && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 5 }}>
                <Button
                  component={RouterLink}
                  to={PATH_DASHBOARD.serviceRequest.createServiceRequestFromProperty(
                    propertyId
                  )}
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  {`${translate('pages.serviceRequest.newRequest')}`}
                </Button>
                <Button
                  onClick={handleBackNavigate}
                  sx={{ float: 'right', marginLeft: '13px' }}
                  type="button"
                  variant="contained"
                >
                  {`${translate('back')}`}
                </Button>
              </Box>
            )}
          {!propertyId && (
            <CustomBreadcrumbs
              heading={`${translate('pages.serviceRequest.heading')}`}
              links={[
                {
                  name: `${translate('dashboard')}`,
                  href: PATH_DASHBOARD.root,
                },
                {
                  name: `${translate('serviceRequests')}`,
                  href: PATH_DASHBOARD.general.serviceRequest,
                },
                { name: `${translate('pages.serviceRequest.heading')}` },
              ]}
              action={
                allowTo(
                  user ? user?.roles : [],
                  ONLY_ADMIN_COMPANY_CUSTOMER_ROLES
                ) && (
                  <Button
                    component={RouterLink}
                    to={PATH_DASHBOARD.serviceRequest.create}
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    {`${translate('pages.serviceRequest.newRequest')}`}
                  </Button>
                )
              }
            />
          )}

          <Card>
            <Divider />
            <div style={{ marginLeft: 20 }}>
              <CustomSimpleFilter
                dataToFilter={dataToFilter.status}
                filteredData={filteredData.filteredStatus}
                handleChange={handleChange}
                updating={FILTERED_STATUS}
              />
              {user?.roles.includes(ROLES_PERMISSIONS.admin) && (
                <CustomFilter
                  dataToFilter={dataToFilter.customers}
                  filteredData={filteredData.filteredCustomerIds}
                  handleChange={handleChange}
                  updating={FILTERED_CUSTOMERS_IDS}
                />
              )}
              <CustomFilter
                dataToFilter={dataToFilter.properties}
                filteredData={filteredData.filteredPropertyIds}
                handleChange={handleChange}
                updating={FILTERED_PROPERTY_IDS}
              />
              <CustomSimpleFilter
                dataToFilter={dataToFilter.priorities}
                filteredData={filteredData.filteredPriorities}
                handleChange={handleChange}
                updating={FILTERED_PRIORITY}
              />
            </div>
            <ServiceRequestToolbar
              isFiltered={isFiltered}
              filterName={filterName}
              onFilterName={handleFilterName}
              onResetFilter={handleResetFilter}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <TableSelectedAction
                dense={dense}
                numSelected={selected.length}
                rowCount={tableData.length}
                onSelectAllRows={(checked) =>
                  onSelectAllRows(
                    checked,
                    tableData.map((row) => row.id.toString())
                  )
                }
                action={
                  <Tooltip title={`${translate('delete')}`}>
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                }
              />

              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'}>
                  {
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={tableHead}
                      rowCount={tableData.length}
                      numSelected={selected.length}
                      onSort={onSort}
                      onSelectAllRows={
                        !allowTo(user ? user.roles : [], ONLY_ADMIN_ROLES)
                          ? undefined
                          : (checked: boolean) =>
                              onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id.toString())
                              )
                      }
                    />
                  }

                  <TableBody>
                    <>
                      {dataFiltered
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <ServiceRequestTableRow
                            key={row.id}
                            row={row}
                            selected={selected.includes(row.id.toString())}
                            onSelectRow={() => onSelectRow(row.id.toString())}
                            onDeleteRow={() =>
                              handleDeleteRow(row.id.toString())
                            }
                            onEditRow={() => handleEditRow(row.id.toString())}
                            itemName={findItem(row.itemId)}
                            onHandleUpdateToDone={handleUpdateToDone}
                          />
                        ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(
                          page,
                          rowsPerPage,
                          tableData.length
                        )}
                      />

                      <TableNoData isNotFound={isNotFound} />
                    </>
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              //
              dense={dense}
              onChangeDense={onChangeDense}
            />
          </Card>
        </Container>
      )}

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title={`${translate('delete')}`}
        content={
          <>
            {selected.length > 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong>{' '}
                {selected.length > 4
                  ? translate('item_five_more')
                  : translate('item_two_four')}
                ?
              </>
            )}
            {selected.length === 1 && (
              <>
                {translate('infoMessages.deletePrompt')}{' '}
                <strong>{selected.length}</strong> {translate('item_one')}?
              </>
            )}
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows(selected);
              handleCloseConfirm();
            }}
          >
            {`${translate('delete')}`}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filterName,
  filterStatus,
  filterRole,
}: {
  inputData: IServiceRequestLight[];
  comparator: (a: any, b: any) => number;
  filterName: string;
  filterStatus: string;
  filterRole: string;
}) {
  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    inputData = inputData.filter(
      (serviceRequest) =>
        serviceRequest.name.toLowerCase().indexOf(filterName.toLowerCase()) !==
        -1
    );
  }

  return inputData;
}
