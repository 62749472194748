import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useParams } from 'react-router';
import TaskCreateForm from 'src/sections/@dashboard/task/create/TaskCreateForm';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------
export default function ServiceRequestTaskCreatePage() {
  const { translate } = useLocales();
  const { id } = useParams();

  return (
    <>
      <Helmet>
        <title>{`${translate('pages.task.createNewTaskInRequest')}`}</title>
      </Helmet>
      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.task.createNewTask')}`}
          links={[
            {
              name: `${translate('dashboard')}`,
              href: PATH_DASHBOARD.root,
            },
            {
              name: `${translate('serviceRequest')}`,
              href: PATH_DASHBOARD.general.serviceRequest,
            },
            { name: `${translate('pages.task.newTask')}` },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              <TaskCreateForm
                serviceRequestId={Number(id)}
                assigneeId={''}
                title={''}
                description={''}
                propertyId={''}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
