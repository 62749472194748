import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Container, Stack, TextField } from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, { RHFSelect, RHFTextField } from '../../../components/hook-form';
import { dispatch, useSelector } from 'src/redux/store';
import { getCustomers } from 'src/redux/slices/customer';
import { getItem, getPropertyItems } from 'src/redux/slices/item';
import { getUsers } from 'src/redux/slices/user';
import { IReminder, ReminderType } from 'src/@types/reminder';
import { DateTimePicker } from '@mui/x-date-pickers';
import { updateReminder } from 'src/redux/slices/reminder';
import { useLocales } from '../../../locales';
import { CUSTOMER, ITEM, PROPERTY } from 'src/constants';
import { useAuthContext } from 'src/auth/useAuthContext';
import { getCustomerProperties } from 'src/redux/slices/property';
import { useActiveSelectReminder } from 'src/hooks/useActiveSelectReminder';

type FormValuesProps = {
  propertyId: number | string;
  customerId: number | string;
  assigneeId: number | string;
  itemId: number | string;
  roomId: number | string;
  name: string;
  description: string;
  time: string;
  type: string;
};
type Props = {
  disableEdit?: boolean;
  reminder?: IReminder | null;
  fromUrl: string;
};

export default function ReminderNewEditForm({
  reminder,
  fromUrl,
  disableEdit,
}: Props) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const [editing, setEditing] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { customers } = useSelector((state) => state.customer);
  const { properties } = useSelector((state) => state.property);
  const { users } = useSelector((state) => state.user);
  const { items, item } = useSelector((state) => state.item);
  const { user } = useAuthContext();
  const { onChangeHandler } = useActiveSelectReminder();

  const UpdatePropertySchema = Yup.object().shape({
    description: Yup.string().required(
      `${translate('isRequired.description')}`
    ),
    name: Yup.string().required(`${translate('isRequired.name')}`),
    customerId: Yup.number()
      .typeError(`${translate('isRequired.customer')}`)
      .required(`${translate('isRequired.customer')}`),
    propertyId: Yup.number()
      .typeError(`${translate('isRequired.property')}`)
      .required(`${translate('isRequired.property')}`),
    assigneeId: Yup.number()
      .typeError(`${translate('isRequired.asigneeName')}`)
      .required(`${translate('isRequired.assigneeName')}`),
    time: Yup.string().required(`${translate('isRequired.time')}`),
  });

  const defaultValues = {
    name: reminder?.name || '',
    description: reminder?.description || '',
    customerId: Number(reminder?.customer?.id) || '',
    propertyId: Number(reminder?.property?.id) || '',
    assigneeId: Number(reminder?.assignee?.id) || '',
    itemId: Number(reminder?.item?.id) || '',
    roomId: Number(reminder?.room?.id) || '',
    time: reminder?.time || new Date().toISOString(),
    type: reminder?.type || '',
  };

  const isViewerAuthor = user?.id === reminder?.author?.id;

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdatePropertySchema),
    values: defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  const values = watch();

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(updateReminder(Number(reminder?.id), data));
      reset();
      enqueueSnackbar(`${translate('infoMessages.successEdit')}`, {
        variant: 'success',
      });
      handlerNavigate();
    } catch (error) {
      enqueueSnackbar(`${translate('infoMessages.couldNotEdit')}`, {
        variant: 'error',
      });
    }
  };

  const handlerNavigate = () => {
    switch (fromUrl) {
      case ITEM:
        navigate(PATH_DASHBOARD.item.reminders(item ? item.id : '1'));
        break;
      default:
        navigate(PATH_DASHBOARD.general.reminder);
        break;
    }
  };

  const editingHandler = (_editing: boolean) => {
    setEditing(!_editing);
    dispatch(getCustomerProperties(Number(defaultValues.customerId)));
    dispatch(getPropertyItems(Number(defaultValues.propertyId)));
    reset();
  };

  useEffect(() => {
    if (
      customers.length === 0 &&
      properties.length === 0 &&
      users.length === 0 &&
      items.length === 0
    )
      Promise.all([dispatch(getCustomers()), dispatch(getUsers())]);
    if (reminder?.property?.id && reminder?.item?.id) {
      dispatch(getPropertyItems(+reminder.property.id));
      dispatch(getItem(+reminder.item.id));
    }
    if (reminder?.customer.id) {
      dispatch(getCustomerProperties(+reminder.customer.id));
    }
  }, [reminder?.item?.id]);

  return (
    <Container maxWidth={false}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end" sx={{ mb: 5 }}>
          <Button
            onClick={handlerNavigate}
            sx={{ float: 'right' }}
            type="button"
            variant="contained"
          >
            {`${translate('back')}`}
          </Button>
        </Stack>
        <Card sx={{ p: 3 }}>
          <Box
            rowGap={3}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            }}
          >
            <RHFTextField
              name="name"
              label={`${translate('name')}`}
              id="name"
              value={values.name}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
            <RHFTextField
              name="description"
              label={`${translate('description')}`}
              id="description"
              value={values.description}
              readOnly={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            />
            <RHFSelect
              native
              name="customerId"
              label={`${translate('customer')}`}
              placeholder={`${translate('customer')}`}
              value={values.customerId}
              disabled={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              onChange={(e: any) =>
                onChangeHandler(CUSTOMER, e.target.value, setValue, setError)
              }
              required
            >
              {values.customerId === '' && <option value=""></option>}
              {customers.map((customer) => (
                <option key={customer.id} value={customer.id}>
                  {customer.name}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect
              native
              name="propertyId"
              label={`${translate('property')}`}
              placeholder={`${translate('name')}`}
              value={values.propertyId}
              variant={!editing ? 'standard' : 'outlined'}
              disabled={!editing || properties.length == 0 ? true : false}
              readOnly={properties.length == 0 ? true : false}
              onChange={(e: any) =>
                onChangeHandler(PROPERTY, e.target.value, setValue, setError)
              }
              error={
                values.customerId !== '' && properties.length === 0
                  ? true
                  : false
              }
              required
            >
              {values.propertyId === '' && <option value=""></option>}
              {properties.map((property) => (
                <option key={property.id} value={property.id}>
                  {property.name}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect
              native
              name="assigneeId"
              label={`${translate('assigneeName')}`}
              placeholder={`${translate('assigneeName')}`}
              value={values.assigneeId}
              disabled={!editing}
              variant={!editing ? 'standard' : 'outlined'}
              required
            >
              {users.map((user) => (
                <option key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </option>
              ))}
            </RHFSelect>
            <RHFSelect
              native
              name="itemId"
              label={`${translate('item')}`}
              placeholder={`${translate('item')}`}
              value={values.itemId}
              variant={!editing ? 'standard' : 'outlined'}
              disabled={!editing || items.length === 0 ? true : false}
              readOnly={items.length === 0 ? true : false}
              error={
                values.customerId !== '' && items.length === 0 ? true : false
              }
              required
            >
              {values.itemId === '' && <option value=""></option>}
              {items.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </RHFSelect>
            <DateTimePicker
              readOnly={!editing}
              renderInput={(props) => <TextField {...props} fullWidth />}
              label={`${translate('time')}`}
              value={values.time}
              onChange={(time: any) => setValue('time', time)}
            />
            <RHFSelect
              native
              name="type"
              label={`${translate('pages.reminder.repeating')}`}
              placeholder={`${translate('pages.reminder.repeating')}`}
              variant={!editing ? 'standard' : 'outlined'}
              disabled={!editing || items.length === 0 ? true : false}
              value={values.type}
            >
              <option key={''} value=""></option>
              {Object.entries(ReminderType).map((reminderType) => (
                <option key={reminderType[0]} value={reminderType[1]}>
                  {reminderType[1].charAt(0).toUpperCase() +
                    reminderType[1].slice(1).toLowerCase()}
                </option>
              ))}
            </RHFSelect>
          </Box>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            {editing && (
              <>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {`${translate('saveChanges')}`}
                </LoadingButton>
                <Button
                  onClick={() => editingHandler(true)}
                  sx={{ ml: 1 }}
                  variant="contained"
                >
                  {`${translate('close')}`}
                </Button>
              </>
            )}
            {!editing && !disableEdit && isViewerAuthor && (
              <Button
                onClick={() => setEditing(true)}
                sx={{ ml: 1 }}
                variant="contained"
              >
                {`${translate('edit')}`}
              </Button>
            )}
          </Box>
        </Card>
      </FormProvider>
    </Container>
  );
}
