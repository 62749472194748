import { Helmet } from 'react-helmet-async';
// @mui
import { Card, Container, Grid } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useParams } from 'react-router';
import TaskCreateForm from 'src/sections/@dashboard/task/create/TaskCreateForm';

// ----------------------------------------------------------------------

export default function RoomTaskCreatePage() {

  const { id, roomId } = useParams();

  return (
    <>
      <Helmet>
        <title> Property: Create a new task | Properio.io</title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading="Create a new task"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Task',
              href: PATH_DASHBOARD.general.task,
            },
            { name: 'New Task' },
          ]}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              <TaskCreateForm propertyId={id} roomId={roomId} />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
