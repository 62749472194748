// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const sk = {
  dashboard: 'Domov',
  delete: 'Vymazať',
  name: 'Názov',
  creator: 'Autor',
  authorName: 'Autor',
  customers: 'Zákazníci',
  customer: 'Zákazník',
  customerName: 'Meno Zákazníka',
  firstName: 'Meno',
  lastName: 'Priezvisko',
  email: 'E-mail',
  role: 'Rola',
  street: 'Ulica',
  country: 'Krajina',
  city: 'Mesto',
  zipCode: 'PSČ',
  property: 'Objekt',
  properties: 'Objekty',
  businessId: 'IČO',
  taxId: 'DIČ',
  vatId: 'IČ DPH',
  back: 'Späť',
  item: 'Položka',
  items: 'Položky',
  item_one: 'položku',
  item_two_four: 'položky',
  item_five_more: 'položiek',
  description: 'Popis',
  room: 'Miestnosť',
  rooms: 'Miestnosti',
  itemCategory: 'Kategória Položky',
  itemTag: 'Tag Položky',
  itemCategories: 'Kategórie Položiek',
  itemTags: 'Tagy Položiek',
  newItemTag: 'Nový Tag Položky',
  newItemCategory: 'Nová Kategória Položky',
  serviceRequest: 'Požiadavka',
  task: 'Úloha',
  tasks: 'Úlohy',
  serviceRequestName: 'Názov požiadavky',
  status: 'Status',
  assigneeName: 'Meno Nadobúdateľa',
  serviceRequests: 'Požiadavky',
  reminder: 'Pripomienka',
  reminders: 'Pripomienky',
  user: 'Používateľ',
  users: 'Používatelia',
  configuration: 'Konfigurácia',
  time: 'Čas',
  company: 'Spoločnosť',
  companyManager: 'Manažér spoločnosti',
  propertyManager: 'Manažér objektu',
  internalOperator: 'Interný operátor',
  externalOperator: 'Externý operátor',
  admin: 'Admin',
  manager: 'Manažér',
  password: 'Heslo',
  parentCategory: 'Nadradená kategória',
  general: 'Všeobecné',
  saveChanges: 'Uložiť zmeny',
  edit: 'Upraviť',
  close: 'Zavrieť',
  cancel: 'Zavrieť',
  parentRoom: 'Nadradená miestnosť',
  subRoom: 'Miestnosť spadajúca pod',
  start: 'Štart',
  complete: 'Dokončené',
  search: 'Vyhľadať',
  inProgress: 'V procese',
  inprogress: 'V procese',
  in_progress: 'V procese',
  created: 'Vytvorené',
  done: 'Dokončené',
  sent: 'Odoslané',
  seen: 'Videné',
  profile: 'Profil',
  logoutUnable: 'Nie je možné odhlásiť sa!',
  logout: 'Odhlásiť sa',
  passwordConfirmation: 'Potvrdenie hesla',
  unknown: 'Neznáme',
  actions: 'Akcie',
  noAction: 'Žiadna akcia',
  clear: 'Zmazať',
  logsAttachments: 'Záznamy a prílohy',
  content: 'Obsah',
  createdAt: 'Vytvorené',
  itemLog: 'Záznam položky',
  itemLogs: 'Záznamy položky',
  attachments: 'Prílohy',
  low: 'Nízka',
  medium: 'Stredná',
  high: 'Vysoká',
  subCategories: 'Podkategórie',
  comments: 'Komentáre',
  newComment: 'Nový komentár',
  somethingWentWrong: 'Nastala chyba. Prosím kontaktujte administrátora.',
  scanQr: 'Naskenuj QR kód',
  error: 'Chyba',
  notFound: 'Požadovaný záznam nebol nájdený',
  repeating: 'Opakovanie',
  pages: {
    dashboard: {
      title: 'Vitajte na Properio',
      text: 'Váš systém technickej správy je pripravený',
      belowText: 'prosím, použite navigáciu vľavo',
    },
    customer: {
      title: 'Zákazníci: Zoznam | Properio.io',
      heading: 'Zoznam zákazníkov',
      newCustomer: 'Nový Zákazník',
      createNewCustomerTitle:
        'Zákazníci: Vytvoriť nového zákazníka | Properio.io',
      createNewCustomerHeading: 'Vytvoriť nového zákazníka',
      createCustomer: 'Vytvoriť zákazníka',
      customerDetail: 'Detail zákazníka',
      noCustomer: 'Žiadny zákazník',
      titlePropertyCreate: 'Zákazníci: Vytvoriť nový objekt | Properio.io',
    },
    property: {
      title: 'Objekty: Zoznam | Properio.io',
      titleCreate: 'Objekty: Vytvoriť nový objekt | Properio.io',
      heading: 'Zoznam objektov',
      newProperty: 'Nový objekt',
      createNewProperty: 'Vytvoriť nový objekt',
      createProperty: 'Vytvoriť objekt',
      noProperty: 'Žiadny objekt',
      propertyDetail: 'Detail objektu',
      titleEditRoom: 'Objekty: Upraviť miestnosť | Properio.io',
    },
    item: {
      title: 'Položky: Zoznam | Properio.io',
      titleCreate: 'Položky: Vytvoriť novú položku | Properio.io',
      heading: 'Zoznam položiek',
      newItem: 'Nová Položka',
      createNewItem: 'Vytvoriť novú položku',
      createItem: 'Vytvoriť položku',
      createNewItemInProperty: 'Objekt: Vytvoriť novú položku | Properio.io',
      itemDetail: 'Detail položky',
      editItem: 'Upraviť položku',
      noItem: 'Žiadna položka',
    },
    itemLog: {
      newItemLog: 'Nový Záznam',
      createNewItemLog: 'Vytvoriť nový záznam',
      typeOfLog: 'Typ záznamu',
      technicalCheck: 'Revízia',
      generalInfo: 'Všeobecné',
      itemLogName: 'Názov záznamu',
      noItemLog: 'Žiadny záznam',
      itemLogDetail: 'Detail záznamu položky',
      editItemLog: 'Upraviť záznam položky',
      nextCheck: 'Najbližšia revízia',
    },
    task: {
      title: 'Úlohy: Zoznam | Properio.io',
      titleCreate: 'Úloha: Vytvoriť novú úlohu | Properio.io',
      heading: 'Zoznam úloh',
      newTask: 'Nová úloha',
      createNewTask: 'Vytvoriť novú úlohu',
      createTask: 'Vytvoriť úlohu',
      createNewTaskInProperty: 'Objekt: Vytvoriť novú úlohu | Properio.io',
      createNewTaskInRequest: 'Požiadavka: Vytvoriť novú úlohu | Properio.io',
      editTaskTitle: 'Úloha: Upraviť úlohu | Properio.io',
      editTask: 'Upraviť úlohu',
    },
    serviceRequest: {
      title: 'Požiadavky: Zoznam | Properio.io',
      titleCreate: 'Požiadavky: Vytvoriť novú požiadavku | Properio.io',
      titleEdit: 'Požiadavka: Upraviť požiadavku | Properio.io',
      titleCreateComment: 'Komentár: Vytvoriť nový komentár | Properio.io',
      titleEditComment: 'Komentár: Upraviť komentár | Properio.io',
      titleViewComment: 'Komentár: Zobraziť komentár | Properio.io',
      heading: 'Zoznam požiadaviek',
      newRequest: 'Nová požiadavka',
      createNewRequest: 'Vytvoriť novú požiadavku',
      createRequest: 'Vytvoriť požiadavku',
      editRequest: 'Upraviť požiadavku',
      priority: 'Priorita',
      commentsList: 'Komentáre: Zoznam',
      createNewComment: 'Vytvoriť nový komentár',
      createComment: 'Vytvoriť komentár',
      editComment: 'Upraviť komentár',
      viewComment: 'Zobraziť komentár',
    },
    reminder: {
      title: 'Pripomienky: Zoznam | Properio.io',
      titleCreate: 'Pripomienky: Vytvoriť novú pripomienku | Properio.io',
      titleEdit: 'Pripomienky: Upraviť pripomienku | Properio.io',
      titleView: 'Pripomienky: Zobraziť pripomienku | Properio.io',
      name: 'Názov Pripomienky',
      heading: 'Zoznam pripomienok',
      newReminder: 'Nová pripomienka',
      createNewReminder: 'Vytvoriť novú pripomienku',
      createReminder: 'Vytvoriť pripomienku ',
      editReminder: 'Upraviť pripomienku',
      viewReminder: 'Zobraziť pripomienku',
      nextReminder: 'Budúce úkony',
      repeating: 'Opakovanie',
    },
    user: {
      title: 'Používatelia: Zoznam | Properio.io',
      titleCreate: 'Používatelia: Vytvoriť nového používateľa | Properio.io',
      titleEdit: 'Používatelia: Upraviť používateľa | Properio.io',
      heading: 'Zoznam používateľov',
      newUser: 'Nový používateľ',
      createNewUser: 'Vytvoriť nového používateľa',
      createUser: 'Vytvoriť používateľa',
      editNewUser: 'Upraviť nového používateľa',
      editUser: 'Upraviť používateľa',
      userProfileTitle: 'Používateľ: Profil | Properio.io',
      updateProfile: 'Upraviť údaje používateľa',
    },
    configuration: {
      title: 'Konfigurácia | Properio.io',
      titleCreateItemTag:
        'Konfigurácia: Vytvoriť nový tag položky | Properio.io',
      titleEditItemTag: 'Konfigurácia: Upraviť tag položky | Properio.io',
      titleCreateItemCategory:
        'Konfigurácia: Vytvoriť novú kategóriu položky | Properio.io',
      createNewItemTag: 'Vytvoriť nový tag položky',
      createItemTag: 'Vytvoriť tag položky',
      createNewItemCategory: 'Vytvoriť novú kategóriu položky',
      createItemCategory: 'Vytvoriť kategóriu položky',
      editItemTag: 'Upraviť tag položky',
      titleEditItemCategory:
        'Konfigurácia: Upraviť kategóriu položky | Properio.io',
      editItemCategory: 'Upraviť kategóriu položky',
    },
    room: {
      newRoom: 'Nová miestnosť',
      createNewRoomInProperty: 'Objekt: Vytvoriť novú miestnosť | Properio.io',
      createNewRoom: 'Vytvoriť novú miestnosť',
      createRoom: 'Vytvoriť miestnosť',
      editRoom: 'Upraviť miestnosť',
    },
    attachment: {
      createNewAttachment: 'Pridať novú prílohu',
      newAttachment: 'Nová príloha',
      editAttachment: 'Upraviť prílohu',
      viewAttachment: 'Zobraziť prílohu',
      titleViewAttachment: 'Príloha: Zobraziť prílohu | Properio.io',
      downloadAttachment: 'Stiahnuť prílohu',
      noAuthor: 'Neznámy autor',
    },
    login: {
      heading: 'Prihláste sa do Properio',
      email: 'E-mailová adresa',
      loginButton: 'Prihlásiť sa',
      invalidCredentials: 'Nesprávny e-mail alebo heslo',
    },
    forgotPassword: {
      title: 'Zabudnuté heslo | Properio.io',
      forgotPassword: 'Zabudli ste heslo?',
      heading: 'Zabudli ste heslo?',
      description:
        'Zadajte e-mailovú adresu priradenú k Vášmu kontu a my Vám pošleme odkaz na obnovenie hesla.',
      email: 'Email',
      send: 'Odoslať',
      back: 'Späť na prihlásenie',
      success:
        'Ďakujeme, na uvedený e-mail boli odoslané pokyny na obnovu hesla.',
      error: 'Vyskytla sa chyba.',
    },
    newPassword: {
      title: 'Nové heslo | Properio.io',
      heading: 'Zadajte nové heslo',
      back: 'Späť na prihlásenie',
      success: 'Heslo bolo úspešne obnovené.',
      technicalError: 'Nastala chyba. Prosím kontaktujte administrátora.',
      invalidToken:
        'Vypršala platnosť žiadosti o obnovu hesla. Prosím vráťte sa domovskú obrazovku a proces obnovy hesla zopakujte.',
      confirmPassword: 'Potvrdiť nové heslo',
    },
    qrCode: {
      title: 'QR kód | Properio.io',
      heading: 'QR kód',
      download: 'Stiahnuť QR kód',
      print: 'Vytlačiť QR kód',
      wrongQrCode: 'Chybný QR kód',
      back: 'Späť na domovskú obrazovku',
    },
    qrScan: {
      title: 'Skenovanie QR kódu | Properio.io',
      heading: 'Naskenuj QR kód',
      description: 'Naskenujte QR kód a získajte prístup k aplikácii',
      wrongQrCode: 'Chybný QR kód',
      back: 'Späť na domovskú obrazovku',
      error:
        'Vyskytla sa chyba. V prípade neznámej chyby kontaktujte administrátora. Chybová hláška: ',
      errorPermissionDenied:
        'Nemáte povolený prístup ku kamere pre túto aplikáciu. Prosím povoľte prístup ku kamere v prehliadači a skúste to znova.',
      errorDeviceNotFound:
        'Nepodarilo sa nájsť zariadenie s kamerou. V prípade, že vaše zariadenie má kameru, skúste to znova alebo kontaktujte administrátora.',
      retry: 'Skúsiť znova',
      errorUnknownOrigin:
        'Neznámy pôvod QR kódu. Uistite sa, že kód bol vygenerovaný v aplikácii Properio.io a skúste to znova.',
    },
  },
  isRequired: {
    name: 'Názov je povinný',
    priority: 'Priorita je povinná',
    street: 'Ulica je povinná',
    city: 'Mesto je povinné',
    country: 'Krajina je povinná',
    zipCode: 'PSČ je povinné',
    customerId: 'Zákazník je povinný',
    company: 'Spoločnosť je povinná',
    description: 'Popis je povinný',
    room: 'Miestnosť je povinná',
    itemTag: 'Tag položky je povinný',
    itemCategory: 'Kategória položky je povinná',
    property: 'Objekt je povinný',
    assigneeName: 'Meno Nadobúdateľa je povinné',
    serviceRequest: 'Požiadavka je povinná',
    firstName: 'Meno je povinné',
    email: 'Email je povinný',
    validEmail: 'Email musí byť platný',
    lastName: 'Priezvisko je povinné',
    password: 'Heslo je povinné',
    roles: 'Rola je povinná',
    photoURL: 'Fotografia je povinná',
    phoneNumber: 'Telefónne číslo je povinné',
    about: 'Info je povinné',
    passwordMatch: 'Heslá musia byť rovnaké',
    passwordConfirmation: 'Potvrdenie hesla je povinné',
    manager: 'Manažér je povinný',
    content: 'Obsah je povinný',
    typeOfLog: 'Typ záznamu je povinný',
    deadline: 'Termín je povinný',
    item: 'Položka je povinná',
  },
  infoMessages: {
    successCreate: 'Úspešne vytvorené!',
    successDelete: 'Úspešne vymazané!',
    successEdit: 'Úspešne upravené!',
    couldNotDelete: 'Odstránenie zlyhalo!',
    couldNotEdit: 'Úprava zlyhala!',
    couldNotCreate: 'Vytvorenie zlyhalo!',
    allowedExtensions:
      'Povolené sú *.jpeg, *.jpg, *.png, *.gif \nmaximálnej veľkosti',
    deletePrompt: 'Ste si istý, že chcete odstrániť',
    deletePromptV2: 'Ste si istý, že to chcete odstrániť?',
    fileNotAttached: 'Nie je priložený súbor',
  },
  page500: {
    headerText: 'Nastala chyba',
    subHeaderText: 'Prosím kontaktujte administrátora.',
  },
  page403: {
    headerText: 'Nepovolený prístup',
    subHeaderText:
      'Na túto stránku nemáte prístup z dôvodu chýbajúcich oprávnení.',
  },
  demo: {
    title: `Slovenčina`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  goToHome: 'Späť na hlavnú stránku',
  filteredCity: 'Filtrovať podľa miest',
  filteredManagerIds: 'Filtrovať podľa manažérov',
  filteredCustomerIds: 'Filtrovať podľa zákazníkov',
  filteredCategory: 'Filtrovať podľa kategórie',
  filteredPropertyIds: 'Filtrovať podľa objektov',
  filteredTag: 'Filtrovať podľa tagu',
  filteredStatus: 'Filtrovať podľa stavu',
  filteredPriorities: 'Filtrovať podľa priorít',
  app: `app`,
  list: `list`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  banking: `banking`,
  booking: `booking`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  notification: {
    notification: 'Notifikácie',
    new: 'Nové notifikácie',
    markAllAsRead: 'Označiť všetky ako prečítané',
    markAsRead: 'Označiť ako prečítané',
    beforeThat: 'Staršie notifikácie',
    unreadMessages: 'Neprečítané notifikácie',
    markedAsRead: 'Označené ako prečítané',
    serviceRequestCreate: 'Požiadavka bola vytvorená',
    serviceRequestAccept: 'Požiadavka bola prijatá',
    commentCreate: 'Komentár k požiadavke bol vytvorený',
    attachmentCreate: 'Príloha bola pridaná',
    taskCreate: 'Úloha bola vytvorená',
  },
};

export default sk;
