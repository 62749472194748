import { Stack, Typography } from '@mui/material';
import LoginLayout from '../../layouts/login';
import AuthLoginForm from './AuthLoginForm';
import backgroundImg from '../../assets/new-background-image.webp';
import { useLocales } from 'src/locales';

export default function Login() {
  const { translate } = useLocales();

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
      }}
    >
      <LoginLayout>
        <div
          style={{
            backgroundColor: 'white',
            border: '20px solid white',
            borderRadius: 10,
          }}
        >
          <Stack
            spacing={2}
            sx={{ mb: 5, position: 'relative', alignItems: 'center' }}
          >
            <Typography variant="h4">{`${translate(
              'pages.login.heading'
            )}`}</Typography>
          </Stack>
          <AuthLoginForm />
        </div>
      </LoginLayout>
    </div>
  );
}
