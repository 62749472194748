import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axiosAPI';
import { IItemTag, IItemTagState } from '../../@types/itemTag';

// ----------------------------------------------------------------------

const initialState: IItemTagState = {
  isLoading: true,
  error: null,
  itemTags: [],
  itemTag: null,
};

const slice = createSlice({
  name: 'itemTag',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET ITEM_TAGS
    getItemTagsSuccess(state, action) {
      state.itemTags = action.payload;
      state.isLoading = false;
    },

    // GET ITEM_TAG
    getItemTagSuccess(state, action) {
      state.itemTag = action.payload;
      state.isLoading = false;
    },

    // UPDATE ITEM_TAG
    updateItemTagSuccess(state, action) {
      state.itemTag = action.payload;
      state.isLoading = false;
    },

    // CREATE ITEM_TAG
    createItemTagSuccess(state, action) {
      state.itemTag = action.payload;
      state.isLoading = false;
    },

    // REMOVE ITEM_TAG
    removeItemTagSuccess(state, action) {
      state.isLoading = false;
    },

    reset(state) {
      state.isLoading = true;
      state.error = null;
      state.itemTag = null;
      state.itemTags = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;

// Mapper
const mapItemTag = (itemTag: any): IItemTag => {
  return {
    id: itemTag?.id ? itemTag.id : '',
    name: itemTag?.name ? itemTag.name : '',
    itemTag: itemTag?.itemTag ? itemTag.itemTag : null,
  };
};

const mapItemTagAccount = (itemTag: any) => {
  return {
    name: itemTag?.name,
    parentId: itemTag?.parentId !== '' ? itemTag.parentId : null,
  };
};

// ----------------------------------------------------------------------

export function getItemTags() {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/item-tags');

      const result: IItemTag[] = response?.data?.data?.map((itemTag: any) =>
        mapItemTag(itemTag)
      );

      dispatch(slice.actions.getItemTagsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getItemTag(id: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get('/item-tags/' + id, {
        params: { id },
      });

      const result: IItemTag = mapItemTag(response?.data?.data);

      dispatch(slice.actions.getItemTagSuccess(result));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function updateItemTag(id: number, data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.patch(
        '/item-tags/' + id,
        mapItemTagAccount(data)
      );

      const result: IItemTag = mapItemTag(response?.data?.data);

      dispatch(slice.actions.updateItemTagSuccess(result));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function createItemTag(data: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post('/item-tags/', mapItemTagAccount(data));

      const result: IItemTag = mapItemTag(response?.data?.data);

      dispatch(slice.actions.createItemTagSuccess(result));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      throw error;
    }
  };
}

export function removeItemTag(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete('/item-tags/' + id);

      const result = response?.data;

      dispatch(slice.actions.removeItemTagSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      throw new Error('Error deleting item tag: ' + error.message);
    }
  };
}

export function resetItemTag() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.reset());
  };
}
