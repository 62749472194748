import { useSnackbar } from 'notistack';
import { useLocales } from 'src/locales';
import { useDispatch } from 'src/redux/store';

const useDeleteRowsHandler = () => {
  let hasError = false;
  let messageError = '';
  const { translate } = useLocales();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const deleteRows = async (selectedRows: string[], deleteFunction: any) => {
    const deletePromises = selectedRows.map(async (id: string) => {
      try {
        await dispatch(deleteFunction(Number(id)));
      } catch (error) {
        messageError = error.message;
        hasError = true;
      }
    });

    await Promise.allSettled(deletePromises);

    if (hasError) {
      enqueueSnackbar(messageError, {
        variant: 'error',
      });
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
      return true;
    }
    return false;
  };

  const deleteRow = async (
    id: string,
    deleteFunction: any,
    getFunction: any
  ) => {
    try {
      await dispatch(deleteFunction(Number(id)));
      await dispatch(getFunction());
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      enqueueSnackbar(`${translate('infoMessages.couldNotDelete')}`, {
        variant: 'error',
      });
      return true;
    }
    return false;
  };

  return {
    deleteHandlers: {
      deleteRows,
      deleteRow,
    },
  };
};

export default useDeleteRowsHandler;
