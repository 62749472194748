import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import { Box, Card, Container, Grid, Tab, Tabs } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// components
import Iconify from '../../../components/iconify';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// sections
import { useDispatch, useSelector } from '../../../redux/store';
import { useLocation, useParams } from 'react-router-dom';
import { getItem, getRedirectForItem } from 'src/redux/slices/item';
import { AccountGeneral } from 'src/sections/@dashboard/item/account';
import { useLocales } from '../../../locales';
import ItemLogListPage from '../itemLogs/ItemLogListPage';
import AttachmentListOfItemPage from '../attachment/AttachmentListOfItemPage';
import { ADMIN_MANAGERS_ROLES, ALL_ROLES, PROPERTY, ROOM } from 'src/constants';
import { useAuthContext } from 'src/auth/useAuthContext';
import ReminderListPage from '../reminder/ReminderListPage';
import { urlHandler } from 'src/utils/navigateHandler';
import { setLastUrlItems } from 'src/redux/slices/setting';
import QrCodePage from '../QrCodePage';
import useFlushNavigate from 'src/hooks/useFlushNavigate';

// ----------------------------------------------------------------------

export default function ItemDetailPage() {
  const { id } = useParams();
  const { user } = useAuthContext();
  const dispatch = useDispatch();
  const { translate } = useLocales();
  const location = useLocation();
  const { item, redirect } = useSelector((state) => state.item);
  const { lastUrlItems } = useSelector((state: any) => state.setting);
  const [currentTab, setCurrentTab] = useState<string>(`item/${id}/general`);
  const [propertyId, setPropertyId] = useState<string | undefined>(undefined);
  const [roomId, setRoomId] = useState<string | undefined>(undefined);
  const { flushRedux } = useFlushNavigate();

  const tabHandler = (tab: string) => {
    flushRedux();
    setCurrentTab(tab);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        await Promise.all([
          dispatch(getItem(Number(id))),
          dispatch(getRedirectForItem(Number(id))),
        ]);
      }
    };
    fetchData();
  }, [id, currentTab]);

  useEffect(() => {
    if (item) {
      setPropertyId(item?.property?.id.toString());
      setRoomId(item?.room?.id.toString());
    }
  }, [item]);

  useEffect(() => {
    const newPathname = `/dashboard/${currentTab}`;
    window.history.pushState(null, '', newPathname);
  }, [currentTab]);

  useEffect(() => {
    let tabFromPathname = location.pathname.replace('/dashboard/', '');
    const url = urlHandler(PROPERTY, tabFromPathname, ROOM);
    if (url.length > 0) dispatch(setLastUrlItems(url));
    tabFromPathname = tabFromPathname.replace('/property', '');
    tabFromPathname = tabFromPathname.replace('/room', '');
    tabHandler(tabFromPathname);
  }, [location.pathname]);

  const TABS = [
    {
      value: `item/${id}/general`,
      label: `${translate('general')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: <AccountGeneral id={id} fromUrl={lastUrlItems} />,
      roles: [],
    },
    {
      value: `item/${id}/item-logs`,
      label: `${translate('itemLogs')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <ItemLogListPage
          itemId={id}
          roomId={roomId}
          propertyId={propertyId}
          fromUrl={lastUrlItems}
        />
      ),
      roles: [],
    },
    {
      value: `item/${id}/attachments`,
      label: `${translate('attachments')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <AttachmentListOfItemPage
          propertyId={propertyId}
          itemId={id}
          fromUrl={lastUrlItems}
          roomId={roomId}
        />
      ),
      roles: [],
    },
    {
      value: `item/${id}/reminders`,
      label: `${translate('pages.reminder.nextReminder')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <ReminderListPage
          itemId={id}
          propertyId={propertyId}
          roomId={roomId}
          fromUrl={lastUrlItems}
        />
      ),
      roles: ADMIN_MANAGERS_ROLES,
    },
    {
      value: `item/${id}/qr-code`,
      label: `${translate('pages.qrCode.heading')}`,
      icon: <Iconify icon="ic:round-account-box" />,
      component: <QrCodePage uuid={redirect?.uuid} uri={item?.name ?? ''} />,
      roles: ALL_ROLES,
    },
  ];

  const content = (
    <>
      <Tabs
        value={currentTab}
        onChange={(event, newValue) => tabHandler(newValue)}
      >
        {TABS.map((tab) => {
          // If roles array is empty, show the tab regardless of user's roles
          if (tab.roles.length === 0) {
            return (
              <Tab
                key={tab.value}
                label={tab.label}
                icon={tab.icon}
                value={tab.value}
              />
            );
          }

          // Check if the user's roles match any of the roles specified in the tab
          const hasPermission = tab.roles.some((role) =>
            user?.roles.includes(role)
          );

          // If the user has permission, show the tab
          if (hasPermission) {
            return (
              <Tab
                key={tab.value}
                label={tab.label}
                icon={tab.icon}
                value={tab.value}
              />
            );
          }

          // If the user doesn't have permission, don't render the tab
          return null;
        })}
      </Tabs>

      {TABS.map(
        (tab) =>
          tab.value === currentTab && (
            <Box key={tab.value} sx={{ mt: 5 }}>
              {tab.component}
            </Box>
          )
      )}
    </>
  );

  const renderContent = () => {
    if (currentTab === TABS[0].value) {
      return (
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
              {content}
            </Card>
          </Grid>
        </Grid>
      );
    }
    return (
      <Card sx={{ padding: '16px 16px 32px 16px', marginBottom: '40px' }}>
        {content}
      </Card>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`${translate('item')}`}: {item?.name ?? ''} | Properio.io
        </title>
      </Helmet>

      <Container maxWidth={false}>
        <CustomBreadcrumbs
          heading={`${translate('pages.item.itemDetail')}`}
          links={[
            { name: `${translate('dashboard')}`, href: PATH_DASHBOARD.root },
            {
              name: `${translate('items')}`,
              href: PATH_DASHBOARD.general.items,
            },
            { name: item?.name ?? '' },
          ]}
        />
        {renderContent()}
      </Container>
    </>
  );
}
